import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { getLabel, getLang } from '../../../helpers/translator';
import { listItems } from '../../../actions/list';
import { findPhysicalTestData } from '../../../actions/physicalTest';
import { logEvent } from '../../../helpers/amplitude';

const fieldWrapperClassName = 'p-col-12 p-md-3';
const initialStartDate = () => {
  const now = moment(new Date()).subtract(1, 'year').toDate();
  now.setMinutes(now.getMinutes() > 30 ? 30 : 0);
  return now;
}

const initialEndDate = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() > 30 ? 30 : 0);
  return now;
}

const Filter = ({ fields, loading, setLoading, setData, toast, testCode }) => {
  const [inputs, setInputs] = useState({
    'startDate': initialStartDate(),
    'endDate': initialEndDate(),
    plants: [],
    aggregateTypes: [{ label: getLabel('natural_sand_cost'), value: 'natural_sand' },
    { label: getLabel('crushed_sand_cost'), value: 'crushed_sand' },
    { label: getLabel('no_1_cost'), value: 'no_1' },
    { label: getLabel('no_2_cost'), value: 'no_2' }],
    materials: [
      { label: getLabel('cement'), value: 'cement' },
      { label: getLabel('mineral'), value: 'mineral' },
      { label: getLabel('additive'), value: 'additive' },
      { label: getLabel('physicalTest.generalLabels.recycleWater'), value: 'recycle_water' },
      { label: getLabel('physicalTest.generalLabels.recycleWaterSolids'), value: 'recycle_water_solid' },
    ],
    experimentTypes: [
      { label: getLabel('physicalTest.generalLabels.loose'), value: 'loose' },
      { label: getLabel('physicalTest.generalLabels.compressed'), value: 'compressed' }
    ],
    sources: [],
    minerals: [],
    cements: [],
    additives: [],
    naturalSandSources: [],
    crushedSandSources: [],
    aggNoOneSources: [],
    aggNoTwoSources: [],
  });

  useEffect(() => {


    listItems('plant').then(d => {
      let plants = [];
      if (d.success) {
        d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('plants', plants);
    });

    listItems('mineral').then(d => {
      let minerals = [];
      if (d.success) {
        d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('minerals', minerals);
    });

    listItems('cement').then(d => {
      let cements = [];
      if (d.success) {
        d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('cements', cements);
    });

    listItems('additive').then(d => {
      let additives = [];
      if (d.success) {
        d.data.forEach(i => additives.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('additives', additives);

    });

    listItems('natural_sand').then(d => {
      let naturalSandSources = [];
      if (d.success) {
        d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('naturalSandSources', naturalSandSources);
    });

    listItems('crushed_sand').then(d => {
      let crushedSandSources = [];
      if (d.success) {
        d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('crushedSandSources', crushedSandSources);
    });

    listItems('agg_no_one').then(d => {
      let aggNoOneSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoOneSources', aggNoOneSources);
    });

    listItems('agg_no_two').then(d => {
      let aggNoTwoSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoTwoSources', aggNoTwoSources);
    });

  }, []);

  const cleanBeforeSave = () => {
    const sendingInputs = { ...inputs };
    delete sendingInputs.plants;
    delete sendingInputs.aggregateTypes;
    delete sendingInputs.sources;
    delete sendingInputs.minerals;
    delete sendingInputs.cements;
    delete sendingInputs.additives;
    delete sendingInputs.naturalSandSources;
    delete sendingInputs.crushedSandSources;
    delete sendingInputs.aggNoOneSources;
    delete sendingInputs.aggNoTwoSources;
    delete sendingInputs.materials;
    delete sendingInputs.experimentTypes;
    sendingInputs.date = moment(inputs.date).format("YYYY-MM-DD");
    return sendingInputs;
  }

  const search = () => {
    const params = {
      code: testCode,
      ...cleanBeforeSave()
    };

    logEvent(`[Physical Test Search] - ${testCode} `, { params });

    setLoading(true);
    findPhysicalTestData(params).then(d => {
      setLoading(false);
      if (d.success) {
        if (d?.data?.length === 0 || !d?.data)
          toast.show({ severity: 'warn', summary: getLabel('info'), detail: getLabel('physicalTest.operation.filterNoData') });

        setData(d.data);
      }
      else {
        toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.filterError') });
      }
    }).catch(_e => {
      setLoading(false);
      toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.filterError') });
    })
  }

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const getDropdownSource = field => {
    const { source, sourceFilter, sourceName } = field;
    if (source === 'aggregate_sources')
      return sourceFilter ? inputs.aggregateTypes.filter(f => eval(sourceFilter)) : inputs.aggregateTypes;

    if (source === 'plants')
      return sourceFilter ? inputs.plants.filter(f => eval(sourceFilter)) : inputs.plants;

    if (source === 'materials')
      return sourceFilter ? inputs.materials.filter(f => eval(sourceFilter)) : inputs.materials;

    if (source === 'experimentType')
      return sourceFilter ? inputs.experimentTypes.filter(f => eval(sourceFilter)) : inputs.experimentTypes;

    if (source === 'sources') {

      if (sourceName) {
        return sourceFilter ? inputs[sourceName].filter(f => eval(sourceFilter)) : inputs[sourceName];
      }

      if (inputs.aggregateType) {
        switch (inputs.aggregateType) {
          case "natural_sand":
            return sourceFilter ? inputs.naturalSandSources.filter(f => eval(sourceFilter)) : inputs.naturalSandSources;
          case "crushed_sand":
            return sourceFilter ? inputs.crushedSandSources.filter(f => eval(sourceFilter)) : inputs.crushedSandSources;
          case "no_1":
            return sourceFilter ? inputs.aggNoOneSources.filter(f => eval(sourceFilter)) : inputs.aggNoOneSources;
          case "no_2":
            return sourceFilter ? inputs.aggNoTwoSources.filter(f => eval(sourceFilter)) : inputs.aggNoTwoSources;
          default:
            return null;
        }
      }

      if (inputs.material) {
        switch (inputs.material) {
          case "cement":
            return sourceFilter ? inputs.cements.filter(f => eval(sourceFilter)) : inputs.cements;
          case "mineral":
            return sourceFilter ? inputs.minerals.filter(f => eval(sourceFilter)) : inputs.minerals;
          case "additive":
            return sourceFilter ? inputs.additives.filter(f => eval(sourceFilter)) : inputs.additives;
          default:
            return null;
        }
      }

    }

  }

  const changeInput = (key, e) => {
    logEvent(`[Physical Test Search] Filter Change - ${testCode} `, { filter: key, value: e.value });
    setInputs(prevState => { return { ...prevState, [key]: e.value } });
  }

  const changeArrayInput = (key, e) => {
    logEvent(`[Physical Test Search] Filter Change - ${testCode} `, { filter: key, value: e.value });
    setInputs(prevState => { return { ...prevState, [key]: e } });
  }

  const renderField = field => {
    switch (field.type) {
      case "number":
        return renderNumber(field);
      case "dropdown":
        return renderDropdown(field);
      default:
        return (
          <div>Unknown Field</div>
        )
    }
  }

  const renderNumber = field => {
    return (
      <div className="p-col-12" key={field.code}>
        <span className="p-float-label">
          <InputNumber
            disabled={loading} key={field.code} id={field.code} value={inputs[field.code]} step={100} useGrouping={false}
            minFractionDigits={0} onChange={(e) => changeInput(field.code, e)} />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    )
  }

  const renderDropdown = field => {
    if (field.multi_choice_filter)
      return renderMultiSelect(field);
    else
      return renderSingleSelect(field);
  }

  const renderSingleSelect = field => {
    return (
      <div className="p-col-12" key={field.code}>
        <span className="p-float-label">
          <Dropdown
            showClear disabled={loading} id={field.code} key={field.code}
            value={inputs[field.code]} options={getDropdownSource(field)}
            onChange={(e) => changeInput(field.code, e)}
          />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    )
  }

  const renderMultiSelect = field => {
    return (
      <div className="p-col-12" key={field.code}>
        <span className="p-float-label">
          <MultiSelect
            id={field.code} key={field.code}
            disabled={loading}
            value={inputs[field.code]}
            options={getDropdownSource(field)} onChange={(e) => changeInput(field.code, e)} filter
            emptyFilterMessage={getLabel('multiEmptyItems')} />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    );
  }

  return (
    <div>
      <Panel header={getLabel('filters')} toggleable className='p-mb-4'>
        <div className="p-grid p-fluid">

          <div className={fieldWrapperClassName}>

            <div className="p-col-12">
              <span className="p-float-label">
                <Calendar id='start_date' showTime hourFormat='24' stepMinute={15} key="start_date" disabled={loading} showButtonBar showIcon locale={getLang()} value={inputs.startDate} onChange={(e) => changeInput('startDate', e)} />
                <label htmlFor="start_date">{getLabel('startDate')}</label>
              </span>
            </div>

            <div className="p-col-12">
              <span className="p-float-label">
                <Calendar id='end_date' showTime hourFormat='24' stepMinute={15} key='end_date' disabled={loading} showButtonBar showIcon locale={getLang()} value={inputs.endDate} onChange={(e) => changeInput('endDate', e)} />
                <label htmlFor="end_date">{getLabel('endDate')}</label>
              </span>
            </div>

          </div>

          {
            fields && chunk(fields, 2).map((block, index) => (
              <div key={index} className={fieldWrapperClassName}>
                {block.map(field => (
                  renderField(field)
                ))}
              </div>
            ))
          }

          <div className={fieldWrapperClassName}>
            <div className="p-col-12">
              <Button disabled={loading} label={getLabel('search')} onClick={() => search()} className='p-button-raised p-button-success no-print' icon="pi pi-search" />
            </div>

          </div>
        </div>
      </Panel>
      {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
    </div>
  );
};

export default withTranslation()(Filter);