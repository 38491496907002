import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import MyInputNumber from '../common/MyInputNumber';
import { updateSamples, getSamplesForUpdate } from '../../actions/sample';
import { listItems } from '../../actions/list';
import { getLimit } from '../../helpers/limit';
import { LocalStorage } from '../../helpers/storage';
import { logEvent } from '../../helpers/amplitude';
import { getLang, getLabel, getAdditionalColumnDesc, dateFormat } from '../../helpers/translator';

const UpdateSample = ({ history }) => {
    const timezone = LocalStorage.get('timezone');
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({});
    const [otherFields] = useState(LocalStorage.get('other_fields'));
    const [breakFields] = useState(LocalStorage.get('breaks'));
    const [plants] = useState([]);
    const [classes] = useState([]);
    const [classDesigns] = useState([]);
    const [cements] = useState([]);
    const [alternateCements] = useState([]);
    const [minerals] = useState([]);
    const [additiveOne] = useState([]);
    const [additiveTwo] = useState([]);
    const [additiveThree] = useState([]);
    const [sampleTypes] = useState([]);
    const [aggNoOneSources] = useState([]);
    const [aggNoTwoSources] = useState([]);
    const [naturalSandSources] = useState([]);
    const [crushedSandSources] = useState([]);
    const [customers] = useState([]);
    const [data, setData] = useState([]);
    const [inputs, setInputs] = useState({ 'date': new Date() });
    const [pageLoad, setPageLoad] = useState(true);

    const column_mapper = {
        'plant.name': 'plant_id',
        'classes.name': 'class_id',
        'customer.name': 'customer_id',
        'cement.name': 'cement_id',
        'alternative_cement.name': 'alternative_cement_id',
        'mineral.name': 'mineral_id',
        'additive_one.name': 'additive_one_id',
        'additive_two.name': 'additive_two_id',
        'sample_type.name': 'sample_type_id',
        'agg_no_one_source.name': 'agg_no_one_source_id',
        'agg_no_two_source.name': 'agg_no_two_source_id',
        'natural_sand_source.name': 'natural_sand_source_id',
        'crushed_sand_source.name': 'crushed_sand_source_id',
        'class_design.name': 'class_design_id'
    }

    const source_mapper = {
        'plant.name': plants,
        'classes.name': classes,
        'customer.name': customers,
        'cement.name': cements,
        'alternative_cement.name': alternateCements,
        'mineral.name': minerals,
        'additive_one.name': additiveOne,
        'additive_two.name': additiveTwo,
        'sample_type.name': sampleTypes,
        'agg_no_one_source.name': aggNoOneSources,
        'class_design.name': classDesigns,
        'agg_no_two_source.name': aggNoTwoSources,
        'natural_sand_source.name': naturalSandSources,
        'crushed_sand_source.name': crushedSandSources
    }

    useEffect(() => {
        logEvent("[Update Sample] View");

        if (plants.length === 0) {
            setLoading(true);
            listItems('plant').then(d => {
                setLoading(false);
                if (d.success) {
                    d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
                    setPageLoad(false);
                }
            });
        }

        if (classes.length === 0) {
            listItems('class').then(d => {
                if (d.success) {
                    d.data.forEach(i => classes.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (classDesigns.length === 0) {
            listItems('class_design').then(d => {
                if (d.success) {
                    d.data.forEach(i => classDesigns.push({ label: i.name, value: i.id, class_id: i.class_id }));
                }
            });
        }

        if (cements.length === 0) {
            listItems('cement').then(d => {
                if (d.success) {
                    d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => alternateCements.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (minerals.length === 0) {
            listItems('mineral').then(d => {
                if (d.success) {
                    d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (additiveOne.length === 0) {
            listItems('additive').then(d => {
                if (d.success) {
                    d.data.forEach(i => additiveOne.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => additiveTwo.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => additiveThree.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (sampleTypes.length === 0) {
            listItems('sample_type').then(d => {
                if (d.success) {
                    d.data.forEach(i => sampleTypes.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (customers.length === 0) {
            listItems('customer').then(d => {
                if (d.success) {
                    d.data.forEach(i => customers.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (naturalSandSources.length === 0) {
            listItems('natural_sand').then(d => {
                if (d.success) {
                    d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (crushedSandSources.length === 0) {
            listItems('crushed_sand').then(d => {
                if (d.success) {
                    d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (aggNoOneSources.length === 0) {
            listItems('agg_no_one').then(d => {
                if (d.success) {
                    d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (aggNoTwoSources.length === 0) {
            listItems('agg_no_two').then(d => {
                if (d.success) {
                    d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
                }
            });
        }

    }, [plants, aggNoOneSources, aggNoTwoSources, customers, classes, cements, alternateCements, minerals, additiveOne, additiveTwo, additiveThree, sampleTypes, naturalSandSources, crushedSandSources, classDesigns]);



    const queryData = () => {
        setLoading(true);
        const searchParams = {
            date: inputs.date ? moment(inputs.date).format('YYYY-MM-DD') : null,
            plant_id: inputs.plant_id,
            sample_no: inputs.sample_no
        }

        logEvent("[Update Sample] Get Data", { "queryParams": searchParams });

        getSamplesForUpdate(searchParams).then(d => {
            setLoading(false);
            setData([]);
            if (d.not_valid) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('updateData.reqParams') });
                return;
            }
            if (d.success) {
                setData(d.data);
                if (d.data.length === 0) {
                    toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('filter.noData') });
                }
            }
            else {
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('filter.error') });
            }
        }).catch(err => {
            logEvent("[Update Sample] Error on Get Data", { "queryParams": searchParams });

            setLoading(false); toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('filter.error') })
        });

    }

    const changeInput = (key, e) => {
        logEvent("[Update Sample] Change Input", { "field": key, "value": e.value });

        setInputs(prevState => { return { ...prevState, [key]: e.value } });
        if (key === 'sample_no') {
            if (e.value) {
                changeInput('date', { value: null })
            }
            else if (e.value === null || e.value === '') {
                changeInput('date', { value: new Date() });
            }
        }
    }

    const editRow = (props, field, minFractionDigits = 0) => {
        return <InputNumber style={{ fontSize: 10 }} value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.value)} useGrouping={false} minFractionDigits={minFractionDigits} />;
    }

    const editTextRow = (props, field) => {
        return <InputText style={{ fontSize: 10 }} value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.target.value)} />;

    }

    const editAdditionalRow = (props, field) => {
        return <InputNumber style={{ fontSize: 10 }} type="text" value={getAdditionalData(props.rowData, field)} onChange={(e) => onEditorAdditionalValueChange(props, e.value, field)} useGrouping={false} minFractionDigits={2} />;

    }

    const editAdditionalTextRow = (props, field) => {
        return <InputText style={{ fontSize: 10 }} type="text" value={getAdditionalData(props.rowData, field)} onChange={(e) => onEditorAdditionalValueChange(props, e.target.value, field)} />;

    }

    const editDate = (props, field) => {
        return <Calendar id='editDate' touchUI showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.value)}></Calendar>
    }

    const onEditorValueChange = (props, value) => {
        const newData = [...data];
        const { rowData, rowIndex, column } = props;
        if (props.field === 'date') {
            rowData['date'] = moment(value).format('YYYY-MM-DD');
            rowData['date_updated'] = true;
        }
        else {
            rowData[column.props.field] = value;
        }

        rowData['updated'] = true;
        newData[rowIndex] = rowData;
        setData(newData);
    }

    const onEditorAdditionalValueChange = (props, value, field) => {
        const newData = [...data];
        const { rowData, rowIndex } = props;
        const row = rowData.additional_values.find(f => f.column.code === field);
        if (row)
            row['value'] = value;
        else {
            const newRow = {
                column: props.column.props.otherField,
                column_id: props.column.props.otherField.id,
                value: value
            }
            rowData.additional_values.push(newRow);
        }
        rowData['updated'] = true;
        newData[rowIndex] = rowData;
        setData(newData);
    }

    const cellEditValidator = (e) => {
        let { newRowData, field, rowIndex } = e;
        if (field.indexOf('weight') !== -1) {
            const sample_type = newRowData?.sample_type?.name;
            const lower = getLimit(sample_type === '15X15X15 KÜP' ? 'weight_type_1' : 'weight_type_2', 'lower');
            const upper = getLimit(sample_type === '15X15X15 KÜP' ? 'weight_type_1' : 'weight_type_2', 'upper');
            if (parseFloat(newRowData[field]) < lower || parseFloat(newRowData[field]) > upper) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                newRowData['error'] = true;
                data[rowIndex] = newRowData;
                return false;
            }
            else {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }
        }
        else if (field.indexOf('strength') !== -1) {
            const lower = getLimit('strength', 'lower');
            const upper = getLimit('strength', 'upper');
            if (parseFloat(newRowData[field]) < lower || parseFloat(newRowData[field]) > upper) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                newRowData['error'] = true;
                data[rowIndex] = newRowData;
                return false;
            }
            else {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }
        }
        else {
            const lower = getLimit(field, 'lower');
            const upper = getLimit(field, 'upper');
            if (parseFloat(newRowData[field]) < lower || parseFloat(newRowData[field]) > upper) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                newRowData['error'] = true;
                data[rowIndex] = newRowData;
                return false;
            }
            else {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }
        }
    }

    const breakCellEditValidator = (e) => {
        let { newRowData, column, rowIndex } = e;
        const fieldType = column.props.fieldType;

        if (fieldType === 'weight') {
            const sample_type = newRowData?.sample_type?.name;
            const lower = getLimit(sample_type === '15X15X15 KÜP' ? 'weight_type_1' : 'weight_type_2', 'lower');
            const upper = getLimit(sample_type === '15X15X15 KÜP' ? 'weight_type_1' : 'weight_type_2', 'upper');
            const breakValue = newRowData.break_values.find(f => f.number_id === column.props.number.id);

            if (!breakValue) {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }


            if (breakValue.hasOwnProperty(fieldType) && (parseFloat(breakValue[fieldType]) < lower || parseFloat(breakValue[fieldType]) > upper)) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                newRowData['error'] = true;
                data[rowIndex] = newRowData;
                return false;
            }
            else {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }
        }
        else if (fieldType === 'strength') {
            const lower = getLimit('strength', 'lower');
            const upper = getLimit('strength', 'upper');
            const breakValue = newRowData.break_values.find(f => f.number_id === column.props.number.id);

            if (!breakValue) {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }

            if (breakValue.hasOwnProperty(fieldType) && (parseFloat(breakValue[fieldType]) < lower || parseFloat(breakValue[fieldType]) > upper)) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                newRowData['error'] = true;
                data[rowIndex] = newRowData;
                return false;
            }
            else {
                newRowData['error'] = false;
                data[rowIndex] = newRowData;
                return true;
            }
        }

        return true;
    }

    const editBreakRow = (props) => {
        const { column, rowData } = props;
        const breakValue = rowData.break_values.find(f => f.number_id === column?.props?.number.id);
        const value = breakValue ? breakValue[column.props.fieldType] : null

        return <InputNumber minFractionDigits={column.props.fieldType === 'weight' ? 0 : 2} useGrouping={false}
            value={value} onChange={(e) => onBreakEditorValueChange(props, e.value)} />;

    }

    const onBreakEditorValueChange = (props, value) => {
        const newData = [...data];
        const { rowData, rowIndex, column } = props;
        const breakValue = rowData.break_values.find(f => f.number_id === column.props.number.id);
        if (breakValue) {
            breakValue[column.props.fieldType] = value;
            breakValue['updated'] = true;
        }
        else {
            rowData.break_values.push({
                break_id: column.props.breakData.id,
                number_id: column.props.number.id,
                sample_id: rowData.id,
                [column.props.fieldType]: value,
                'updated': true
            });
        }
        rowData['updated'] = true;
        newData[rowIndex] = rowData;
        setData(newData);
    }

    const editListRow = (props, field) => {
        let listSources = [];
        if (field === 'class_design.name') {
            listSources = source_mapper[field].filter(f => f.class_id === props.rowData.class_id).map(i => { return { label: i.label, value: i.value } });
        }
        else {
            listSources = source_mapper[field];
        }
        return (
            <Dropdown id={field} options={listSources} filter
                value={props.rowData[column_mapper[field]]} showClear
                onChange={(e) => onEditorListValueChange(props, e.value)} />
        )
    }

    const onEditorListValueChange = (props, value) => {
        if (value === undefined)
            value = null;
        const newData = [...data];
        const { rowData, rowIndex, column } = props;
        rowData['updated'] = true;
        rowData[column_mapper[column.props.field]] = value;
        const fields = column.props.field.split('.');
        let val = undefined;
        if (column.props.field === 'class_design.name') {
            val = source_mapper[column.props.field].find(x => x.value === value && x.class_id === props.rowData.class_id)
        }
        else {
            val = source_mapper[column.props.field].find(x => x.value === value) || null;
        }

        if (rowData[fields[0]])
            if (val)
                rowData[fields[0]][fields[1]] = val.label;
            else
                rowData[fields[0]] = null;
        else
            rowData[fields[0]] = { id: val?.value, name: val?.label }

        newData[rowIndex] = rowData;
        setData(newData);

    }

    const renderDate = (date) => {
        return moment.utc(date).tz(timezone).format(dateFormat()).toString();
    }

    const save = () => {
        toast.clear();
        let err = data.find(f => f.error);
        if (err) {
            logEvent("[Update Sample] Limit Error");
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('limit.warn') });
            return;
        }
        setLoading(true);
        logEvent("[Update Sample] Save");
        updateSamples({ data: data.filter(f => f.updated), activity: 'updateSample' }).then(d => {
            setLoading(false);

            if (d.already) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('newSample.already'), sticky: true });
                return;
            }

            if (d.success) {
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('updateData.updateSuccess') });
                queryData();
            }
            else {
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('updateData.updateError') })
            }
        }).catch(e => {
            logEvent("[Update Sample] Error on Save");
            setLoading(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('updateData.updateError') })
        }
        );

    }

    const getAdditionalData = (rowData, key) => {
        const row = rowData.additional_values.find(f => f.column.code === key);
        if (row) {
            return row.value;
        }

    }

    const getBreakDate = (rowData, breakId) => {
        const row = rowData.break_dates.find(f => f.break_id === breakId);
        if (row) {
            return moment.utc(row.value).tz(timezone).format(dateFormat()).toString();
        }
    }

    const getBreakValues = (rowData, numberId, key) => {
        const row = rowData.break_values.find(f => f.number_id === numberId);
        if (row) {
            return row[key]
        }
    }

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }
            {
                !pageLoad && (
                    <div>
                        {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
                        <div className="p-grid p-fluid">

                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <span className="p-float-label">
                                            <Calendar id='date' disabled={inputs?.sample_no} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={inputs.date} onChange={(e) => changeInput('date', e)}></Calendar>
                                            <label htmlFor="date">{getLabel('date')}</label>
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <span className="p-float-label">
                                            <Dropdown id="plant" showClear style={{ width: '100%' }} value={inputs.plant_id} options={plants} onChange={(e) => changeInput('plant_id', e)} />
                                            <label htmlFor="plant">{getLabel('plant')}</label>
                                        </span>
                                    </div>

                                    <MyInputNumber validationLimit={false} customClass="p-col-12 p-md-2" htmlFor={getLabel("sample_no")} id="sample_no"
                                        value={inputs.sample_no} change={changeInput} />

                                    <div className="p-col-12 p-md-2">
                                        <Button disabled={loading} style={{ width: "100%" }} label={getLabel('search')} onClick={() => queryData()} className='p-button-raised p-button-secondary' icon="pi pi-search" />
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <Button disabled={loading || data.length === 0} onClick={() => save()} label={getLabel('save')} className='p-button-raised' />
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12" style={{ width: '100%', overflow: 'scroll', maxHeight: '500px' }}>

                                <DataTable className="update-table p-datatable-border p-datatable-sm header-center p-datatable-gridlines" value={data} editMode="cell" emptyMessage={getLabel('updateData.emptyTableMessage')} scrollable scrollHeight="flex" >
                                    <Column field="date" alignHeader={"center"} align={"center"} header={getLabel('date')} body={(e) => renderDate(e?.date)} editor={(props) => editDate(props, 'date')} />
                                    <Column field="sample_no" alignHeader={"center"} header={getLabel('sample_no')} editor={(props) => editTextRow(props, 'sample_no')} />
                                    <Column field="waybill_number" alignHeader={"center"} header={getLabel('waybill_number')} editor={(props) => editTextRow(props, 'waybill_number')} />
                                    <Column field="plant.name" alignHeader={"center"} header={getLabel('plant')} editor={(props) => editListRow(props, 'plant.name')} />
                                    <Column field="classes.name" alignHeader={"center"} header={getLabel('class')} editor={(props) => editListRow(props, 'classes.name')} />
                                    <Column field="class_design.name" alignHeader={"center"} header={getLabel('class_design')} editor={(props) => editListRow(props, 'class_design.name')} />
                                    <Column field="customer.name" alignHeader={"center"} header={getLabel('customer')} editor={(props) => editListRow(props, 'customer.name')} />
                                    <Column field="environmental_effect" alignHeader={"center"} header={getLabel('environment_affect')} editor={(props) => editTextRow(props, 'environmental_effect')} />
                                    <Column field="clor_content" alignHeader={"center"} header={getLabel('clor')} editor={(props) => editTextRow(props, 'clor_content')} />
                                    <Column field="cement.name" alignHeader={"center"} header={getLabel('cement')} editor={(props) => editListRow(props, 'cement.name')} />
                                    <Column field="cement_amount" alignHeader={"center"} header={getLabel('cement_val')} editor={(props) => editRow(props, 'cement_amount')} cellEditValidator={cellEditValidator} />
                                    <Column field="alternative_cement.name" alignHeader={"center"} header={getLabel('alternate_cement')} editor={(props) => editListRow(props, 'alternative_cement.name')} />
                                    <Column field="alternative_cement_amount" alignHeader={"center"} header={getLabel('alternate_cement_val')} editor={(props) => editRow(props, 'alternative_cement_amount')} cellEditValidator={cellEditValidator} />
                                    <Column field="mineral.name" alignHeader={"center"} header={getLabel('mineral')} editor={(props) => editListRow(props, 'mineral.name')} />
                                    <Column field="mineral_amount" alignHeader={"center"} header={getLabel('mineral_val')} editor={(props) => editRow(props, 'mineral_amount')} cellEditValidator={cellEditValidator} />
                                    <Column field="theoretical_water" alignHeader={"center"} header={getLabel('theorical_water')} editor={(props) => editRow(props, 'theoretical_water')} cellEditValidator={cellEditValidator} />
                                    <Column field="additive_one.name" alignHeader={"center"} header={getLabel('additive_one')} editor={(props) => editListRow(props, 'additive_one.name')} />
                                    <Column field="add_one_amount" alignHeader={"center"} header={getLabel('additive_one_val')} editor={(props) => editRow(props, 'add_one_amount', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="additive_two.name" alignHeader={"center"} header={getLabel('additive_two')} editor={(props) => editListRow(props, 'additive_two.name')} />
                                    <Column field="add_two_amount" alignHeader={"center"} header={getLabel('additive_two_val')} editor={(props) => editRow(props, 'add_two_amount', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="natural_sand" alignHeader={"center"} header={getLabel('natural_sand')} editor={(props) => editRow(props, 'natural_sand')} cellEditValidator={cellEditValidator} />
                                    <Column field="crushed_sand" alignHeader={"center"} header={getLabel('crushed_sand')} editor={(props) => editRow(props, 'crushed_sand')} cellEditValidator={cellEditValidator} />
                                    <Column field="agg_no_one" alignHeader={"center"} header={getLabel('no_1')} editor={(props) => editRow(props, 'agg_no_one', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="agg_no_two" alignHeader={"center"} header={getLabel('no_2')} editor={(props) => editRow(props, 'agg_no_two', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="agg_no_one_source.name" alignHeader={"center"} header={getLabel('no_1_source')} editor={(props) => editListRow(props, 'agg_no_one_source.name')} />
                                    <Column field="agg_no_two_source.name" alignHeader={"center"} header={getLabel('no_2_source')} editor={(props) => editListRow(props, 'agg_no_two_source.name')} />
                                    <Column field="crushed_sand_source.name" alignHeader={"center"} header={getLabel('crushed_sand_source')} editor={(props) => editListRow(props, 'crushed_sand_source.name')} />
                                    <Column field="natural_sand_source.name" alignHeader={"center"} header={getLabel('natural_sand_source')} editor={(props) => editListRow(props, 'natural_sand_source.name')} />
                                    <Column field="natural_sand_methylene" alignHeader={"center"} header={getLabel('natural_sand_methylene')} editor={(props) => editRow(props, 'natural_sand_methylene', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="crushed_sand_methylene" alignHeader={"center"} header={getLabel('crushed_sand_methylene')} editor={(props) => editRow(props, 'crushed_sand_methylene', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="natural_sand_system_moisture" alignHeader={"center"} header={getLabel('natural_sand_system_moisture')} editor={(props) => editRow(props, 'natural_sand_system_moisture', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="crushed_sand_system_moisture" alignHeader={"center"} header={getLabel('crushed_sand_system_moisture')} editor={(props) => editRow(props, 'crushed_sand_system_moisture', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="agg_no_one_system_moisture" alignHeader={"center"} header={getLabel('agg_no_one_system_moisture')} editor={(props) => editRow(props, 'agg_no_one_system_moisture', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="agg_no_two_system_moisture" alignHeader={"center"} header={getLabel('agg_no_two_system_moisture')} editor={(props) => editRow(props, 'agg_no_two_system_moisture', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="weather_temp" alignHeader={"center"} header={getLabel('temp')} editor={(props) => editRow(props, 'weather_temp', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="concrete_temp" alignHeader={"center"} header={getLabel('concrete_temp')} editor={(props) => editRow(props, 'concrete_temp', 2)} cellEditValidator={cellEditValidator} />
                                    <Column field="sample_type.name" alignHeader={"center"} header={getLabel('sample_type')} editor={(props) => editListRow(props, 'sample_type.name')} />
                                    <Column field="slump" alignHeader={"center"} header={getLabel('slump')} editor={(props) => editRow(props, 'slump')} cellEditValidator={cellEditValidator} />
                                    <Column field="flow_diameter" alignHeader={"center"} header={getLabel('flow_diameter')} editor={(props) => editRow(props, 'flow_diameter')} />
                                    <Column field="fresh_concrete_density" alignHeader={"center"} header={getLabel('fresh_concrete_density')} editor={(props) => editRow(props, 'fresh_concrete_density')} cellEditValidator={cellEditValidator} />
                                    <Column field="air_percent" alignHeader={"center"} header={getLabel('air_percent')} editor={(props) => editRow(props, 'air_percent', 2)} cellEditValidator={cellEditValidator} />
                                    {
                                        otherFields && otherFields.map(field => (
                                            <Column
                                                key={field.code}
                                                field={field.code}
                                                otherField={field}
                                                alignHeader={"center"}
                                                header={getAdditionalColumnDesc(field)}
                                                editor={(props) => field.field_type === 'FLOAT' ? editAdditionalRow(props, field.code) : editAdditionalTextRow(props, field.code)}
                                                body={(rowData) => getAdditionalData(rowData, field.code)}
                                                columnKey={field.code}
                                                className="datatable-custom-column"
                                            />
                                        ))
                                    }

                                    {
                                        breakFields && breakFields.filter(f => f.value !== 0 && f.class_id === null).map(field => (
                                            [
                                                <Column
                                                    key={field.id}
                                                    className="disabled-background"
                                                    field={field.code}
                                                    alignHeader={"center"}
                                                    header={getLabel('updateData.day').replace('#VAL#', field.value)}
                                                    body={(rowData) => getBreakDate(rowData, field.id)}
                                                    columnKey={field.code}
                                                />,
                                                field.numbers.map(number => (
                                                    [
                                                        <Column
                                                            key={`${number.id}_weight`}
                                                            field={`${number.id}_weight`}
                                                            alignHeader={"center"}
                                                            header={getLabel('updateData.weight').replace('#VAL#', number.value).replace("#DAY#", field.value)}
                                                            editor={(props) => editBreakRow(props)}
                                                            cellEditValidator={breakCellEditValidator}
                                                            body={(rowData) => getBreakValues(rowData, number.id, 'weight')}
                                                            fieldType='weight'
                                                            number={number}
                                                            breakData={field}
                                                            className="datatable-custom-column"
                                                        />,
                                                        <Column
                                                            key={`${number.id}_strength`}
                                                            field={`${number.id}_strength`}
                                                            alignHeader={"center"}
                                                            header={getLabel('updateData.strength').replace('#VAL#', number.value).replace("#DAY#", field.value)}
                                                            editor={(props) => editBreakRow(props)}
                                                            cellEditValidator={breakCellEditValidator}
                                                            body={(rowData) => getBreakValues(rowData, number.id, 'strength')}
                                                            fieldType='strength'
                                                            number={number}
                                                            breakData={field}
                                                            className="datatable-custom-column"
                                                        />
                                                    ]
                                                ))
                                            ]
                                        ))
                                    }

                                    {
                                        breakFields && breakFields.filter(f => f.value !== 0 && f.class_id !== null).map(field => (
                                            [
                                                <Column
                                                    key={field.id}
                                                    className="disabled-background"
                                                    field={field.code}
                                                    alignHeader={"center"}
                                                    header={`${getLabel('updateData.day').replace('#VAL#', field.value)} ${getLabel('special')}`}
                                                    body={(rowData) => getBreakDate(rowData, field.id)}
                                                    columnKey={field.code}
                                                />,
                                                field.numbers.map(number => (
                                                    [
                                                        <Column
                                                            key={number.id}
                                                            field={`${number.id}_weight`}
                                                            alignHeader={"center"}
                                                            header={`${getLabel('updateData.weight').replace('#VAL#', number.value).replace("#DAY#", field.value)} ${getLabel('special')}`}
                                                            editor={(props) => editBreakRow(props)}
                                                            cellEditValidator={breakCellEditValidator}
                                                            body={(rowData) => getBreakValues(rowData, number.id, 'weight')}
                                                            fieldType='weight'
                                                            number={number}
                                                            breakData={field}
                                                            className="datatable-custom-column"
                                                        />,
                                                        <Column
                                                            key={number.id}
                                                            field={`${number.id}_strength`}
                                                            alignHeader={"center"}
                                                            header={`${getLabel('updateData.strength').replace('#VAL#', number.value).replace("#DAY#", field.value)} ${getLabel('special')}`}
                                                            editor={(props) => editBreakRow(props)}
                                                            cellEditValidator={breakCellEditValidator}
                                                            body={(rowData) => getBreakValues(rowData, number.id, 'strength')}
                                                            fieldType='strength'
                                                            number={number}
                                                            breakData={field}
                                                            className="datatable-custom-column"
                                                        />
                                                    ]
                                                ))
                                            ]
                                        ))
                                    }

                                    <Column field="statement" alignHeader={"center"} header={getLabel('notes')} editor={(props) => editTextRow(props, 'statement')} />
                                </DataTable>

                            </div>

                        </div>
                        <Toast ref={(el) => setToast(el)} />
                    </div >

                )
            }
        </div>
    )
};


export default withTranslation()(UpdateSample);