import { Menubar } from "primereact/menubar";
import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { LocalStorage } from "../../helpers/storage";
import { logEvent } from '../../helpers/amplitude';

import { getLabel } from '../../helpers/translator';

const Header = ({ history }) => {

  const menus = [
    {
      index: 0,
      label: getLabel("menu.manuel"),
      icon: "pi pi-sliders-h",
      link: "/manuel-predict",
      command: () => { history.push("/manuel-predict"); }
    },
    {
      index: 1,
      label: getLabel("menu.report"),
      icon: "pi pi-chart-line",
      link: "/report",
      command: () => { history.push("/report"); }
    },
    {
      index: 2,
      label: getLabel("menu.data"),
      icon: "pi pi-cloud-upload",
      link: "/update-data",
      command: () => { history.push("/update-data"); }
    },
    {
      index: 3,
      label: getLabel("menu.daily"),
      icon: "pi pi-directions",
      link: "/daily-predict",
      command: () => { history.push("/daily-predict"); }
    },
    {
      index: 4,
      label: getLabel("menu.database"),
      icon: "pi pi-download",
      link: "/data",
      command: () => { history.push("/data"); }
    },
    {
      index: 5,
      label: getLabel("menu.cost"),
      icon: "pi pi-wallet",
      link: "/cost",
      command: () => { history.push("/cost"); }
    },
    {
      index: 6,
      label: getLabel("menu.physicalTest"),
      icon: "pi pi-folder-open",
      items: [
        
        {
          index: 8,
          label: getLabel('physicalTest.menu.testLabel'),
          items: [
            {
              index: 9,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.methyleneBlue'),
              link: "/physical-test/methylene-blue",
              command: () => { history.push('/physical-test/methylene-blue') }
            },
            {
              separator: true
            },
            {
              index: 10,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.otherMaterialsSpecificGravity'),
              link: "/physical-test/other-materials-specific-gravity",
              command: () => { history.push('/physical-test/other-materials-specific-gravity') }
            },
            {
              separator: true
            },
            {
              index: 11,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.aggregateParticleSizeDistribution'),
              link: "/physical-test/aggregate-particle-size-distribution",
              command: () => { history.push('/physical-test/aggregate-particle-size-distribution') }
            },
            {
              separator: true
            },
            {
              index: 12,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.aggregateSpecificWeightAndWaterAbsorption'),
              link: "/physical-test/aggregate-specific-weight-and-water-absorption",
              command: () => { history.push('/physical-test/aggregate-specific-weight-and-water-absorption') }
            },
            {
              separator: true
            },
            {
              index: 13,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.chemicalAdditiveTracking'),
              link: "/physical-test/chemical-additive-tracking",
              command: () => { history.push('/physical-test/chemical-additive-tracking') }
            },
            {
              separator: true
            },
            {
              index: 14,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.looseCompressedUnitWeight'),
              link: "/physical-test/loose-compressed-unit-weight",
              command: () => { history.push('/physical-test/loose-compressed-unit-weight') }
            },
            {
              separator: true
            },
            {
              index: 15,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.sandEquivalent'),
              link: "/physical-test/sand-equivalent",
              command: () => { history.push('/physical-test/sand-equivalent') }
            },
            {
              separator: true
            },
            {
              index: 16,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.flatnessIndex'),
              link: "/physical-test/flatness-index",
              command: () => { history.push('/physical-test/flatness-index') }
            },
            {
              separator: true
            },
            {
              index: 17,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.shapeIndex'),
              link: "/physical-test/shape-index",
              command: () => { history.push('/physical-test/shape-index') }
            },
            {
              separator: true
            },
            {
              index: 18,
              className: "physical-test-menu-item",
              label: getLabel('physicalTest.testLabels.losAngelesTest'),
              link: "/physical-test/los-angeles-test",
              command: () => { history.push('/physical-test/los-angeles-test') }
            },
            {
              separator: true
            },
          ]
        }
      ]
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  const menuEndItems = () => {
    return (
      <div className="menu-rightitems">
        <div className="p-menubar p-component">
          <ul className="menu-rightitems-list">
            <li className="p-menuitem">
              <div className="p-menuitem-link" onClick={() => { history.push("/profile"); }}>
                <span className="p-menuitem-icon pi pi-user"></span>
                <span className="p-menuitem-text">{LocalStorage.get("name")}</span>
              </div>
            </li>
            <li className="p-menuitem">
              <div className="p-menuitem-link" onClick={() => {
                logEvent("[Log Out] Click");
                LocalStorage.clear();
                window.location.href = "/login";
              }}>
                <span className="p-menuitem-icon pi pi-sign-out"></span>
                <span className="p-menuitem-text">{getLabel("menu.logout")}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (history.location.state?.index !== undefined) {
      setActiveItem(history.location.state.index);
    }
  }, [history, activeItem]);


  const start = (
    <img
      src="/optimix-logo-black.png"
      alt="logo"
      width="64"
      onClick={() => history.push("/manuel-predict")}
    />
  );

  return (
    <div className="card no-print">
      <Menubar model={menus} start={start} end={menuEndItems} />
    </div>
  );
};
export default withTranslation()(Header);
