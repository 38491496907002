import { get as $get, post as $post, put as $put } from './core/fetch';


export const physical_test_detail = (test_code) => $get(`/physical-test-detail/${test_code}`);
export const save_physical_test_data = (data) => $post('/physical-test', data);
export const update_physical_test_data = (data) => $put('/physical-test', data);
export const find_physical_test_data = (data) => $post('/physical-test-data', data);
export const find_max_test_no = (test_code) => $get(`/find-max-test-no/${test_code}`);


