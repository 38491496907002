import { save_cost, material_price, cost_report } from '../api/cost';

export const saveCost = (cost) =>
  new Promise(function (resolve, reject) {
    save_cost(cost)
      .then(data => resolve(data))
      .catch(error => reject(error));
  });


export const materialPrice = (req) =>
  new Promise(function (resolve, reject) {
    material_price(req)
      .then(data => resolve(data))
      .catch(error => reject(error));
  });

export const costReport = (req) =>
  new Promise(function (resolve, reject) {
    cost_report(req)
      .then(data => resolve(data))
      .catch(error => reject(error));
  });