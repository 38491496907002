import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { getLabel, getLang } from '../../helpers/translator';
import { listItems } from '../../actions/list';
import { LocalStorage } from '../../helpers/storage';
import { materialPrice } from '../../actions/cost';
import { Chart } from 'primereact/chart';
import { isPlainObject } from 'lodash';
import { logEvent } from '../../helpers/amplitude';



const MaterialPrice = ({ history }) => {
  const [toast, setToast] = useState({});
  const [state, _setStates] = useState({
    loading: false,
    startDate: moment(new Date()).subtract(2, 'year').toDate(),
    endDate: new Date(),
    chartData: null,
    plants: [],
    products: [],
    sources: [],
    minerals: [],
    cements: [],
    additives: [],
    naturalSandSources: [],
    crushedSandSources: [],
    aggNoOneSources: [],
    aggNoTwoSources: [],
    otherFields: LocalStorage.get('cost_fields', []),
    colors: ['#cd84f1', '#ff4d4d', '#ffaf40', '#fffa65', '#c56cf0', '#7efff5', '#18dcff', '#7d5fff', '#4b4b4b', '#3ae374']
  });

  useEffect(() => {

    logEvent("[Material Price] View");

    let products = [
      { label: getLabel('cement'), value: 'cement' },
      { label: getLabel('mineral'), value: 'mineral' },
      { label: getLabel('additive'), value: 'additive' },
      { label: getLabel('natural_sand_cost'), value: 'natural_sand' },
      { label: getLabel('crushed_sand_cost'), value: 'crushed_sand' },
      { label: getLabel('no_1_cost'), value: 'no_1' },
      { label: getLabel('no_2_cost'), value: 'no_2' }
    ];

    const others = LocalStorage.get('cost_fields', []);

    others.forEach(d => {
      products.push({ label: d[`label_${getLang()}`], value: d.key });
    });

    setState({ products });

    listItems('plant').then(d => {
      let plants = [];
      if (d.success) {
        d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
      }
      setState({ plants });
    });

    listItems('mineral').then(d => {
      let minerals = [];
      if (d.success) {
        d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
      }
      setState({ minerals });
    });

    listItems('cement').then(d => {
      let cements = [];
      if (d.success) {
        d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
      }
      setState({ cements });
    });

    listItems('additive').then(d => {
      let additives = [];
      if (d.success) {
        d.data.forEach(i => additives.push({ label: i.name, value: i.id }));
      }
      setState({ additives })
    });

    listItems('natural_sand').then(d => {
      let naturalSandSources = [];
      if (d.success) {
        d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
      }
      setState({ naturalSandSources })
    });

    listItems('crushed_sand').then(d => {
      let crushedSandSources = [];
      if (d.success) {
        d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
      }
      setState({ crushedSandSources })
    });

    listItems('agg_no_one').then(d => {
      let aggNoOneSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
      }
      setState({ aggNoOneSources })
    });

    listItems('agg_no_two').then(d => {
      let aggNoTwoSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
      }
      setState({ aggNoTwoSources })
    });

  }, []);

  useEffect(() => {
    setState({ chartData: null, sources: [], source: null })
    if (state.product !== -1 && state.product) {

      if (state.product === 'cement')
        setState({ sources: state.cements });

      if (state.product === 'mineral')
        setState({ sources: state.minerals });

      if (state.product === 'additive')
        setState({ sources: state.additives });

      if (state.product === 'natural_sand')
        setState({ sources: state.naturalSandSources });

      if (state.product === 'crushed_sand')
        setState({ sources: state.crushedSandSources });

      if (state.product === 'no_1')
        setState({ sources: state.aggNoOneSources });

      if (state.product === 'no_2')
        setState({ sources: state.aggNoTwoSources });
    }

  },
    [
      state.product, state.cements, state.minerals, state.additives, state.naturalSandSources,
      state.crushedSandSources, state.aggNoOneSources, state.aggNoTwoSources
    ]
  );

  const setState = (props) => {
    logEvent("[Material Price] Change Filter", { "props": props });
    _setStates(prevState => { return { ...prevState, ...props } });
  }

  const disableSource = () => {
    const fixedSources = ['cement', 'mineral', 'additive', 'natural_sand', 'crushed_sand', 'no_1', 'no_2'];
    return !fixedSources.includes(state.product);
  }

  const getReport = () => {

    if (validateFilter()) {
      toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('cost.materialFilter') })
      return;
    }

    const request = {
      material: state.product,
      source: state.source,
      plant_id: state.plant_id,
      start_date: moment(state.startDate).format('YYYY-MM-DD'),
      end_date: moment(state.endDate).format('YYYY-MM-DD')
    }

    logEvent("[Material Price] Get Report", { "params": request });

    setState({ loading: true, chartData: null });

    materialPrice(request).then(d => {

      setState({ loading: false });

      if (d.success) {
        if (validateResponse(d.data)) {
          let _chartData = {
            labels: [],
            datasets: []
          }
          _chartData.labels = d.data.map(d => d.date);

          _chartData.datasets.push({
            label: getChartLabel(),
            backgroundColor: state.colors[0],
            borderColor: state.colors[0],
            data: d.data.map(d => d.price),
            borderWidth: 1,
            pointRadius: 5,
            pointHoverRadius: 5,
            lineTension: 0,
            type: 'line',
            fill: false
          });


          setState({ chartData: _chartData });
        }
        else {
          setState({ chartData: null });
          toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('cost.noData') })
        }
      }
      else {
        toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('cost.materialReportError') })
      }
    }).catch(_ => {
      logEvent("[Material Price] Error on Get Report", { "params": request });
      setState({ loading: false });
      toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('cost.materialReportError') })
    });
  }

  const validateFilter = () => {
    if (state.otherFields.find(f => f.key === state.product))
      return !state.startDate || !state.endDate || !state.product;
    else
      return !state.startDate || !state.endDate || !state.product || !state.source;
  }

  const validateResponse = (data) => {
    if (isPlainObject(data)) {
      return Object.values(data).find(f => f.length > 0);
    }
    else
      return data.length > 0;
  }

  const getChartLabel = () => {
    if (state.source === null)
      return state.products.find(f => f.value === state.product).label;
    else
      return state.sources.find(f => f.value === state.source)?.label;
  }

  const { loading, chartData } = state;

  return (
    <div>
      {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px' }} />)}
      <Panel header={getLabel('filters')} toggleable>

        <div className="p-grid">

          <div className="p-col-12 p-md-3">

            <div className="p-col-12">
              <span className="p-float-label">
                <Calendar id='start_date' disabled={loading} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={state.startDate} onChange={(e) => setState({ startDate: e.value })}></Calendar>
                <label htmlFor="start_date">{getLabel('startDate')}</label>
              </span>
            </div>

            <div className="p-col-12">
              <span className="p-float-label">
                <Calendar id='end_date' disabled={loading} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={state.endDate} onChange={(e) => setState({ endDate: e.value })}></Calendar>
                <label htmlFor="end_date">{getLabel('endDate')}</label>
              </span>
            </div>
          </div>

          <div className="p-col-12 p-md-3">

            <div className="p-col-12 p-md-12">
              <span className="p-float-label">
                <Dropdown id="plant" disabled={loading} showClear style={{ width: '100%' }} value={state.plant_id} options={state.plants} onChange={(e) => setState({ plant_id: e.value })} />
                <label htmlFor="plant">{getLabel('plant')}</label>
              </span>
            </div>

            <div className="p-col-12 p-md-12">
              <span className="p-float-label">
                <Dropdown id="product" disabled={loading} showClear style={{ width: '100%' }} value={state.product} options={state.products} onChange={(e) => setState({ product: e.value, source: null })} />
                <label htmlFor="product">{getLabel('cost.product')}</label>
              </span>
            </div>

          </div>

          <div className="p-col-12 p-md-3">

            <div className="p-col-12">
              <span className="p-float-label">
                <Dropdown
                  id="class_design" showClear
                  disabled={disableSource() || loading}
                  style={{ width: "100%" }} value={state.source}
                  options={state.sources} onChange={(e) => setState({ source: e.value, chartData: null })} filter
                />
                <label htmlFor="class_design">{getLabel('cost.source')}</label>
              </span>
            </div>

          </div>

          <div className="p-col-12 p-md-1">
            <div className="p-col-12">
              <Button disabled={loading} label={getLabel('search')} onClick={() => getReport()} className='p-button-raised p-button-success no-print' icon="pi pi-search" />
            </div>
            <div className="p-col-12">
              <Button
                disabled={loading} label={getLabel('reporting')}
                className='p-button-raised p-button-secondary no-print' icon="pi pi-print"
                onClick={() => {
                  logEvent("[Material Price] Print");
                  window.print();
                }}
              />
            </div>
          </div>

        </div>
      </Panel>

      <div className="p-col-12">
        {
          chartData && (
            <Chart key={Math.random()} type="line" redraw data={chartData} options={
              {
                responsive: true,
                aspectRatio: 4,

                scales: {
                  x: {
                    ticks: {
                    }
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                    }
                  }
                },
                plugins: {
                  title: {
                    display: true,
                    position: 'top',
                    text: getLabel('cost.title'),
                    fontSize: 18
                  },
                  datalabels: {
                    align: 'top',
                    anchor: 'end',
                  }
                }
              }
            } />
          )
        }

      </div>

      <Toast ref={(el) => setToast(el)} />
    </div>
  )
};

export default withTranslation()(MaterialPrice);