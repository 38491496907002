import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import _ from 'lodash';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { getLabel, getLang } from '../../../helpers/translator';
import { listItems } from '../../../actions/list';
import { savePhysicalTestData, findMaxTestNo } from '../../../actions/physicalTest';
import { LocalStorage } from '../../../helpers/storage';
import { logEvent } from '../../../helpers/amplitude';

const fieldWrapperClassName = 'p-col-12 p-md-3';
const initialDate = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() > 30 ? 30 : 0);
  return now;
}

const Entry = ({ fields, loading, setLoading, testCode, toast }) => {

  const INITIAL_STATE = {
    date: initialDate(),
    plants: [],
    aggregateTypes: [{ label: getLabel('natural_sand_cost'), value: 'natural_sand' },
    { label: getLabel('crushed_sand_cost'), value: 'crushed_sand' },
    { label: getLabel('no_1_cost'), value: 'no_1' },
    { label: getLabel('no_2_cost'), value: 'no_2' }],
    materials: [
      { label: getLabel('cement'), value: 'cement' },
      { label: getLabel('mineral'), value: 'mineral' },
      { label: getLabel('additive'), value: 'additive' },
      { label: getLabel('physicalTest.generalLabels.recycleWater'), value: 'recycle_water' },
      { label: getLabel('physicalTest.generalLabels.recycleWaterSolids'), value: 'recycle_water_solid' },
    ],
    experimentTypes: [
      { label: getLabel('physicalTest.generalLabels.loose'), value: 'loose' },
      { label: getLabel('physicalTest.generalLabels.compressed'), value: 'compressed' }
    ],
    sources: [],
    minerals: [],
    cements: [],
    additives: [],
    naturalSandSources: [],
    crushedSandSources: [],
    aggNoOneSources: [],
    aggNoTwoSources: [],
  }

  const [inputs, setInputs] = useState(INITIAL_STATE);
  const timezone = LocalStorage.get('timezone');
  const [requiredFields] = useState([...['date', 'testNo'], ...fields.filter(f => !f.onRunTime && f.required !== false).map(d => d.code)]);


  useEffect(() => {

    findMaxTestNoForEntry();

    listItems('plant').then(d => {
      let plants = [];
      if (d.success) {
        d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('plants', plants);
    });

    listItems('mineral').then(d => {
      let minerals = [];
      if (d.success) {
        d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('minerals', minerals);
    });

    listItems('cement').then(d => {
      let cements = [];
      if (d.success) {
        d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('cements', cements);
    });

    listItems('additive').then(d => {
      let additives = [];
      if (d.success) {
        d.data.forEach(i => additives.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('additives', additives);

    });

    listItems('natural_sand').then(d => {
      let naturalSandSources = [];
      if (d.success) {
        d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('naturalSandSources', naturalSandSources);
    });

    listItems('crushed_sand').then(d => {
      let crushedSandSources = [];
      if (d.success) {
        d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('crushedSandSources', crushedSandSources);
    });

    listItems('agg_no_one').then(d => {
      let aggNoOneSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoOneSources', aggNoOneSources);
    });

    listItems('agg_no_two').then(d => {
      let aggNoTwoSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoTwoSources', aggNoTwoSources);
    });

  }, []);

  const findMaxTestNoForEntry = () => {
    findMaxTestNo(testCode).then(d => {
      if (d.success)
        changeInput('testNo', { value: d.data?.test_no + 1 || 1 });
    });
  }

  const cleanBeforeSave = () => {
    const sendingInputs = _.cloneDeep(inputs);
    delete sendingInputs.plants;
    delete sendingInputs.aggregateTypes;
    delete sendingInputs.sources;
    delete sendingInputs.minerals;
    delete sendingInputs.cements;
    delete sendingInputs.additives;
    delete sendingInputs.naturalSandSources;
    delete sendingInputs.crushedSandSources;
    delete sendingInputs.aggNoOneSources;
    delete sendingInputs.aggNoTwoSources;
    delete sendingInputs.materials;
    delete sendingInputs.experimentTypes;
    sendingInputs.date = moment.utc(inputs.date).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    return sendingInputs;
  }

  const cleanFieldStates = () => {
    findMaxTestNoForEntry();
    fields.forEach(f => {
      if (f.type === 'text')
        changeInput(f.code, { value: '' });
      else
        changeInput(f.code, { value: null });
    })
  }

  const save = () => {
    setLoading(true);

    let hasError = false;
    requiredFields.forEach(r => {
      if (inputs[r] === null || inputs[r] === undefined) {
        hasError = true;
        return;
      }
    });

    if (hasError) {
      setLoading(false);
      toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.reqFields') });
      return;

    }

    const entry = {
      code: testCode,
      testNo: inputs.testNo,
      date: moment.utc(inputs.date).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
      value: cleanBeforeSave()
    }

    logEvent(`[Physical Test Data Enter] - ${testCode} `, { entry });

    savePhysicalTestData(entry).then(d => {
      setLoading(false);
      if (d.success) {
        toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('physicalTest.operation.saveSuccess') });
        cleanFieldStates();
      }
      else if (d.exists) {
        toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('physicalTest.operation.existsTest') });
      }
      else {
        toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.saveError') });
      }
    }).catch(_e => {
      setLoading(false);
      toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.saveError') });
    });
  }

  const changeInput = (key, e, useTarget = false) => {
    setInputs(prevState => { return { ...prevState, [key]: useTarget ? e.target.value : e.value } });
  }

  const changeArrayInput = (key, e) => {
    setInputs(prevState => { return { ...prevState, [key]: e } });
  }

  const getDropdownSource = field => {
    const { source, sourceFilter, sourceName } = field;
    if (source === 'aggregate_sources')
      return sourceFilter ? inputs.aggregateTypes.filter(f => eval(sourceFilter)) : inputs.aggregateTypes;

    if (source === 'plants')
      return sourceFilter ? inputs.plants.filter(f => eval(sourceFilter)) : inputs.plants;

    if (source === 'materials')
      return sourceFilter ? inputs.materials.filter(f => eval(sourceFilter)) : inputs.materials;

    if (source === 'experimentType')
      return sourceFilter ? inputs.experimentTypes.filter(f => eval(sourceFilter)) : inputs.experimentTypes;

    if (source === 'sources') {
      if (sourceName) {
        return sourceFilter ? inputs[sourceName].filter(f => eval(sourceFilter)) : inputs[sourceName];
      }
      if (inputs.aggregateType) {
        switch (inputs.aggregateType) {
          case "natural_sand":
            return sourceFilter ? inputs.naturalSandSources.filter(f => eval(sourceFilter)) : inputs.naturalSandSources;
          case "crushed_sand":
            return sourceFilter ? inputs.crushedSandSources.filter(f => eval(sourceFilter)) : inputs.crushedSandSources;
          case "no_1":
            return sourceFilter ? inputs.aggNoOneSources.filter(f => eval(sourceFilter)) : inputs.aggNoOneSources;
          case "no_2":
            return sourceFilter ? inputs.aggNoTwoSources.filter(f => eval(sourceFilter)) : inputs.aggNoTwoSources;
          default:
            return null;
        }
      }

      if (inputs.material) {
        switch (inputs.material) {
          case "cement":
            return sourceFilter ? inputs.cements.filter(f => eval(sourceFilter)) : inputs.cements;
          case "mineral":
            return sourceFilter ? inputs.minerals.filter(f => eval(sourceFilter)) : inputs.minerals;
          case "additive":
            return sourceFilter ? inputs.additives.filter(f => eval(sourceFilter)) : inputs.additives;
          case "recycle_water":
            return [{ label: getLabel('no_source'), value: undefined }];
          case "recycle_water_solid":
            return [{ label: getLabel('no_source'), value: undefined }];
          default:
            return null;
        }
      }

    }
  }

  const renderField = field => {
    switch (field.type) {
      case "number":
        return renderNumber(field);
      case "dropdown":
        return renderDropdown(field);
      case "date":
        return renderCalendar(field);
      case "text":
        return renderText(field);
      default:
        return (
          <div>Unknown Field</div>
        )
    }
  }

  const renderDropdown = field => {
    if (field.multi_choice_entry)
      return renderMultiSelect(field);
    else
      return renderSingleSelect(field);
  }

  const renderCalendar = field => {
    return (
      <div className={fieldWrapperClassName} key={field.code}>
        <span className="p-float-label">
          <Calendar id={field.code} key={field.code} disabled={loading || field.disabled}
            showButtonBar showIcon showTime hourFormat="24" stepMinute={15}
            locale={getLang()} value={inputs[field.code]} onChange={(e) => changeInput(field.code, e)} />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    )
  }

  const renderNumber = field => {
    return (
      <div className={fieldWrapperClassName} key={field.code}>
        <span className="p-float-label">
          <InputNumber
            disabled={loading || field.disabled} key={field.code} id={field.code} value={field.autoCalculate ? eval(field.formula) : inputs[field.code]} step={0.1} useGrouping={false}
            minFractionDigits={field.digits || 0} onChange={(e) => changeInput(field.code, e)} />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    )
  }

  const renderText = field => {
    return (
      <div className={fieldWrapperClassName} key={field.code}>
        <span className="p-float-label">
          <InputText
            disabled={loading || field.disabled} key={field.code} id={field.code} value={inputs[field.code]}
            onChange={(e) => changeInput(field.code, e, true)} />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    )
  }

  const renderSingleSelect = field => {
    return (
      <div className={fieldWrapperClassName} key={field.code}>
        <span className="p-float-label">
          <Dropdown
            showClear disabled={loading || field.disabled} id={field.code} key={field.code}
            value={inputs[field.code]} options={getDropdownSource(field)}
            onChange={(e) => changeInput(field.code, e)}
          />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    )
  }

  const renderMultiSelect = field => {
    return (
      <div className={fieldWrapperClassName} key={field.code}>
        <span className="p-float-label">
          <MultiSelect
            id={field.code} key={field.code}
            disabled={loading || field.disabled}
            value={inputs[field.code]}
            options={getDropdownSource(field)} onChange={(e) => changeInput(field.code, e)} filter
            emptyFilterMessage={getLabel('multiEmptyItems')} />
          <label htmlFor={field.code}>{field.label[getLang()]}</label>
        </span>
      </div>
    );
  }

  return (
    <div>
      <Panel header={getLabel('updateData.title')}>
        <div className="p-grid p-fluid" key="entry-main">

          <div className={fieldWrapperClassName} key={'date-wrapper'}>
            <span className="p-float-label">
              <Calendar id={'date'} key={'date'} disabled={loading}
                showButtonBar showIcon showTime hourFormat="24" stepMinute={15}
                locale={getLang()} value={inputs.date} onChange={(e) => changeInput('date', e)} />
              <label htmlFor={'date'}>{getLabel('date')}</label>
            </span>
          </div>

          <div className={fieldWrapperClassName} key='testNo-wrapper'>
            <span className="p-float-label">
              <InputNumber
                disabled={loading} key='testNo' id='testNo' value={inputs.testNo} step={100} useGrouping={false}
                minFractionDigits={0} onChange={(e) => changeInput('testNo', e)} />
              <label htmlFor='testNo'>{getLabel('physicalTest.generalLabels.testNo')}</label>
            </span>
          </div>

          {
            fields && fields.filter(f => !f.onRunTime).map(field => (
              renderField(field)
            ))
          }

        </div>

        <div className="p-grid p-fluid p-mt-2" key="save-main">
          <div className="p-col-1" key="save-button">
            <Button disabled={loading} label={getLabel('save')} onClick={() => save()} />
          </div>
        </div>
      </Panel>
      {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
    </div>
  );
};

export default withTranslation()(Entry);