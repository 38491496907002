import { list_items, get_columns, get_break_columns } from '../api/list';
import { write, getFromCache } from '../helpers/cache';


export const listItems = (itemType) =>
    new Promise(function (resolve, reject) {
        const cached = getFromCache(itemType);
        if (cached) {
            resolve(cached);
            return;
        }
        list_items(itemType)
            .then(data => {
                if (data.success)
                    write(itemType, data);
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const getColumns = (lang) =>
    new Promise(function (resolve, reject) {
        get_columns(lang)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const getBreakColumns = (lang) =>
    new Promise(function (resolve, reject) {
        get_break_columns(lang)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });