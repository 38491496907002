import React from 'react';
import ReactDOM from 'react-dom';
import { router } from './routing/router';
import createHistory from "history/createBrowserHistory";
import { CookiesProvider } from 'react-cookie';
import utility from './util/api';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { addLocale } from 'primereact/api';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-datasheet/lib/react-datasheet.css';
import './i18n';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';

import { tr, en, ru } from './helpers/date';

Chart.register(ChartDataLabels);

const history = createHistory();
utility.setupInterceptors(history);
addLocale('tr', tr);
addLocale('en', en);
addLocale('ru', ru);
ReactDOM.render(
    <CookiesProvider>
        {router}
    </CookiesProvider>,
    document.getElementById('root')
);