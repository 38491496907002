import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { Chart } from 'primereact/chart';

import { LocalStorage } from '../../../helpers/storage';
import { getLang, getLabel, dateFormat } from '../../../helpers/translator';
import { listItems } from '../../../actions/list';


const AggregateParticleSizeChart = ({ fields, data, loading, title, keys }) => {
  const timezone = LocalStorage.get('timezone');
  const colors = [
    '#fd7f6f',
    '#7eb0d5',
    '#b2e061',
    '#bd7ebe',
    '#ffb55a',
    '#ffee65',
    '#beb9db',
    '#fdcce5',
    '#8bd3c7',
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
  ];

  const INITIAL_STATE = {
    plants: [],
    aggregateTypes: [{ label: getLabel('natural_sand_cost'), value: 'natural_sand' },
    { label: getLabel('crushed_sand_cost'), value: 'crushed_sand' },
    { label: getLabel('no_1_cost'), value: 'no_1' },
    { label: getLabel('no_2_cost'), value: 'no_2' }],
    materials: [
      { label: getLabel('cement'), value: 'cement' },
      { label: getLabel('mineral'), value: 'mineral' },
      { label: getLabel('additive'), value: 'additive' },
      { label: getLabel('physicalTest.generalLabels.recycleWater'), value: 'recycle_water' },
      { label: getLabel('physicalTest.generalLabels.recycleWaterSolids'), value: 'recycle_water_solid' },
    ],
    experimentTypes: [
      { label: getLabel('physicalTest.generalLabels.loose'), value: 'loose' },
      { label: getLabel('physicalTest.generalLabels.compressed'), value: 'compressed' }
    ],
    sources: [],
    minerals: [],
    cements: [],
    additives: [],
    naturalSandSources: [],
    crushedSandSources: [],
    aggNoOneSources: [],
    aggNoTwoSources: [],
  };

  const [inputs, setInputs] = useState(INITIAL_STATE);

  useEffect(() => {

    listItems('plant').then(d => {
      let plants = [];
      if (d.success) {
        d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('plants', plants);
    });

    listItems('mineral').then(d => {
      let minerals = [];
      if (d.success) {
        d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('minerals', minerals);
    });

    listItems('cement').then(d => {
      let cements = [];
      if (d.success) {
        d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('cements', cements);
    });

    listItems('additive').then(d => {
      let additives = [];
      if (d.success) {
        d.data.forEach(i => additives.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('additives', additives);

    });

    listItems('natural_sand').then(d => {
      let naturalSandSources = [];
      if (d.success) {
        d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('naturalSandSources', naturalSandSources);
    });

    listItems('crushed_sand').then(d => {
      let crushedSandSources = [];
      if (d.success) {
        d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('crushedSandSources', crushedSandSources);
    });

    listItems('agg_no_one').then(d => {
      let aggNoOneSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoOneSources', aggNoOneSources);
    });

    listItems('agg_no_two').then(d => {
      let aggNoTwoSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoTwoSources', aggNoTwoSources);
    });

  }, []);

  const changeArrayInput = (key, e) => {
    setInputs(prevState => { return { ...prevState, [key]: e } });
  }

  const getChartData = (data) => {
    return keys.map(key => (data.value[key]));
  }


  const chartData = {
    labels: keys.map(key => fields.find(f => f.code === key)?.label[getLang()]),
    datasets: data.map((d, index) => ({
      label: d.value.date,
      data: getChartData(d),
      fill: false,
      borderColor: colors[index],
      backgroundColor: colors[index],
      tension: 0.4,
    }))
  };

  const options = {
    responsive: true,
    aspectRatio: 4,
    scales: {
      x: {
        ticks: {
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'nearest',
        yAlign: 'center',
        callbacks: {
          label: function (tooltipItem) {
            const rowData = data[tooltipItem?.datasetIndex];
            const date = moment.utc(rowData.value.date).tz(timezone).format(`${dateFormat()} HH:mm`);
            const note = rowData.value.note;
            const experimenter = rowData.value.experimenter;
            const source = getSourceData(rowData.value.aggregateType, rowData.value.material, rowData.value.source);
            const plant = inputs.plants.find(f => f.value === rowData.value.plant);
            const aggregateType = inputs.aggregateTypes.find(f => f.value === rowData.value.aggregateType);
            const experimentTypes = inputs.experimentTypes.find(f => f.value === rowData.value.experimentType);
            const categoryFI = rowData.value.categoryFI;
            const material = inputs.materials.find(f => f.value === rowData.value.material);

            const title = []
            title.push(`${getLabel('date')} : ${date}`);
            if (experimentTypes)
              title.push(`${getLabel('physicalTest.generalLabels.experimentType')} : ${experimentTypes?.label}`);
            if (categoryFI)
              title.push(`${getLabel('physicalTest.generalLabels.categoryFI')} : ${categoryFI}`);
            if (material)
              title.push(`${getLabel('physicalTest.generalLabels.material')} : ${material?.label}`);
            if (source)
              title.push(`${getLabel('cost.source')} : ${source?.label}`);
            if (plant)
              title.push(`${getLabel('plant')} : ${plant?.label}`);
            if (aggregateType)
              title.push(`${getLabel('physicalTest.generalLabels.aggregateType')} : ${aggregateType?.label}`);
            title.push(`${getLabel('physicalTest.generalLabels.testNo')} : ${rowData.test_no}`);
            title.push(`${getLabel('physicalTest.generalLabels.experimenter')} : ${experimenter}`);
            if (note && note !== '')
              title.push(`${getLabel('notes')} : ${note}`);

            return title;
          },
          title: function (tooltipItem) {
            if (tooltipItem?.length > 0)
              return `${tooltipItem[0].label} : ${tooltipItem[0].formattedValue}` || getLabel('report.detail');
            else
              return getLabel('report.detail');
          }
        }
      },
      title: {
        display: true,
        position: 'top',
        text: title,
        fontSize: 18
      },
      datalabels: {
        display: false,
        align: 'top',
        anchor: 'start',
      }
    }
  }

  const getSourceData = (aggregateType, material, data) => {
    if (aggregateType) {
      switch (aggregateType) {
        case "natural_sand":
          return inputs.naturalSandSources.find(f => f.value === data);
        case "crushed_sand":
          return inputs.crushedSandSources.find(f => f.value === data);
        case "no_1":
          return inputs.aggNoOneSources.find(f => f.value === data);
        case "no_2":
          return inputs.aggNoTwoSources.find(f => f.value === data);
        default:
          return null;
      }
    }

    if (material) {
      switch (material) {
        case "cement":
          return inputs.cements.find(f => f.value === data);
        case "mineral":
          return inputs.minerals.find(f => f.value === data);
        case "additive":
          return inputs.additives.find(f => f.value === data);
        default:
          return null;
      }
    }

    return inputs.additives.find(f => f.value === data);

  }

  return (
    <>
      {data && data.length > 0 && (
        <div className="card">
          <Chart type="line" data={chartData} options={options} />
        </div>
      )}
    </>

  )
}
export default withTranslation()(AggregateParticleSizeChart);
