import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Message } from 'primereact/message';
import { LocalStorage } from '../helpers/storage';
import { ProgressBar } from 'primereact/progressbar';

import { authUser } from '../actions/auth';

const Login = ({ history }) => {
    const { t, i18n } = useTranslation();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasBlock, setHasBlock] = useState(false);

    const login = () => {
        setLoading(true);
        authUser(username, password)
            .then(response => {
                if (response.access_token) {
                    LocalStorage.set('token', response.access_token);
                    LocalStorage.set('username', response.username);
                    LocalStorage.set('name', response.name);
                    LocalStorage.set('org', response.org);
                    LocalStorage.set('lang', response.lang);
                    LocalStorage.set('limits', response.limits || {});
                    LocalStorage.set('first_min', response.first_min || 0);
                    LocalStorage.set('second_max', response.second_max || 1000);
                    LocalStorage.set('additive_unit', response.additive_unit || "");
                    LocalStorage.set('other_fields', response.other_fields || []);
                    LocalStorage.set('req_fields', response.req_fields || []);
                    LocalStorage.set('breaks', response.breaks || []);
                    LocalStorage.set('cost_fields', response.cost_fields || []);
                    LocalStorage.set('report_settings', response.report_settings || {});
                    LocalStorage.set('timezone', response.timezone || '');
                    LocalStorage.set('cost_report_max', response.cost_report_max || 400);
                    LocalStorage.set('currency', response.currency || '₺');
                    i18n.changeLanguage(response.lang);
                    history.push('/');
                    setLoading(false);
                }
                setLoading(false);
                if (response.block) {
                    setHasBlock(true);
                }
                else if (response.status === false && response.message) {
                    setHasError(true);
                }
            })
            .catch(err => {
                setLoading(false);
                setHasError(true);
            });
    }

    return (
        <div className="login-body">

            <div className="body-container">
                {loading && (<ProgressBar mode="indeterminate" style={{ height: '8px' }} />)}

                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="login-wrapper">
                            <div className="login-container">
                                <img style={{ width: '50%' }} src="/logo-black.png" alt="apollo-layout" className="logo" />
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">
                                        <InputText style={{ width: '100%' }} value={username} onChange={(e) => setUsername(e.target.value)} placeholder="username" />
                                    </div>
                                    <div className="p-col-12">
                                        <Password feedback={false} style={{ width: '100%' }} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                                    </div>
                                    {
                                        hasError && !loading && (
                                            <div className="p-col-12">
                                                <Message severity="error" text={t('crendentialError')}></Message>
                                            </div>
                                        )
                                    }

                                    {
                                        hasBlock && !loading && (
                                            <div className="p-col-12">
                                                <Message severity="error" text={t('userBlockError')}></Message>
                                            </div>
                                        )
                                    }


                                    <div className="p-col-6">
                                        <button className="p-button p-component p-button-text-icon-left" disabled={loading} onClick={() => login()}>
                                            <span className="pi pi-check p-c p-button-icon-left"></span>
                                            <span className="p-button-text p-c">{t('lgnBtn')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default withTranslation()(Login);