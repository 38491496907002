import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { getLabel, getLang, currencySymbol } from '../../helpers/translator';
import { listItems } from '../../actions/list';
import { saveCost } from '../../actions/cost';
import { LocalStorage } from '../../helpers/storage';
import { logEvent } from '../../helpers/amplitude';



const CostPricing = ({ history }) => {
    const [toast, setToast] = useState({});
    const [state, _setStates] = useState({
        loading: false,
        plants: [],
        minerals: [],
        cements: [],
        additives: [],
        naturalSandSources: [],
        crushedSandSources: [],
        aggNoOneSources: [],
        aggNoTwoSources: [],
        otherFields: LocalStorage.get('cost_fields', [])
    });

    useEffect(() => {
        logEvent("[Cost Pricing] View");

        listItems('plant').then(d => {
            let plants = [];
            if (d.success) {
                d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
            }
            setState({ plants })
        });

        listItems('mineral').then(d => {
            let minerals = [];
            if (d.success) {
                d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
            }
            setState({ minerals })
        });

        listItems('cement').then(d => {
            let cements = [];
            if (d.success) {
                d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
            }
            setState({ cements })
        });

        listItems('additive').then(d => {
            let additives = [];
            if (d.success) {
                d.data.forEach(i => additives.push({ label: i.name, value: i.id }));
            }
            setState({ additives })
        });

        listItems('natural_sand').then(d => {
            let naturalSandSources = [];
            if (d.success) {
                d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
            }
            setState({ naturalSandSources })
        });

        listItems('crushed_sand').then(d => {
            let crushedSandSources = [];
            if (d.success) {
                d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
            }
            setState({ crushedSandSources })
        });

        listItems('agg_no_one').then(d => {
            let aggNoOneSources = [];
            if (d.success) {
                d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
            }
            setState({ aggNoOneSources })
        });

        listItems('agg_no_two').then(d => {
            let aggNoTwoSources = [];
            if (d.success) {
                d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
            }
            setState({ aggNoTwoSources })
        });

    }, []);

    const setState = (props) => {
        _setStates(prevState => { return { ...prevState, ...props } });
    }

    const renderRow = (key, label, entity, unit, options) => {
        const plant_id = `${key}_plant_id`
        return (<tr>
            <td>{label}</td>
            <td>
                {renderDropdown(`${key}_id`, options)}
            </td>
            <td>
                <MultiSelect
                    id={key}
                    disabled={loading}
                    className={state[`${key}_plant_id_required`] ? "p-invalid" : ""}
                    style={{ width: "100%" }} value={state[plant_id]}
                    options={state.plants} onChange={(e) => setState({ [plant_id]: e.value })} filter
                    emptyFilterMessage={getLabel('multiEmptyItems')}
                    selectedItemsLabel={getLabel('database.plantSelected')}
                />
            </td>
            <td className='cost-price-left'>
                {renderInput(`${key}_price`, state[`${key}_price`])}
            </td>
            <td>
                {renderCalendar(`${key}_date`, state[`${key}_date`])}
            </td>
            <td>
                {renderUnit(unit)}
            </td>
            <td>
                {renderButton(key, entity)}
            </td>
        </tr >
        )
    }
    const renderDropdown = (key, options) => {
        return (
            options === null ? getLabel('no_source') :
                <Dropdown
                    className={state[`${key}_required`] ? "p-invalid" : ""}
                    showClear disabled={state.loading} id={key}
                    style={{ width: '100%' }} value={state[key]} options={options}
                    onChange={(e) => setState({ [key]: e.value })}
                />)
    }

    const renderInput = (key, value) => {
        return (
            <InputNumber
                className={state[`${key}_required`] ? "cost-price-input p-invalid" : "cost-price-input"}
                disabled={state.loading} id={key} value={value} step={100}
                prefix={currencySymbol()} minFractionDigits={2}
                onChange={(e) => setState({ [key]: e.value })} />
        )
    }

    const renderCalendar = (key, value) => {
        return (
            <Calendar
                className={state[`${key}_required`] ? "p-invalid" : ""}
                disabled={state.loading} id='date' showButtonBar
                showIcon={true} locale={getLang()} value={value}
                onChange={(e) => setState({ [key]: e.value })} />
        )
    }

    const renderUnit = (key) => {
        if (key === 'ton' || key === 'kg') {
            return `${currencySymbol()}/${key}`
        }
        else
            return key
    }

    const renderButton = (event, entity) => {
        return (
            <Button
                label={getLabel('cost.update')} className='p-button-sm' disabled={loading}
                onClick={() => onSaveCost(event, entity)} />
        )
    }

    const onSaveCost = (event, entity) => {
        const plant_ids = state[`${event}_plant_id`];
        const value = state[`${event}_id`];
        const price = state[`${event}_price`];
        const eventDate = state[`${event}_date`];
        const cost = {
            attribute: event,
            entity,
            plant_ids,
            value,
            price,
            date: moment(eventDate).format('YYYY-MM-DD')
        }
        let valid = true;
        let requiredRowFields = {}

        logEvent("[Cost Pricing] New Cost");
        if (!plant_ids || plant_ids.length === 0) {
            valid = false;
            requiredRowFields[`${event}_plant_id_required`] = true;
        }
        else {
            requiredRowFields[`${event}_plant_id_required`] = false;

        }

        if (!value && entity !== 'samples' && entity && entity !== 'null') {
            valid = false;
            requiredRowFields[`${event}_id_required`] = true;
        }
        else {
            requiredRowFields[`${event}_id_required`] = false;
        }

        if (!price) {
            valid = false;
            requiredRowFields[`${event}_price_required`] = true;
        }
        else {
            requiredRowFields[`${event}_price_required`] = false;
        }

        if (!eventDate) {
            valid = false;
            requiredRowFields[`${event}_date_required`] = true;
        }
        else {
            requiredRowFields[`${event}_date_required`] = false;
        }

        if (!valid) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('cost.requiredField') });
            setState(requiredRowFields);
            return;
        }
        saveCost(cost).then(d => {
            if (d.success) {
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('cost.success') });
            }
            else {
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('cost.error') })

            }
            setState(requiredRowFields);
        }).catch(err => {
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('cost.error') })
        })
    }

    const { loading, otherFields } = state;

    return (
        <div>
            {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px' }} />)}
            <div className="p-grid">
                <div className="p-col">
                    <div className="p-datatable p-component p-datatable-border p-datatable-sm header-center p-datatable-gridlines">
                        <div className="p-datatable-wrapper">
                            <table border="1" role="grid" className="cost-price-table">

                                <thead className="p-datatable-thead">
                                    <tr>
                                        <th>{getLabel('cost.product')}</th>
                                        <th>{getLabel('cost.source')}</th>
                                        <th>{getLabel('plant')}</th>
                                        <th>{getLabel('cost.cost')}</th>
                                        <th>{getLabel('cost.date')}</th>
                                        <th>{getLabel('cost.unit')}</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody className="p-datatable-tbody">

                                    {renderRow('cement', getLabel('cement'), 'cements', 'ton', state.cements)}

                                    {renderRow('mineral', getLabel('mineral'), 'minerals', 'ton', state.minerals)}

                                    {renderRow('additive', getLabel('additive'), 'additives', 'kg', state.additives)}

                                    {renderRow('natural_sand', getLabel('natural_sand_cost'), 'aggregate_sources', 'ton', state.naturalSandSources)}

                                    {renderRow('crushed_sand', getLabel('crushed_sand_cost'), 'aggregate_sources', 'ton', state.crushedSandSources)}

                                    {renderRow('no_1', getLabel('no_1_cost'), 'aggregate_sources', 'ton', state.aggNoOneSources)}

                                    {renderRow('no_2', getLabel('no_2_cost'), 'aggregate_sources', 'ton', state.aggNoTwoSources)}

                                    {
                                        otherFields && otherFields.map(field => (
                                            renderRow(
                                                field.key,
                                                field[`label_${getLang()}`],
                                                field.entity,
                                                field[`unit_${getLang()}`],
                                                null
                                            )
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
            <Toast ref={(el) => setToast(el)} />
        </div>
    )
};

export default withTranslation()(CostPricing);