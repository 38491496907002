import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Header from './common/Header';
import MyLoader from './common/MyLoader';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { dailyPredict, runPredict } from '../actions/predict';
import { listItems } from '../actions/list';
import Footer from './common/Footer';
import { uiCheck } from '../actions/user';
import { logEvent } from '../helpers/amplitude';
import { getLabel, dateFormat } from '../helpers/translator';



const DailyPredict = ({ history }) => {
    const [toast, setToast] = useState({});
    const [plants] = useState([]);
    const [plant, setPlant] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageLoad, setPageLoad] = useState(true);

    useEffect(() => {
        uiCheck('daily-predict').then(d => { if (d.success) setPageLoad(false) }).catch(e => { setPageLoad(false) });
        logEvent("[Daily Predict] View");

    }, []);

    useEffect(() => {
        if (plants.length === 0) {
            setLoading(true);
            listItems('plant').then(d => {
                setLoading(false);
                if (d.success) {
                    d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
                }
            });
        }

    }, [plants]);

    useEffect(() => {
        if (plant) {
            setLoading(true);
            logEvent("[Daily Predict] Plant Change");
            dailyPredict(plant).then(d => {
                setLoading(false);
                if (d.success) {
                    setData(d.data);
                    const empties = d.data.filter(f => f.data.length === 0).length;
                    if (empties === d.data.length)
                        toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('dailyPredict.empty') });
                }
                else {
                    toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('dailyPredict.fetchError') });
                }
            }).catch(err => {
                setLoading(false);
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('dailyPredict.fetchError') });
            });
        }

    }, [plant, toast]);


    const run = () => {
        logEvent("[Daily Predict] Run");
        setLoading(true);
        let params = {};
        data.forEach(d => {
            let samples = []
            d.data.forEach(d => {
                samples.push(d.id);
            })
            params[`${d.day}_${d.ultimate}`] = samples;
        });

        runPredict(params).then(d => {
            if (d.success) {
                if (d.data.no_data) {
                    toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('dailyPredict.empty') });
                }
                else {
                    Object.keys(d.data).forEach(results => {
                        const [value, ultimate] = results.split("_");
                        const rowData = data.find(f => f.day === parseInt(value) && f.ultimate === parseInt(ultimate));
                        if (rowData && d.data[results]?.length > 0) {
                            d.data[results].forEach(res => {
                                const item = rowData.data.find(f => f.id === res.id);
                                if (item) {
                                    item['predict.prediction'] = res.prediction;
                                }
                            });
                        }
                    });
                    setData(data);
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                toast.show({ severity: 'error', sticky: true, summary: getLabel('error'), detail: getLabel('dailyPredict.runError') });
            }

        }).catch(e => {
            setLoading(false);
            toast.show({ severity: 'error', sticky: true, summary: getLabel('error'), detail: getLabel('dailyPredict.runError') });
        });
    }

    const renderColumn = (rowData, key) => {
        if (key === 'date')
            return moment(rowData[key]).format((dateFormat())).toString();
        else if (key === 'predict.prediction')
            return rowData[key] !== null ? rowData[key].toFixed(2) : null;
        else
            return rowData[key];
    }

    const renderHeader = (rowData) => {
        return getLabel('dailyPredict.header_day').replace("#DAY#", rowData.day).replace("#ULTIMATE#", rowData.ultimate);
    }

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }

            {
                !pageLoad && (
                    <div>
                        {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
                        <Header history={history} />
                        <Card title={getLabel('dailyPredict.title')} subTitle={getLabel('dailyPredict.subTitle')}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown disabled={loading} id="plant" showClear style={{ width: '100%' }} value={plant} options={plants} onChange={(e) => setPlant(e.value)} />
                                                <label htmlFor="plant">{getLabel('plant')}</label>
                                            </span>

                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label={getLabel('run')} className='p-button-raised p-button-sm'
                                                icon="pi pi-sitemap" iconPos="left" disabled={loading}
                                                onClick={() => run()} />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12">
                                    {
                                        data && data.map(d => (
                                            <div className="datatable-responsive marginBottom20">
                                                <DataTable key={d.day} className="p-datatable-responsive p-datatable-sm header-center p-datatable-gridlines" header={renderHeader(d)} value={d.data} emptyMessage={getLabel('dailyPredict.emptyTableMessage')}>
                                                    <Column field="date" header={getLabel('date')} headerStyle={{ textAlign: 'center' }} body={(e) => renderColumn(e, 'date')} />
                                                    <Column field="sample_no" header={getLabel('sample_no')} headerStyle={{ textAlign: 'center' }} body={(e) => renderColumn(e, 'sample_no')} />
                                                    <Column field="classes.name" header={getLabel('class')} headerStyle={{ textAlign: 'center' }} body={(e) => renderColumn(e, 'classes.name')} />
                                                    <Column field="predict.prediction" header={getLabel('prediction')} headerStyle={{ textAlign: 'center' }} body={(e) => renderColumn(e, 'predict.prediction')} style={{ fontWeight: 'bold' }} />
                                                    <Column field="statement" header={getLabel('notes')} headerStyle={{ textAlign: 'center' }} body={(e) => renderColumn(e, 'statement')} />
                                                </DataTable>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>

                            {loading && (<MyLoader />)
                            }
                        </Card>
                        <Footer />
                        <Toast ref={(el) => setToast(el)} />
                    </div>

                )
            }
        </div>
    )
};

export default withTranslation()(DailyPredict);