import { change_lng, change_password, ui_check, update_limits, change_report_settings } from '../api/user';

export const changeLng = (lng) =>
    new Promise(function (resolve, reject) {
        change_lng(lng)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const changePassword = (pwd) =>
    new Promise(function (resolve, reject) {
        change_password(pwd)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const uiCheck = (page) =>
    new Promise(function (resolve, reject) {
        ui_check(page)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const updateLimits = (limits) =>
    new Promise(function (resolve, reject) {
        update_limits(limits)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const changeReportSettings = (settings) =>
    new Promise(function (resolve, reject) {
        change_report_settings(settings)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });