import React, { useEffect } from 'react';
import { logEvent } from '../helpers/amplitude';

const NotFound = ({ history }) => {
    useEffect(() => {
        logEvent("[Not Found Page] View");
    }, []);

    return (
        <div className="exception-body notfound-body">
            <div className="exception-container">
                <img src="logo-black.png" alt="apollo-layout" />
                <h1>Not Found</h1>
                <p>Resource is not found.</p>
                <button className="p-button p-component p-button-text-icon-left" onClick={() => history.push('/manuel-predict')}>
                    <span className="pi pi-arrow-left p-c p-button-icon-left"></span>
                    <span className="p-button-text p-c">Go To Main Page</span>
                </button>
            </div>
        </div>
    )
};

export default NotFound;