import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Header from './common/Header';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import NewSample from './update-data/NewSample';
import StrengthWeight from './update-data/StrengthWeight';
import UpdateSample from './update-data/UpdateSample';
import { uiCheck } from '../actions/user';
import Footer from './common/Footer';
import { logEvent } from '../helpers/amplitude';

import { getLabel } from '../helpers/translator';

const UpdateData = ({ history }) => {
    const [pageLoad, setPageLoad] = useState(true);

    useEffect(() => {
        uiCheck('data').then(d => { setPageLoad(false) }).catch(e => { setPageLoad(false) });
        logEvent("[Data Entry] View")
    }, []);

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }
            {
                !pageLoad && (
                    <div>
                        <Header history={history} />
                        <Card title={getLabel('updateData.title')} subTitle={getLabel('updateData.subTitle')}>
                            <TabView>
                                <TabPanel header={getLabel('updateData.newSampleHeader')}>
                                    <NewSample history={history} />
                                </TabPanel>
                                <TabPanel header={getLabel('updateData.concreteHeader')}>
                                    <StrengthWeight history={history} />
                                </TabPanel>
                                <TabPanel header={getLabel('updateData.updateHeader')}>
                                    <UpdateSample history={history} />
                                </TabPanel>
                            </TabView>
                        </Card>
                        <Footer />
                    </div>
                )
            }

        </div>
    )
};

export default withTranslation()(UpdateData);