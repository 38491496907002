import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import { getLabel, getLang } from '../../helpers/translator';
import LineChart from './common/LineChart';
import Filter from './common/Filter';
import Table from './common/Table';
import Entry from './common/Entry';
import { logEvent } from '../../helpers/amplitude';


const LooseCompressedUnitWeight = ({ history, test, toast }) => {
  const testCode = 'loose-compressed-unit-weight';
  const { title, fields } = test?.definition || {};
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    logEvent('[Loose-Compressed Unit Weight] View');
  });

  return (
    <div>
      <Card title={title ? title[getLang()] : ''}>
        <TabView>
          <TabPanel header={title ? title[getLang()] : ''}>
            <Filter fields={fields ? fields.filter(f => f.filter) : []} loading={loading} setLoading={setLoading} setData={setData} toast={toast} testCode={testCode} />
            <Table testCode={testCode} fields={fields} loading={loading} data={data} toast={toast} setData={setData} setLoading={setLoading} />
            <LineChart testCode={testCode} fields={fields} loading={loading} data={data} title={title ? title[getLang()] : ''} keys={["unitWeight"]} />
          </TabPanel>
          <TabPanel header={getLabel('physicalTest.generalLabels.newTestHeader')}>
            <Entry fields={fields} loading={loading} setLoading={setLoading} testCode={testCode} toast={toast} />
          </TabPanel>
          <TabPanel header={getLabel('physicalTest.generalLabels.updateTestHeader')}>
            <Filter fields={fields ? fields.filter(f => f.filter) : []} loading={loading} setLoading={setLoading} setData={setData} toast={toast} testCode={testCode} />
            <Table testCode={testCode} fields={fields} loading={loading} data={data} toast={toast} setData={setData} setLoading={setLoading} editMode />
          </TabPanel>
        </TabView>
      </Card>
    </div>
  )
};

export default withTranslation()(LooseCompressedUnitWeight);