import { physical_test_detail, save_physical_test_data, update_physical_test_data, find_physical_test_data, find_max_test_no } from '../api/physicalTest';
import { write, getFromCache } from '../helpers/cache';


export const physicalTestDetail = (testCode) =>
    new Promise(function (resolve, reject) {
        const cached = getFromCache(testCode);
        if (cached) {
            resolve(cached);
            return;
        }
        physical_test_detail(testCode)
            .then(data => {
                if (data.success)
                    write(testCode, data);
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const savePhysicalTestData = (val) =>
    new Promise(function (resolve, reject) {
        save_physical_test_data(val)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const updatePhysicalTestData = (val) =>
    new Promise(function (resolve, reject) {
        update_physical_test_data(val)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const findPhysicalTestData = (val) =>
    new Promise(function (resolve, reject) {
        find_physical_test_data(val)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const findMaxTestNo = (val) =>
    new Promise(function (resolve, reject) {
        find_max_test_no(val)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });