import axios from "axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

    setAuthHeader: () => {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
    },

    setupInterceptors: (history) => {
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === 401) {
                localStorage.clear();
                document.cookie = "token=;";
                document.cookie = "userId=;";
                document.cookie = "userName=;";
                window.location.href = "#/";
            }

            if (error.response.status === 404) {
                // request not found
            }

            return Promise.reject(error);
        });
    }
}