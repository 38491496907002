import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { uiCheck } from '../actions/user';
import Footer from './common/Footer';
import { logEvent } from '../helpers/amplitude';
import { withTranslation } from 'react-i18next';
import { getLabel } from '../helpers/translator';

const PrivacyPolicy = ({ history }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        logEvent("[Privacy Policy] View");
        uiCheck('static').then(d => { if (d.success) setLoading(false) }).catch(e => { setLoading(false) })
    }, [])

    return (
        <div>
            {
                loading && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }

            {!loading && (
                <div>
                    <Header history={history} />
                    <Card title={getLabel('privacy.header')}>
                        <p>
                            {getLabel('privacy.p1')}
                        </p>

                        <h3>{getLabel('privacy.h1')}</h3>
                        <p>
                            {getLabel('privacy.p2')}
                        </p>

                        <p>
                            {getLabel('privacy.p3')}
                        </p>

                        <p>
                            {getLabel('privacy.p4')}
                        </p>
                        <p>

                            {getLabel('privacy.p5')}
                        </p>
                        <p>
                            {getLabel('privacy.p6')}
                        </p>
                        <p>
                            {getLabel('privacy.p7')}
                        </p>

                        <p>
                            {getLabel('privacy.p8')}
                        </p>

                        <h3>{getLabel('privacy.h2')}</h3>
                        <p>
                            {getLabel('privacy.p9')}
                        </p>
                        <p>
                            {getLabel('privacy.p10')}
                        </p>

                        <p>
                            {getLabel('privacy.p11')}
                        </p>

                        <p>
                            {getLabel('privacy.p12')}
                        </p>

                        <p>
                            {getLabel('privacy.p13')}
                        </p>

                        <p>
                            {getLabel('privacy.p14')}
                        </p>
                    </Card>
                    <Footer />
                </div>
            )}
        </div>

    )
};

export default withTranslation()(PrivacyPolicy);