import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Header from './common/Header';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import { Message } from 'primereact/message';
import CostReport from './cost/CostReport';
import CostPricing from './cost/CostPricing';
import { uiCheck } from '../actions/user';
import Footer from './common/Footer';
import { getLabel } from '../helpers/translator';
import MaterialPrice from './cost/MaterialPrice';
import { logEvent } from '../helpers/amplitude';


const Cost = ({ history }) => {
    const [pageLoad, setPageLoad] = useState(true);
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        uiCheck('cost').then(d => { setPageLoad(false); setAuth(d.auth); }).catch(e => { setPageLoad(false); setAuth(false); });
        logEvent("[Cost] View");
    }, []);

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }
            {
                !pageLoad && (
                    <div>
                        <Header history={history} />

                        <Card title={getLabel('cost.title')} subTitle={getLabel('cost.subTitle')}>
                            {
                                !auth && (
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <Message severity="error" text={getLabel('permission')}></Message>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                auth && (
                                    <TabView>
                                        <TabPanel header={getLabel('cost.report')}>
                                            <CostReport history={history} />
                                        </TabPanel>
                                        <TabPanel header={getLabel('cost.materialPrice')}>
                                            <MaterialPrice history={history} />
                                        </TabPanel>
                                        <TabPanel header={getLabel('cost.price')}>
                                            <CostPricing history={history} />
                                        </TabPanel>
                                    </TabView>
                                )
                            }

                        </Card>
                        <Footer />
                    </div>
                )
            }

        </div>
    )
};

export default withTranslation()(Cost);