import React from "react";
import { getLabel } from '../../helpers/translator';
import { withTranslation } from 'react-i18next';
const Footer = ({ history }) => {

    return (
        <div className="layout-footer p-component no-print">

            <div className="p-d-flex" style={{ alignItems: 'center' }}>
                <div className="p-mr-2">
                    <img
                        src="/optimix-logo-black.png"
                        alt="logo"
                        width="64"
                    />
                </div>
                <div className="">
                    <span className="p-menuitem-text p-text-center">©2021 Created By Mono - {getLabel('allRightReserved')}</span>
                </div>

            </div>

            <div className="p-d-flex">
                <div className="p-mr-2">
                    <a href="https://monoanalytics.com/contact" rel="noreferrer" target="_blank">{getLabel("contactUs")}</a>
                </div>
                <div className="p-mr-2">
                    <a href="/privacy">{getLabel("privacyPolicy")}</a>
                </div>
                <div className="">
                    <a href="/terms">{getLabel("termsOfUse")}</a>
                </div>
            </div>
        </div>
    );
};
export default withTranslation()(Footer);
