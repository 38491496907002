import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Header from './common/Header';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { listItems } from '../actions/list';
import { lastManuelPredict, manuelPredict, runPredict, manuelPredictCost } from '../actions/predict';
import MyInputNumber from './common/MyInputNumber';
import MyInputText from './common/MyInputText';
import { getLimit } from '../helpers/limit';
import { LocalStorage } from '../helpers/storage';
import Footer from './common/Footer';
import { uiCheck } from '../actions/user';
import { logEvent } from '../helpers/amplitude';
import { getLang, getLabel, currencySymbol, getAdditionalColumnDesc } from '../helpers/translator';
import MyLoader from './common/MyLoader';


const ManuelPredict = ({ history }) => {
    const [date, setDate] = useState(new Date());
    const [counter, setCounter] = useState(0);
    const [otherFields] = useState(LocalStorage.get('other_fields'));
    const [plants] = useState([]);
    const [plant, setPlant] = useState(null);
    const [classes] = useState([]);
    const [classDesigns] = useState([]);
    const [classItem, setClass] = useState(null);
    const [cements] = useState([]);
    const [cement, setCement] = useState(null);
    const [alternateCements] = useState([]);
    const [alternateCement, setAlternateCement] = useState();
    const [minerals] = useState([]);
    const [mineral, setMineral] = useState();
    const [additiveOne] = useState([]);
    const [additiveOneItem, setAdditiveOne] = useState();
    const [additiveTwo] = useState([]);
    const [additiveTwoItem, setAdditiveTwo] = useState();
    const [additiveThree] = useState([]);
    const [sampleTypes] = useState([]);
    const [naturalSandSources] = useState([]);
    const [crushedSandSources] = useState([]);
    const [aggNoOneSources] = useState([]);
    const [aggNoTwoSources] = useState([]);
    const [sampleType, setSampleType] = useState(null);
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [oldInputs, setOldInputs] = useState({});
    const [toast, setToast] = useState({});
    const [resultToast, setResultToast] = useState({});
    const [loading, setLoading] = useState(false);
    const [runLoading, setRunLoading] = useState(false);
    const [pageLoad, setPageLoad] = useState(true);
    const [showRequired, setShowRequired] = useState(false);
    const additionalReqFields = LocalStorage.get('req_fields');


    useEffect(() => {
        uiCheck('manuel').then(d => { if (d.success) setPageLoad(false) }).catch(e => { setPageLoad(false) });
        logEvent("[Manuel Predict] View");
    }, []);

    useEffect(() => {
        if (plants.length === 0) {
            listItems('plant').then(d => {
                if (d.success) {
                    d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (classDesigns.length === 0) {
            listItems('class_design').then(d => {
                if (d.success) {
                    d.data.forEach(i => classDesigns.push(i));
                }
            });
        }

        if (classes.length === 0) {
            listItems('class').then(d => {
                if (d.success) {
                    d.data.forEach(i => classes.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (cements.length === 0) {
            listItems('cement').then(d => {
                if (d.success) {
                    d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => alternateCements.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (minerals.length === 0) {
            listItems('mineral').then(d => {
                if (d.success) {
                    d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (additiveOne.length === 0) {
            listItems('additive').then(d => {
                if (d.success) {
                    d.data.forEach(i => additiveOne.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => additiveTwo.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => additiveThree.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (sampleTypes.length === 0) {
            listItems('sample_type').then(d => {
                if (d.success) {
                    d.data.forEach(i => sampleTypes.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (naturalSandSources.length === 0) {
            listItems('natural_sand').then(d => {
                if (d.success) {
                    d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (crushedSandSources.length === 0) {
            listItems('crushed_sand').then(d => {
                if (d.success) {
                    d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (aggNoOneSources.length === 0) {
            listItems('agg_no_one').then(d => {
                if (d.success) {
                    d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (aggNoTwoSources.length === 0) {
            listItems('agg_no_two').then(d => {
                if (d.success) {
                    d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
                }
            });
        }

    }, [plants, classDesigns, naturalSandSources, crushedSandSources, aggNoOneSources, aggNoTwoSources, classes, cements, alternateCements, minerals, additiveOne, additiveTwo, additiveThree, sampleTypes]);

    useEffect(() => {

        if (plant && classItem && inputs.class_design_id) {
            logEvent("[Manuel Predict] Main Input Changes");
            setLoading(true);
            lastManuelPredict(plant, classItem, inputs.class_design_id).then(d => {
                setLoading(false);
                if (d.success) {
                    setAdditiveOne(d.data.additive_one_id);
                    setAdditiveTwo(d.data.additive_two_id);
                    setAlternateCement(d.data.alternative_cement_id);
                    setCement(d.data.cement_id);
                    setMineral(d.data.mineral_id);
                    setSampleType(d.data.sample_type_id);
                    setOldInputs({ ...d.data });

                    setInputs(
                        {
                            add_one_amount: d.data.add_one_amount,
                            add_two_amount: d.data.add_two_amount,
                            agg_no_one: d.data.agg_no_one,
                            agg_no_two: d.data.agg_no_two,
                            alternative_cement_amount: d.data.alternative_cement_amount,
                            cement_amount: d.data.cement_amount,
                            crushed_sand: d.data.crushed_sand,
                            natural_sand_system_moisture: d.data.natural_sand_system_moisture,
                            crushed_sand_system_moisture: d.data.crushed_sand_system_moisture,
                            agg_no_one_system_moisture: d.data.agg_no_one_system_moisture,
                            agg_no_two_system_moisture: d.data.agg_no_two_system_moisture,
                            crushed_sand_methylene: d.data.crushed_sand_methylene,
                            mineral_amount: d.data.mineral_amount,
                            natural_sand: d.data.natural_sand,
                            natural_sand_methylene: d.data.natural_sand_methylene,
                            theoretical_water: d.data.theoretical_water,
                            natural_sand_source_id: d.data.natural_sand_source_id,
                            crushed_sand_source_id: d.data.crushed_sand_source_id,
                            class_design_id: d.data.class_design_id || inputs.class_design_id,
                            agg_no_one_source_id: d.data.agg_no_one_source_id,
                            agg_no_two_source_id: d.data.agg_no_two_source_id,
                            weather_temp: d.data.weather_temp,
                            concrete_temp: d.data.concrete_temp,
                            slump: d.data.slump,
                            flow_diameter: d.data.flow_diameter,
                            fresh_concrete_density: d.data.fresh_concrete_density,
                            air_percent: d.data.air_percent

                        });

                    setErrors(
                        {
                            add_one_amount: d.data.add_one_amount && (d.data.add_one_amount < getLimit('add_one_amount', 'lower') || d.data.add_one_amount > getLimit('add_one_amount', 'upper')),
                            add_two_amount: d.data.add_two_amount && (d.data.add_two_amount < getLimit('add_two_amount', 'lower') || d.data.add_two_amount > getLimit('add_two_amount', 'upper')),
                            agg_no_one: d.data.agg_no_one && (d.data.agg_no_one < getLimit('agg_no_one', 'lower') || d.data.agg_no_one > getLimit('agg_no_one', 'upper')),
                            agg_no_two: d.data.agg_no_two && (d.data.agg_no_two < getLimit('agg_no_two', 'lower') || d.data.agg_no_one > getLimit('agg_no_one', 'upper')),
                            alternative_cement_amount: d.data.alternative_cement_amount && (d.data.alternative_cement_amount < getLimit('alternative_cement_amount', 'lower') || d.data.alternative_cement_amount > getLimit('alternative_cement_amount', 'upper')),
                            cement_amount: d.data.cement_amount && (d.data.cement_amount < getLimit('cement_amount', 'lower') || d.data.cement_amount > getLimit('cement_amount', 'upper')),
                            crushed_sand: d.data.crushed_sand && (d.data.crushed_sand < getLimit('crushed_sand', 'lower') || d.data.crushed_sand > getLimit('crushed_sand', 'upper')),
                            crushed_sand_methylene: d.data.crushed_sand_methylene && (d.data.crushed_sand_methylene < getLimit('crushed_sand_methylene', 'lower') || d.data.crushed_sand_methylene > getLimit('crushed_sand_methylene', 'upper')),
                            mineral_amount: d.data.mineral_amount && (d.data.mineral_amount < getLimit('mineral_amount', 'lower') || d.data.mineral_amount > getLimit('mineral_amount', 'upper')),
                            natural_sand: d.data.natural_sand && (d.data.natural_sand < getLimit('natural_sand', 'lower') || d.data.natural_sand > getLimit('natural_sand', 'upper')),
                            natural_sand_methylene: d.data.natural_sand_methylene && (d.data.natural_sand_methylene < getLimit('natural_sand_methylene', 'lower') || d.data.natural_sand_methylene > getLimit('natural_sand_methylene', 'upper')),
                            theoretical_water: d.data.theoretical_water && (d.data.theoretical_water < getLimit('theoretical_water', 'lower') || d.data.theoretical_water > getLimit('theoretical_water', 'upper')),
                            weather_temp: d.data.weather_temp && (d.data.weather_temp < getLimit('weather_temp', 'lower') || d.data.weather_temp > getLimit('weather_temp', 'upper')),
                            concrete_temp: d.data.concrete_temp && (d.data.concrete_temp < getLimit('concrete_temp', 'lower') || d.data.concrete_temp > getLimit('concrete_temp', 'upper')),
                            slump: d.data.slump && (d.data.slump < getLimit('slump', 'lower') || d.data.slump > getLimit('slump', 'upper')),
                            flow_diameter: d.data.flow_diameter && (d.data.flow_diameter < getLimit('flow_diameter', 'lower') || d.data.flow_diameter > getLimit('flow_diameter', 'upper')),
                            fresh_concrete_density: d.data.fresh_concrete_density && (d.data.fresh_concrete_density < getLimit('fresh_concrete_density', 'lower') || d.data.fresh_concrete_density > getLimit('fresh_concrete_density', 'upper')),
                            air_percent: d.data.air_percent && (d.data.air_percent < getLimit('air_percent', 'lower') || d.data.air_percent > getLimit('air_percent', 'upper')),
                        });
                }
            });
        }

    }, [plant, classItem, inputs.class_design_id]);


    const predict = () => {
        if (!validRequired()) {
            logEvent("[Manuel Predict] Required Fields");
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('manuelPredict.reqFields') });
            return;
        }

        const err = Object.keys(errors).find(k => errors[k]);
        if (err) {
            logEvent("[Manuel Predict] Limit Error");
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('limit.warn') });
            return;
        }
        logEvent("[Manuel Predict] Run");
        setRunLoading(true);
        const predictObj = {
            date: moment(date).format('YYYY-MM-DD'),
            plant_id: plant,
            class_id: classItem,
            cement_id: cement,
            alternative_cement_id: alternateCement,
            mineral_id: mineral,
            additive_one_id: additiveOneItem,
            additive_two_id: additiveTwoItem,
            sample_type_id: sampleType,
            inputs
        }

        const externalData = {};
        otherFields.forEach(d => { externalData[d.code] = inputs[d.code] })
        manuelPredict(predictObj).then(d => {
            if (d.success) {

                runPredict({
                    manuel: true,
                    data: d.data,
                    model: d.model,
                    externalData
                }).then(r => {
                    setRunLoading(false);
                    if (r.success && r.data.no_data) {
                        toast.show({ severity: 'warn', sticky: true, summary: getLabel('warning'), detail: getLabel('manuelPredict.noData') })
                    }
                    else if (r.success) {
                        manuelPredictCost(d.data).then(res => {
                            showResult(r?.data[d.model][0].prediction.toFixed(2), res.data, plant, classItem);
                        }).catch(err => {
                            showResult(r?.data[d.model][0].prediction.toFixed(2), null, plant, classItem);
                        })
                    }
                    else {
                        toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('manuelPredict.runErrorMsg') })
                    }
                }).catch(e => {
                    setRunLoading(false);
                    toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('manuelPredict.runErrorMsg') })
                });
            }
            else {
                setRunLoading(false);
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('manuelPredict.runErrorMsg') });
            }

        }).catch(err => {
            setRunLoading(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('manuelPredict.runErrorMsg') })
        });
    }

    const checkChanges = (key) => {
        if (Object.entries(oldInputs).length !== 0 && (oldInputs[key] !== null && oldInputs[key] !== undefined)) {
            return oldInputs[key] !== inputs[key];
        }
        else if (inputs[key] !== null && inputs[key] !== undefined)
            return true;
        else
            return false;
    }

    const checkShowRequired = (key) => {
        if (showRequired) {
            const field = additionalReqFields.find(f => f === key);
            return field !== undefined;
        }
        else {
            return false;
        }
    }

    const checkDropdowns = (key) => {
        if (key === 'cement') {
            if (oldInputs['cement_id'])
                return cement !== oldInputs['cement_id']
            else if (!oldInputs['cement_id'] && cement)
                return true;
            else
                return false
        }

        else if (key === 'alternateCement') {
            if (oldInputs['alternative_cement_id'])
                return alternateCement !== oldInputs['alternative_cement_id']
            else if (!oldInputs['alternative_cement_id'] && alternateCement)
                return true;
            else
                return false
        }

        else if (key === 'mineral') {
            if (oldInputs['mineral_id'])
                return mineral !== oldInputs['mineral_id']
            else if (!oldInputs['mineral_id'] && mineral)
                return true;
            else
                return false;
        }

        else if (key === 'additiveOneItem') {
            if (oldInputs['additive_one_id'])
                return additiveOneItem !== oldInputs['additive_one_id']
            else if (!oldInputs['additive_one_id'] && additiveOneItem)
                return true;
            else
                return false;
        }

        else if (key === 'additiveTwoItem') {
            if (oldInputs['additive_two_id'])
                return additiveTwoItem !== oldInputs['additive_two_id']
            else if (!oldInputs['additive_two_id'] && additiveTwoItem)
                return true;
            else
                return false;
        }

        else if (key === 'sample_type_id') {
            if (oldInputs['sample_type_id'])
                return sampleType !== oldInputs['sample_type_id']
            else if (!oldInputs['sample_type_id'] && sampleType)
                return true;
            else
                return false;
        }
        else
            return false;

    }

    const changeInput = (key, e) => {
        if (getLimit(key, 'lower') !== null || getLimit(key, 'upper') !== null)
            setErrors(prevState => { return { ...prevState, [key]: e.value !== null && e.value !== undefined && (e.value < getLimit(key, 'lower') || e.value > getLimit(key, 'upper')) } });
        setInputs(prevState => { return { ...prevState, [key]: e.value } });
    }

    const validRequired = () => {
        if (!date || !plant || !classItem || !cement || !sampleType
            || !inputs.cement_amount || !inputs.theoretical_water
            || !inputs.crushed_sand || !inputs.agg_no_one
            || !inputs.class_design_id
            || !inputs.crushed_sand_source_id || !inputs.agg_no_one_source_id
            || !inputs.weather_temp || !inputs.concrete_temp
            || !inputs.fresh_concrete_density) {
            setShowRequired(true);
            return false;
        }
        if ((!additiveOneItem || !inputs.add_one_amount) &&
            (!additiveTwoItem || !inputs.add_two_amount)) {
            setShowRequired(true);
            return false;
        }

        if (!inputs.slump && !inputs.flow_diameter) {
            setShowRequired(true);
            return false;
        }

        let additionalReq = true;
        additionalReqFields.forEach(d => {
            if (inputs[d] === null || inputs[d] === undefined) {
                additionalReq = false;
                setShowRequired(true);
                return false;
            }
        })
        if (additionalReq)
            setShowRequired(false);
        return additionalReq;
    }

    const showResult = (predict, cost, plant, classItem) => {
        logEvent("[Manuel Predict] Get Predict Result", { 'predictCount': counter });
        resultToast.show({
            severity: 'success', sticky: true, content: (
                <div className="p-flex p-flex-column " style={{ flex: '1' }}>
                    <div className="p-text-center">
                        <h4>{getLabel('manuelPredict.resultHeader')}</h4>
                        <h5>{getLabel('plant')} : {plants.find(f => f.value === plant)?.label} {getLabel('class')} : {classes.find(f => f.value === classItem)?.label}</h5>
                        <p style={{ fontSize: 24 }}>{predict}  N/mm2</p>
                        <p style={{ fontSize: 20 }}>{getLabel('cost.cost')}: {cost} {currencySymbol()}</p>
                    </div>
                </div>
            )
        });
        setCounter(counter + 1);
        setRunLoading(false);
    }

    const selectBoxChanged = (key) => {
        return checkDropdowns(key) ? "select-box-changed" :
            (
                (key === 'cement' && cement === null) ||
                (key === 'sample_type_id' && sampleType === null) ||
                (key === 'agg_no_one_source_id' && !inputs.agg_no_one_source_id) ||
                (key === 'crushed_sand_source_id' && !inputs.crushed_sand_source_id) ||
                (key === 'class_design_id' && !inputs.class_design_id)
            ) && showRequired ? "p-invalid" : "";
    }

    const fresh_classes = [
        'p-grid',
        'p-col-12',
        counter > 0 ? 'p-md-3' : 'p-md-3'
    ]

    const recipe_classes = [
        'p-grid',
        'p-col-12',
        counter > 0 ? 'p-md-7' : 'p-md-9'
    ]

    const filter_classes = [
        'p-grid',
        counter > 0 ? 'p-col-10' : 'p-col-12'
    ]
    const classDesign = classDesigns.filter(f => f.class_id === classItem).map(i => { return { label: i.name, value: i.id } });
    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }
            {!pageLoad && (
                <div>
                    <div>
                        {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px' }} />)}

                        <Header history={history} />
                        <Card title={getLabel('manuelPredict.title')} subTitle={getLabel('manuelPredict.subTitle')}>
                            <div className="p-grid p-fluid">
                                <div className={filter_classes.join(" ")}>
                                    <div className="p-col-12 p-md-3">
                                        <span className="p-float-label">
                                            <Calendar className={showRequired && date === null ? "p-invalid" : ''} disabled={loading} id='date' showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={date} onChange={(e) => setDate(e.value)}></Calendar>
                                            <label htmlFor="date">{getLabel('date')}</label>
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <span className="p-float-label">
                                            <Dropdown className={showRequired && plant === null ? "p-invalid" : ''} disabled={loading} id="plant" showClear style={{ width: '100%' }} value={plant} options={plants} onChange={(e) => setPlant(e.value)} />
                                            <label htmlFor="plant">{getLabel('plant')}</label>
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <span className="p-float-label">
                                            <Dropdown className={showRequired && classItem === null ? "p-invalid" : ''} disabled={loading} id="class" showClear style={{ width: '100%' }} value={classItem} options={classes} onChange={(e) => setClass(e.value)} />
                                            <label htmlFor="class">{getLabel('class')}</label>
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <Button disabled={loading} style={{ width: "100%" }} onClick={() => predict()} label={getLabel('manuelPredict.predictBtn')} className='p-button-raised' />
                                    </div>
                                </div>

                                <div className={recipe_classes.join(" ")}>
                                    <Panel header={getLabel('manuelPredict.recipeHeader')} toggleable className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="class_design_id" className={selectBoxChanged('class_design_id')} showClear
                                                        style={{ width: '100%' }} value={inputs.class_design_id} options={classDesign}
                                                        onChange={(e) => changeInput('class_design_id', e)} />
                                                    <label htmlFor="class_design">{getLabel('class_design')}</label>
                                                </span>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="cement" className={selectBoxChanged('cement')} showClear
                                                        style={{ width: '100%' }} value={cement} options={cements}
                                                        onChange={(e) => {
                                                            if (!e.value) changeInput('cement_amount', { value: null });
                                                            setCement(e.value)
                                                        }} />
                                                    <label htmlFor="cement">{getLabel('cement')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={showRequired} disabled={loading} customClass="p-col-12 p-md-4 p-invalid" htmlFor="cement_val" id="cement_amount" step={5} suffix=" kg"
                                                checkChanges={checkChanges('cement_amount')} value={inputs.cement_amount} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="alter_cement" className={selectBoxChanged("alternateCement")}
                                                        showClear style={{ width: '100%' }} value={alternateCement} options={alternateCements}
                                                        onChange={(e) => {
                                                            if (!e.value) changeInput('alternative_cement_amount', { value: null });
                                                            setAlternateCement(e.value)
                                                        }} />
                                                    <label htmlFor="alter_cement">{getLabel('alternate_cement')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={checkShowRequired('alternative_cement_amount')} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="alternate_cement_val" id="alternative_cement_amount" step={5} suffix=" kg"
                                                checkChanges={checkChanges('alternative_cement_amount')} value={inputs.alternative_cement_amount} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="mineral" className={selectBoxChanged("mineral")} showClear
                                                        style={{ width: '100%' }} value={mineral} options={minerals}
                                                        onChange={(e) => {
                                                            if (!e.value) changeInput('mineral_amount', { value: null });
                                                            setMineral(e.value)
                                                        }} />
                                                    <label htmlFor="mineral">{getLabel('mineral')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={checkShowRequired('mineral_amount')} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="mineral_val" id="mineral_amount" step={5} suffix=" kg"
                                                checkChanges={checkChanges('mineral_amount')} value={inputs.mineral_amount} change={changeInput} />

                                            <MyInputNumber required={showRequired} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="theorical_water" id="theoretical_water" step={1} suffix=" kg"
                                                checkChanges={checkChanges('theoretical_water')} value={inputs.theoretical_water} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="add_1" className={selectBoxChanged("additiveOneItem")} showClear
                                                        style={{ width: '100%' }} value={additiveOneItem} options={additiveOne}
                                                        onChange={(e) => {
                                                            if (!e.value) changeInput('add_one_amount', { value: null });
                                                            setAdditiveOne(e.value)
                                                        }} />
                                                    <label htmlFor="add_1">{getLabel('additive_one')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber disabled={loading} add_prefix={true} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="additive_one_val" id="add_one_amount" step={0.1} suffix={LocalStorage.get("additive_unit")}
                                                checkChanges={checkChanges('add_one_amount')} value={inputs.add_one_amount} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="add_2" className={selectBoxChanged("additiveTwoItem")} showClear
                                                        style={{ width: '100%' }} value={additiveTwoItem} options={additiveTwo}
                                                        onChange={(e) => {
                                                            if (!e.value) changeInput('add_two_amount', { value: null });
                                                            setAdditiveTwo(e.value)
                                                        }} />
                                                    <label htmlFor="add_2">{getLabel('additive_two')}</label>
                                                </span>

                                            </div>

                                            <MyInputNumber disabled={loading} add_prefix={true} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="additive_two_val" id="add_two_amount" step={0.1} suffix={LocalStorage.get("additive_unit")}
                                                checkChanges={checkChanges('add_two_amount')} value={inputs.add_two_amount} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown className={selectBoxChanged("natural_sand_source_id")} id="natural_sand_source_id" showClear style={{ width: '100%' }} value={inputs.natural_sand_source_id} options={naturalSandSources} onChange={(e) => {
                                                        if (!e.value) changeInput('natural_sand', { value: null });
                                                        changeInput('natural_sand_source_id', e)
                                                    }} />
                                                    <label htmlFor="natural_sand_source">{getLabel('natural_sand_source')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={checkShowRequired('natural_sand')} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="natural_sand" id="natural_sand" step={1} suffix=" kg"
                                                checkChanges={checkChanges('natural_sand')} value={inputs.natural_sand} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} className={selectBoxChanged("crushed_sand_source_id")} id="crushed_sand_source_id" showClear style={{ width: '100%' }} value={inputs.crushed_sand_source_id} options={crushedSandSources} onChange={(e) => {
                                                        if (!e.value) changeInput('crushed_sand', { value: null });
                                                        changeInput('crushed_sand_source_id', e)
                                                    }} />
                                                    <label htmlFor="crushed_sand_source">{getLabel('crushed_sand_source')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={showRequired} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="crushed_sand" id="crushed_sand" step={1} suffix=" kg"
                                                checkChanges={checkChanges('crushed_sand')} value={inputs.crushed_sand} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown className={selectBoxChanged("agg_no_one_source_id")} id="agg_no_one_source_id" showClear style={{ width: '100%' }} value={inputs.agg_no_one_source_id} options={aggNoOneSources} onChange={(e) => {
                                                        if (!e.value) changeInput('agg_no_one', { value: null });
                                                        changeInput('agg_no_one_source_id', e)
                                                    }} />
                                                    <label htmlFor="no_1_source">{getLabel('no_1_source')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={showRequired} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="no_1" id="agg_no_one" step={1} suffix=" kg"
                                                checkChanges={checkChanges('agg_no_one')} value={inputs.agg_no_one} change={changeInput} />

                                            <div className="p-col-12 p-md-4">
                                                <span className="p-float-label">
                                                    <Dropdown className={selectBoxChanged("agg_no_two_source_id")} id="agg_no_two_source_id" showClear style={{ width: '100%' }} value={inputs.agg_no_two_source_id} options={aggNoTwoSources} onChange={(e) => {
                                                        if (!e.value) changeInput('agg_no_two', { value: null });
                                                        changeInput('agg_no_two_source_id', e)
                                                    }} />
                                                    <label htmlFor="no_2_source">{getLabel('no_2_source')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={checkShowRequired('agg_no_two')} disabled={loading} customClass="p-col-12 p-md-4" htmlFor="no_2" id="agg_no_two" step={1} suffix=" kg"
                                                checkChanges={checkChanges('agg_no_two')} value={inputs.agg_no_two} change={changeInput} />

                                            <MyInputNumber required={checkShowRequired('natural_sand_system_moisture')} disabled={loading} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="natural_sand_system_moisture" id="natural_sand_system_moisture" step={0.1} prefix="%"
                                                checkChanges={checkChanges('natural_sand_system_moisture')} value={inputs.natural_sand_system_moisture} change={changeInput} />

                                            <MyInputNumber required={checkShowRequired('crushed_sand_system_moisture')} disabled={loading} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="crushed_sand_system_moisture" id="crushed_sand_system_moisture" step={0.1} prefix="%"
                                                checkChanges={checkChanges('crushed_sand_system_moisture')} value={inputs.crushed_sand_system_moisture} change={changeInput} />

                                            <MyInputNumber required={checkShowRequired('agg_no_one_system_moisture')} disabled={loading} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="agg_no_one_system_moisture" id="agg_no_one_system_moisture" step={0.1} prefix="%"
                                                checkChanges={checkChanges('agg_no_one_system_moisture')} value={inputs.agg_no_one_system_moisture} change={changeInput} />

                                            <MyInputNumber required={checkShowRequired('agg_no_two_system_moisture')} disabled={loading} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="agg_no_two_system_moisture" id="agg_no_two_system_moisture" step={0.1} prefix="%"
                                                checkChanges={checkChanges('agg_no_two_system_moisture')} value={inputs.agg_no_two_system_moisture} change={changeInput} />

                                        </div>
                                    </Panel>

                                    {
                                        otherFields && otherFields.length > 0 && (
                                            <Panel header={getLabel('newSample.otherInfo')} className="p-col-12" toggleable >
                                                <div className="p-grid">
                                                    {otherFields.map(field => (
                                                        [
                                                            field.field_type === 'FLOAT' && (
                                                                <MyInputNumber required={checkShowRequired(field.code)} key={field.id} customHeader={getAdditionalColumnDesc(field)} checkChanges={checkChanges(field.code)} customClass="p-col-12 p-md-4" minFractionDigits={2} id={field.code} step={0.1} value={inputs[field.code]} change={changeInput} validationLimit={false} />
                                                            ),

                                                            field.field_type === 'STRING' && (
                                                                <MyInputText required={checkShowRequired(field.code)} key={field.id} customHeader={getAdditionalColumnDesc(field)} checkChanges={checkChanges(field.code)} customClass="p-col-12 p-md-4" id={field.code} value={inputs[field.code]} change={(key, e) => { setInputs(prevState => { return { ...prevState, [key]: e.target.value } }) }} />
                                                            )
                                                        ]
                                                    ))}
                                                </div>
                                            </Panel>
                                        )
                                    }

                                    <Panel header={getLabel('newSample.inputMaterialMeasurements')} className="p-col-12" toggleable >
                                        <div className="p-grid">
                                            <MyInputNumber required={checkShowRequired('natural_sand_methylene')} checkChanges={checkChanges('natural_sand_methylene')} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="natural_sand_methylene" id="natural_sand_methylene" step={0.1} value={inputs.natural_sand_methylene} change={changeInput} />

                                            <MyInputNumber required={checkShowRequired('crushed_sand_methylene')} checkChanges={checkChanges('crushed_sand_methylene')} customClass="p-col-12 p-md-4" minFractionDigits={2} htmlFor="crushed_sand_methylene" id="crushed_sand_methylene" step={0.1} value={inputs.crushed_sand_methylene} change={changeInput} />
                                        </div>
                                    </Panel>
                                </div>

                                <div className={fresh_classes.join(" ")}>
                                    <Panel header={getLabel('manuelPredict.freshConcreteHeader')} toggleable className="p-col-12">
                                        <div className="p-grid">

                                            <MyInputNumber required={showRequired} disabled={loading} checkChanges={checkChanges('weather_temp')} customClass="p-col-12" htmlFor="temp" id="weather_temp" minFractionDigits={2} step={1} suffix=" ℃"
                                                value={inputs.weather_temp} change={changeInput} />

                                            <MyInputNumber required={showRequired} disabled={loading} checkChanges={checkChanges('concrete_temp')} customClass="p-col-12" htmlFor="concrete_temp" id="concrete_temp" minFractionDigits={2} step={1} suffix=" ℃"
                                                value={inputs.concrete_temp} change={changeInput} />

                                            <MyInputNumber disabled={loading} checkChanges={checkChanges('slump')} customClass="p-col-12" htmlFor="slump" id="slump" step={10} suffix=" mm"
                                                value={inputs.slump} change={changeInput} />

                                            <MyInputNumber disabled={loading} checkChanges={checkChanges('flow_diameter')} customClass="p-col-12" htmlFor="flow_diameter" id="flow_diameter" step={10} suffix=" mm"
                                                value={inputs.flow_diameter} change={changeInput} />


                                            <div className="p-col-12">
                                                <span className="p-float-label">
                                                    <Dropdown disabled={loading} id="sample_type" className={selectBoxChanged("sample_type_id")} showClear style={{ width: '100%' }} value={sampleType} options={sampleTypes} onChange={(e) => setSampleType(e.value)} />
                                                    <label htmlFor="sample_type">{getLabel('sample_type')}</label>
                                                </span>
                                            </div>

                                            <MyInputNumber required={showRequired} disabled={loading} checkChanges={checkChanges('fresh_concrete_density')} customClass="p-col-12" htmlFor="unit_weight" id="fresh_concrete_density" step={1} suffix=" kg/m3"
                                                value={inputs.fresh_concrete_density} change={changeInput} />

                                            <MyInputNumber required={checkShowRequired('air_percent')} disabled={loading} checkChanges={checkChanges('air_percent')} customClass="p-col-12" minFractionDigits={2} htmlFor="air_percent" id="air_percent" step={0.1} prefix="%"
                                                value={inputs.air_percent} change={changeInput} />

                                        </div>
                                    </Panel>

                                </div>

                            </div>
                            {
                                runLoading && (<MyLoader />)
                            }
                        </Card>
                        <Footer />

                        <Toast ref={(el) => setToast(el)} />
                        <div className="manuel-predict-result">
                            <Toast ref={(el) => setResultToast(el)} position="bottom-right" onRemove={(e) => { setCounter(counter - 1) }} />
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
};

export default withTranslation()(ManuelPredict);