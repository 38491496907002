import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { updateSamples, getSamples } from '../../actions/sample';
import { listItems } from '../../actions/list';
import { getLimit } from '../../helpers/limit';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { logEvent } from '../../helpers/amplitude';
import { getLang, getLabel } from '../../helpers/translator';

const StrengthWeight = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({});
    const [plants] = useState([]);
    const [data, setData] = useState([]);
    const [inputs, setInputs] = useState({ 'date': new Date() });
    const [pageLoad, setPageLoad] = useState(true);

    useEffect(() => {
        logEvent("[Strength Weight] View");

        if (plants.length === 0) {
            listItems('plant').then(d => {
                if (d.success) {
                    d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
                    setPageLoad(false);
                }
            });
        }
    }, [plants]);

    useEffect(() => {
        if (inputs?.plant_id && inputs?.date) {
            setLoading(true);
            getSamples(moment(inputs.date).format('YYYY-MM-DD'), inputs.plant_id).then(d => {
                setLoading(false);
                if (d.success) {
                    if (d.data.filter(f => f.value.length === 0).length === d.data.length)
                        toast.show({ 'severity': 'warn', summary: getLabel('info'), detail: getLabel('strengthWeight.empty') });
                    setData(_.cloneDeep(d.data));
                }
                else {
                    toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('strengthWeight.fetchError') });
                }
            }).catch(err => {
                setLoading(false);
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('strengthWeight.fetchError') });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs.plant_id, inputs.date]);

    const changeInput = (key, e) => {
        setInputs(prevState => { return { ...prevState, [key]: e.value } });
    }

    const afterNow = () => {
        return moment(inputs.date).isAfter(new Date());
    }

    const renderRow = (rowData, header) => {
        return (
            <React.Fragment>
                <span className="p-column-title">{header}</span>
                {rowData}
            </React.Fragment>
        );
    }

    const editStatement = (props) => {
        const { column, rowData } = props;
        const { breakData } = column.props;
        const dataRow = data.find(f => f.definition.id === breakData.id);
        const dataValue = dataRow?.value?.find(f => f.id === rowData.id);

        if (afterNow()) {
            toast.show({ severity: 'warn', summary: getLabel('info'), detail: getLabel('strengthWeight.afterNow') });
            return <div>{dataValue.statement}</div>;
        }

        return <InputText disabled={afterNow()} value={dataValue.statement} onChange={(e) => onStatementChange(props, e.target.value)} />;
    }

    const onStatementChange = (props, value) => {
        const newData = [...data];
        const { column, rowData } = props;
        const { breakData } = column.props;
        const dataRow = newData.find(f => f.definition.id === breakData.id);
        const dataValue = dataRow?.value?.find(f => f.id === rowData.id);
        dataValue['statement'] = value;
        dataValue['updated'] = true;
        setData(newData);
    }

    const renderBreak = (rowData, number_id, key, header) => {
        const row = rowData?.break_values.find(f => f.number_id === number_id);
        return (
            <React.Fragment>
                <span className="p-column-title">{header}</span>
                {row ? row[key] : ""}
            </React.Fragment>
        );
    }

    const editBreakRow = (props) => {
        debugger;
        const { column, rowIndex } = props;
        const { breakData, fieldType, number } = column.props;
        const dataRow = data.find(f => f.definition.id === breakData.id);
        const dataValue = dataRow?.value.length > 0 ? dataRow?.value[rowIndex] : null;
        if (!dataValue)
            return <div>Something went wrong!</div>;
        const breakValue = dataValue.break_values.find(f => f.number_id === number.id);
        const value = breakValue ? breakValue[fieldType] : null

        if (afterNow()) {
            toast.show({ severity: 'warn', summary: getLabel('info'), detail: getLabel('strengthWeight.afterNow') });
            return <div>{value}</div>;
        }
        if (value && breakValue && breakValue.id !== undefined) {
            toast.show({ severity: 'warn', summary: getLabel('info'), detail: getLabel('strengthWeight.notEmpty') });
            return <div>{value}</div>;
        }
        else
            return <InputNumber minFractionDigits={fieldType === 'weight' ? 0 : 2} useGrouping={false} disabled={afterNow()} value={value} onChange={(e) => onBreakEditorValueChange(props, e.value)} />;

    }

    const onBreakEditorValueChange = (props, value) => {
        const newData = [...data];
        const { column, rowData } = props;
        const { breakData, number, fieldType } = column.props;

        const dataRow = newData.find(f => f.definition.id === breakData.id);
        const dataValue = dataRow?.value?.find(f => f.id === rowData.id);
        const breakValue = dataValue.break_values.find(f => f.number_id === number.id);
        if (breakValue) {
            breakValue[fieldType] = value;
            breakValue['updated'] = true;
            dataValue['updated'] = true;
        }
        else {
            dataValue.break_values.push({
                break_id: breakData.id,
                number_id: number.id,
                sample_id: rowData.id,
                [fieldType]: value,
                'updated': true
            });
            dataValue['updated'] = true;
        }
        setData(newData);
    }

    const breakEditorValidator = (e) => {
        if (afterNow())
            return true;
        let { newRowData, column, rowData } = e;
        const { fieldType, number } = column.props;

        if (fieldType === 'weight') {
            const sample_type = newRowData?.sample_type?.name;
            const lower = getLimit(sample_type === '15X15X15 KÜP' ? 'weight_type_1' : 'weight_type_2', 'lower');
            const upper = getLimit(sample_type === '15X15X15 KÜP' ? 'weight_type_1' : 'weight_type_2', 'upper');
            const breakValue = newRowData.break_values.find(f => f.number_id === number.id);

            if (!breakValue) {
                rowData['error'] = false;
                return true;
            }

            if (breakValue && breakValue.id !== undefined) {
                return true;
            }

            if (breakValue.hasOwnProperty(fieldType) && (parseFloat(breakValue[fieldType]) < lower || parseFloat(breakValue[fieldType]) > upper)) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                rowData['error'] = true;
                return false;
            }
            else {
                rowData['error'] = false;
                return true;
            }
        }
        else if (fieldType === 'strength') {
            const lower = getLimit('strength', 'lower');
            const upper = getLimit('strength', 'upper');
            const breakValue = newRowData.break_values.find(f => f.number_id === number.id);

            if (!breakValue) {
                rowData['error'] = false;
                return true;
            }

            if (breakValue && breakValue.id !== undefined) {
                return true;
            }

            if (breakValue.hasOwnProperty(fieldType) && (parseFloat(breakValue[fieldType]) < lower || parseFloat(breakValue[fieldType]) > upper)) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: `${getLabel('limit.warn')} ${lower} - ${upper} ${getLabel('limit.info')}` });
                rowData['error'] = true;
                return false;
            }
            else {
                rowData['error'] = false;
                return true;
            }
        }

        return true;
    }

    const save = () => {
        toast.clear();
        let err = data.filter(d => d.value.find(f => f.error));
        if (err.length > 0) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('limit.warn') });
            logEvent("[Strength Weight] Limit Error");
            return;
        }
        logEvent("[Strength Weight] Save");
        setLoading(true);
        updateSamples({
            data: data.filter(d => d.value.find(f => f.updated)),
            activity: 'concreteSample'
        }).then(d => {
            setLoading(false);
            if (d.success) {
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('strengthWeight.updateSuccess') });
            }
            else {
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('strengthWeight.updateError') })
            }
        }).catch(e => {
            logEvent("[Strength Weight] Error on Save");
            setLoading(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('strengthWeight.updateError') })
        }
        );

    }

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }
            {
                !pageLoad && (
                    <div>
                        {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
                        <div className="p-grid p-fluid">

                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4">
                                        <span className="p-float-label">
                                            <Calendar id='date' showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={inputs.date} onChange={(e) => changeInput('date', e)}></Calendar>
                                            <label htmlFor="date">{getLabel('date')}</label>
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        <span className="p-float-label">
                                            <Dropdown id="plant" showClear style={{ width: '100%' }} value={inputs.plant_id} options={plants} onChange={(e) => changeInput('plant_id', e)} />
                                            <label htmlFor="plant">{getLabel('plant')}</label>
                                        </span>
                                    </div>

                                </div>
                            </div>

                            {
                                data && data.map(d => (
                                    <div id={d.definition.id} className="p-col-12">
                                        <div id={d.definition.id} className="datatable-responsive marginBottom20">
                                            <DataTable
                                                id={d.definition.id}
                                                className="p-datatable-responsive p-datatable-sm header-center p-datatable-gridlines table-text-center"
                                                header={`${getLabel('strengthWeight.day').replace("#VAL#", d.definition.value)}${d.special ? getLabel('special') : ''}`}
                                                value={d.value} editMode="cell" emptyMessage={getLabel('strengthWeight.emptyTableMessage')}>

                                                <Column
                                                    className="disabled-background"
                                                    field="sample_no"
                                                    header={getLabel('sample_no')}
                                                    body={(e) => renderRow(e?.sample_no, getLabel('sample_no'))} />

                                                <Column
                                                    className="disabled-background"
                                                    field="classes.name"
                                                    header={getLabel('class')}
                                                    body={(e) => renderRow(e?.classes?.name, getLabel('class'))} />

                                                {
                                                    d.definition.numbers.map(number => (
                                                        [
                                                            <Column
                                                                id={`${number.id}_weight`}
                                                                header={getLabel('strengthWeight.weight').replace("#VAL#", number.value)}
                                                                body={(props) => renderBreak(props, number.id, 'weight', getLabel('strengthWeight.weight').replace("#VAL#", number.value))}
                                                                editor={(props) => editBreakRow(props)}
                                                                breakData={d.definition}
                                                                number={number}
                                                                fieldType="weight"
                                                                field={`${number.id}_weight`}
                                                                cellEditValidator={breakEditorValidator} />,

                                                            <Column
                                                                id={`${number.id}_strength`}
                                                                header={getLabel('strengthWeight.strength').replace("#VAL#", number.value)}
                                                                body={(props) => renderBreak(props, number.id, 'strength', getLabel('strengthWeight.strength').replace("#VAL#", number.value))}
                                                                editor={(props) => editBreakRow(props)}
                                                                breakData={d.definition}
                                                                number={number}
                                                                field={`${number.id}_strength`}
                                                                fieldType="strength"
                                                                cellEditValidator={breakEditorValidator} />
                                                        ]
                                                    ))
                                                }
                                                <Column field="statement" header={getLabel('notes')} breakData={d.definition} body={(e) => renderRow(e?.statement, getLabel('notes'))} editor={(props) => editStatement(props)}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                data && data.length > 0 && (
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-2 p-lg-1 p-xl-1">
                                                <Button onClick={() => save()} label={getLabel('save')} className='p-button-raised' />
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                        </div>
                        <Toast ref={(el) => setToast(el)} />
                    </div >

                )
            }
        </div >
    )
};


export default withTranslation()(StrengthWeight);