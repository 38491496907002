import React, { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import Header from './common/Header';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { LocalStorage } from '../helpers/storage';
import { changeLng, changePassword, updateLimits, changeReportSettings } from '../actions/user';
import { uiCheck } from '../actions/user';
import Footer from './common/Footer';
import MyInputNumber from './common/MyInputNumber';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { logEvent } from '../helpers/amplitude';
import { getLabel } from '../helpers/translator';

const Profile = ({ history }) => {
    const { i18n } = useTranslation();
    const langs = [{ value: 'tr', name: getLabel('lng-tr') }, { value: 'en', name: getLabel('lng-en') }, { value: 'ru', name: getLabel('lng-ru') }]
    const [lang, setLang] = useState(LocalStorage.get('lang'));
    const [reportSettings, setReportSettings] = useState(LocalStorage.get('report_settings'));
    const breakDays = LocalStorage.get('breaks');
    const [currentPwd, setCurrentPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [toast, setToast] = useState({});
    const [limits, setLimits] = useState(LocalStorage.get('limits'));
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        uiCheck('profile').then(d => { if (d.success) setLoading(false) }).catch(e => { setLoading(false) })
        logEvent("[Profile] View");
    }, [])

    const changeUserPassword = () => {
        if (currentPwd === '' || newPwd === '' || confirmPwd === '') {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('profile.emptyPwd') });
            return
        }

        if (newPwd !== confirmPwd) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('profile.passwordMissMatch') });
            return
        }

        setSaving(true);
        logEvent("[Profile] Change Password");
        changePassword({ oldPassword: currentPwd, newPassword: newPwd }).then(d => {
            if (d.success) {
                LocalStorage.clear();
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('profile.passwordChanged') });
                setTimeout(() => history.push('/login'), 1000);
            }
            else {
                if (d.oldMissing)
                    toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.oldError') })
                else if (d.notStrong)
                    toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.notStrong') })

            }
        }).catch(err => {
            logEvent("[Profile] Error on Change Password");

            setSaving(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.changePwdError') })
        });

    }

    const changeLang = () => {
        logEvent("[Profile] Change Lang", { "lang": lang });
        setSaving(true);
        changeLng({ lang }).then(d => {
            setSaving(false);
            if (d.success) {
                LocalStorage.set('lang', lang);
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('profile.langChanged') });

            }
            else
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.lngError') });

        }).catch(err => {
            logEvent("[Profile] Error on Change Lang", { "lang": lang });
            setSaving(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.lngError') })
        });

    }

    const changeLimit = () => {
        logEvent("[Profile] Change Limits", { "limits": limits });
        setSaving(true);
        updateLimits(limits).then(d => {
            setSaving(false);
            if (d.success) {
                LocalStorage.set('limits', limits);
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('profile.limitSuccess') })
            } else
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.limitError') })
        }).catch(err => {
            logEvent("[Profile] Error on Change Limits", { "limits": limits });
            setSaving(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.limitError') })
        });
    }

    const changeInput = (key, e) => {
        setLimits(prevState => { return { ...prevState, [key]: e.value } });
    }

    const saveReportSettings = () => {
        logEvent("[Profile] Change Report Settings", { "reportSettings": reportSettings });
        setSaving(true);
        changeReportSettings({ report_settings: reportSettings }).then(d => {
            setSaving(false);
            if (d.success) {
                LocalStorage.set('report_settings', reportSettings);
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('profile.reportSuccess') });
            }
            else {
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.reportError') })
            }
        }).catch(e => {
            logEvent("[Profile] Error on Change Report Settings", { "reportSettings": reportSettings });
            setSaving(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('profile.reportError') })
        });
    }


    return (
        <div>
            {
                loading && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }

            {!loading && (
                <div>
                    <Header history={history} />
                    <Card title={getLabel('profile.title')} subTitle={getLabel('profile.subTitle')}>
                        <Panel header={getLabel('profile.pwdHeader')} collapsed toggleable>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <span className="p-float-label">
                                        <Password feedback={false} required={true} id="currentPwd" style={{ width: '100%' }} value={currentPwd} onChange={(e) => { setCurrentPwd(e.target.value) }} />
                                        <label htmlFor="currentPwd">{getLabel('profile.currentPwd')}</label>
                                    </span>
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <span className="p-float-label">
                                        <Password feedback={false} id="newPwd" style={{ width: '100%' }} value={newPwd} onChange={(e) => setNewPwd(e.target.value)} />
                                        <label htmlFor="newPwd">{getLabel('profile.newPwd')}</label>
                                    </span>
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <span className="p-float-label">
                                        <Password feedback={false} id="confirmPwd" style={{ width: '100%' }} value={confirmPwd} onChange={(e) => setConfirmPwd(e.target.value)} />
                                        <label htmlFor="confirmPwd">{getLabel('profile.confirmPwd')}</label>
                                    </span>
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <Button onClick={() => changeUserPassword()}
                                        label={getLabel('profile.changePwd')}
                                        className='p-button-raised' />
                                </div>
                            </div>
                        </Panel>

                        <Panel header={getLabel('profile.lngHeader')} toggleable collapsed style={{ marginTop: 15 }}>
                            <div className='p-grid'>
                                <div className="p-col-12 p-md-3">
                                    <SelectButton value={lang} options={langs} optionLabel="name" onChange={(e) => { setLang(e.value); i18n.changeLanguage(e.value); }} />
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1 p-xl-1">
                                    <Button onClick={() => changeLang()}
                                        label={saving ? getLabel('saving') : getLabel('save')} className="p-button-raised" />
                                </div>
                            </div>
                        </Panel>

                        <Panel header={getLabel('profile.limitHeader')} toggleable collapsed style={{ marginTop: 15 }}>
                            <div className='p-grid'>
                                {
                                    limits && Object.keys(limits).map(limit => (
                                        <MyInputNumber validationLimit={false} disabled={loading} customClass="p-col-12 p-md-2" htmlFor={`limit.${limit}`}
                                            id={limit} step={100} value={limits[limit]} change={changeInput} />
                                    ))
                                }

                                <div className="p-col-12 p-md-2 p-lg-1 p-xl-1">
                                    <Button onClick={() => changeLimit()}
                                        label={saving ? getLabel('saving') : getLabel('save')} className="p-button-raised" />
                                </div>
                            </div>
                        </Panel>

                        <Panel header={getLabel('profile.reportHeader')} collapsed toggleable style={{ marginTop: 15 }}>
                            <div className="p-grid p-fluid p-formgrid">
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="minmax">Chart Digit (0-2)</label>
                                    <InputNumber id="minmax" value={reportSettings?.chart?.digit}
                                        min={0} max={2} onValueChange={(e) => setReportSettings(prevState => {
                                            prevState.chart.digit = e.value;
                                            return { ...prevState }
                                        })} />
                                </div>

                                <div className="p-field-checkbox p-col-6 p-md-2" style={{ marginTop: 29 }}>
                                    <Checkbox inputId='showData' checked={reportSettings.chart.showData}
                                        onChange={e => setReportSettings(prevState => {
                                            prevState.chart.showData = e.checked;
                                            return { ...prevState }
                                        })} />
                                    <label htmlFor={'showData'}>
                                        {
                                            getLabel('profile.showData')
                                        }
                                    </label>
                                </div>

                                <div className="p-field p-col-12 p-md-2 p-lg-1 p-xl-1" style={{ marginTop: 29 }}>
                                    <Button onClick={() => saveReportSettings()}
                                        label={saving ? getLabel('saving') : getLabel('save')} className="p-button-raised" />
                                </div>
                            </div>

                            <Card subTitle="FCK">
                                <div className="p-grid">
                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='report.fck' checked={reportSettings.chart.fields['report.fck']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['report.fck'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'report.fck'}>
                                            {
                                                getLabel('report.fck')
                                            }
                                        </label>
                                    </div>

                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='report.fck+' checked={reportSettings.chart.fields['report.fck+']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['report.fck+'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'report.fck+'}>
                                            {
                                                getLabel('report.fck+')
                                            }
                                        </label>
                                    </div>

                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='report.fckMin' checked={reportSettings.chart.fields['report.fckMin']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['report.fckMin'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'report.fckMin'}>
                                            {
                                                getLabel('report.fckMin')
                                            }
                                        </label>
                                    </div>

                                </div>
                            </Card>

                            <Card subTitle={getLabel('newSample.otherInfo')} style={{ marginTop: 15 }}>
                                <div className="p-grid">
                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='slump' checked={reportSettings.chart.fields['slump']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['slump'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'slump'}>
                                            {
                                                getLabel('slump')
                                            }
                                        </label>
                                    </div>

                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='flow_diameter' checked={reportSettings.chart.fields['flow_diameter']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['flow_diameter'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'flow_diameter'}>
                                            {
                                                getLabel('flow_diameter')
                                            }
                                        </label>
                                    </div>

                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='cement' checked={reportSettings.chart.fields['cement']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['cement'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'cement'}>
                                            {
                                                getLabel('cement')
                                            }
                                        </label>
                                    </div>

                                    <div className="p-field-checkbox p-col-6 p-md-2">
                                        <Checkbox inputId='mineral_additives' checked={reportSettings.chart.fields['mineral_additives']}
                                            onChange={e => setReportSettings(prevState => {
                                                prevState.chart.fields['mineral_additives'] = e.checked;
                                                return { ...prevState }
                                            })} />
                                        <label htmlFor={'mineral_additives'}>
                                            {
                                                getLabel('mineral_additives')
                                            }
                                        </label>
                                    </div>


                                </div>
                            </Card>

                            <Card subTitle={getLabel('profile.breakDays')} style={{ marginTop: 15 }}>
                                <div className="p-grid">
                                    {
                                        breakDays && breakDays.filter(d => d.value !== 0 && d.class_id === null).map(d => (
                                            <div className="p-field-checkbox p-col-6 p-md-2">
                                                <Checkbox inputId={d.value} checked={reportSettings.chart.fields[`day_${d.value}_${d.ultimate}`]}
                                                    onChange={e => setReportSettings(prevState => {
                                                        prevState.chart.fields[`day_${d.value}_${d.ultimate}`] = e.checked;
                                                        return { ...prevState }
                                                    })} />
                                                <label htmlFor={d.value}>
                                                    {
                                                        getLabel('report.chart_day').replace("#DAY#", d.value).replace("#ULT#", d.ultimate)
                                                    }
                                                </label>
                                            </div>
                                        ))
                                    }

                                </div>

                                <div className="p-grid">
                                    {
                                        breakDays && breakDays.filter(d => d.value !== 0 && d.class_id !== null).map(d => (
                                            <div className="p-field-checkbox p-col-6 p-md-2">
                                                <Checkbox inputId={d.value} checked={reportSettings.chart.fields[`day_${d.value}_${d.ultimate}`]}
                                                    onChange={e => setReportSettings(prevState => {
                                                        prevState.chart.fields[`day_${d.value}_${d.ultimate}`] = e.checked;
                                                        return { ...prevState }
                                                    })} />
                                                <label htmlFor={d.value}>
                                                    {
                                                        getLabel('report.chart_day').replace("#DAY#", d.value).replace("#ULT#", d.ultimate)
                                                    }
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>

                            </Card>

                            <Card subTitle={getLabel('profile.partials')} style={{ marginTop: 15 }}>
                                <div className="p-grid">
                                    {
                                        breakDays && breakDays.filter(d => d.class_id === null && d.value !== d.ultimate).map(d => (
                                            <div className="p-field-checkbox p-col-6 p-md-2">
                                                <Checkbox inputId={d.value} checked={reportSettings.chart.fields[`${d.value}_${d.ultimate}_partial`]}
                                                    onChange={e => setReportSettings(prevState => {
                                                        prevState.chart.fields[`${d.value}_${d.ultimate}_partial`] = e.checked;
                                                        return { ...prevState }
                                                    })} />
                                                <label htmlFor={d.value}>
                                                    {
                                                        getLabel('report.prediction_partial').replace("DAY", d.value).replace("ULT", d.ultimate)
                                                    }
                                                </label>
                                            </div>
                                        ))
                                    }

                                </div>

                                <div className="p-grid">
                                    {
                                        breakDays && breakDays.filter(d => d.value !== d.ultimate && d.class_id !== null).map(d => (
                                            <div className="p-field-checkbox p-col-6 p-md-2">
                                                <Checkbox inputId={d.value} checked={reportSettings.chart.fields[`${d.value}_${d.ultimate}_partial`]}
                                                    onChange={e => setReportSettings(prevState => {
                                                        prevState.chart.fields[`${d.value}_${d.ultimate}_partial`] = e.checked;
                                                        return { ...prevState }
                                                    })} />
                                                <label htmlFor={d.value}>
                                                    {
                                                        getLabel('report.prediction_partial').replace("DAY", d.value).replace("ULT", d.ultimate)
                                                    }
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>

                            </Card>

                        </Panel>

                    </Card>
                    <Footer />
                    <Toast ref={(el) => setToast(el)} />

                </div>
            )
            }
        </div>
    )
};

export default withTranslation()(Profile);