const { hostname } = window.location;
export const getEnv = () =>
  hostname === 'localhost' || hostname.indexOf('test') === 0 ? 'test' : 'prod';

export const isTestEnv = () => getEnv() === 'test';

let api_url = 'https://mxf1woq8vb.execute-api.eu-central-1.amazonaws.com/production';
let ml_url = 'https://api.optimixmono.com';

const config = {
  baseUrl: isTestEnv() ? 'http://localhost:5000' : api_url,
  mlUrl: isTestEnv() ? 'http://localhost:5001' : ml_url,
  imageUrl: '',
};

export const resolveFromBaseURL = path => config.baseUrl + path;

export default config;
