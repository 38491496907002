import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { ProgressBar } from 'primereact/progressbar';
import { Chart } from 'primereact/chart';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { listItems } from '../../actions/list';
import { costReport } from '../../actions/cost';
import { getLabel, getLang, currencySymbol } from '../../helpers/translator'
import { logEvent } from '../../helpers/amplitude';
import { LocalStorage } from '../../helpers/storage';
import MyLoader from '../common/MyLoader';


const CostReport = ({ history }) => {
    const colors = ['#009900', '#FF0000', '#0070C0', '#D1CC00', '#AEAAAA', '#D2A000', '#5B9BD5', '#92D050', '#C65911',
        '#FFE699', '#9734A4', '#C0BC00', '#F8CBAD', '#808080', '#8EA9DB', '#00B050', '#FFFF89', '#833C0C'];
    const staticFields = ['cement', 'mineral', 'aggregate', 'additive'];
    const [toast, setToast] = useState({});
    const [state, _setStates] = useState({
        loading: false,
        data: [],
        plants: [],
        classes: [],
        classDesigns: [],
        minerals: [],
        price_id: 0,
        prices: [{ label: getLabel('cost.realPrice'), value: "REAL" }, { label: getLabel('cost.currentPrice'), value: "CURRENT" }],
        chartData: null,
        summary: {},
        startDate: moment(new Date()).subtract(2, 'month').toDate(),
        endDate: new Date()
    });

    useEffect(() => {
        logEvent("[Cost Report] View");

        listItems('plant').then(d => {
            let plants = [];
            if (d.success) {
                d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
            }
            setState({ plants });
        });

        listItems('class').then(d => {
            let classes = [];
            if (d.success) {
                d.data.forEach(i => classes.push({ label: i.name, value: i.id }));
            }
            setState({ classes });
        });

        listItems('class_design').then(d => {
            const classDesigns = [];
            if (d.success) {
                d.data.forEach(i => classDesigns.push(i));
            }
            setState({ classDesigns });
        });

        listItems('mineral').then(d => {
            let minerals = [];
            if (d.success) {
                d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
                setTimeout(() => {
                    minerals.push({ label: getLabel('noMineral'), value: 0 });
                    setState({ minerals });
                }, 800);
            }
        });

    }, []);

    const setState = (props) => {
        logEvent("[Cost Report] Change Params", { "props": props });
        _setStates(prevState => { return { ...prevState, ...props } });
    }

    const validateFilter = () => {
        if (!state.plant_id || !state.class_id || !state.class_design_id || state.class_design_id.length === 0
            || !state.mineral_id || state.mineral_id.length === 0 || !state.price_id || !state.startDate || !state.endDate)
            return false;
        else
            return true;
    }

    const validateResponse = (data) => {
        return !data.empty;
    }

    const getChartLabel = (key, dataDefinition) => {
        const barStaticFields = ['cement', 'mineral', 'aggregate', 'additive', 'cost_m3', 'material_m3'];

        if (barStaticFields.includes(key)) {
            return getLabel(key);
        }
        else if (key.startsWith(`day_`)) {
            return getLabel('report.day').replace("#DAY#", dataDefinition.ultimate);
        }
        else if (key.startsWith("prediction_")) {
            const keyArr = key.split("_");
            return getLabel('report.prediction_partial').replace("DAY", keyArr[1]).replace("ULT", dataDefinition.ultimate);
        }
        else {
            return dataDefinition[key][`label_${getLang()}`]
        }

    }

    const generateBarDataSet = (key, index, data, dataDefinition) => {
        const dataset = {
            hidden: false,
            stack: 'cost',
            label: getChartLabel(key, dataDefinition),
            data: data,
            backgroundColor: index <= colors.length ? colors[index] : colors[0],
            borderColor: index <= colors.length ? colors[index] : colors[0],
            fill: true,
            type: 'line',
            yAxisID: "bar"
        }
        return dataset;
    }

    const generateTotalCost = (key, data, dataDefinition) => {
        const dataset = {
            stack: 'cost',
            totalCost: true,
            label: getChartLabel(key, dataDefinition),
            data: data,
            backgroundColor: '#B4C6E7',
            borderColor: '#B4C6E7',
            fill: true,
            type: 'line',
            yAxisID: "totals",
            lineTension: 0,
            pointHoverRadius: 5,
            pointRadius: 3,
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                rotation: 270
            }
        }
        return dataset;
    }

    const generateMaterialCost = (key, data, dataDefinition) => {
        const dataset = {
            stack: 'cost',
            totalCost: true,
            label: getChartLabel(key, dataDefinition),
            data: data,
            backgroundColor: '#2e4a7d',
            borderColor: '#2e4a7d',
            fill: true,
            type: 'line',
            yAxisID: "totals",
            lineTension: 0,
            pointHoverRadius: 5,
            pointRadius: 3,
            datalabels: {
                display: false,
                color: 'black',
                anchor: 'end',
                align: 'top',
                rotation: 270
            }
        }
        return dataset;
    }

    const generateLineDataSet = (key, index, data, dataDefinition) => {
        const dataset = {
            strength: true,
            pointRadius: 3,
            label: getChartLabel(key, dataDefinition),
            data: data,
            borderColor: index <= colors.length ? colors[index] : colors[0],
            backgroundColor: index <= colors.length ? colors[index] : colors[0],
            yAxisID: "line",
            type: 'line',
            lineTension: 0,
            pointHoverRadius: 5,
            datalabels: {
                display: false
            }
        }

        return dataset;
    }

    const getCost = () => {
        if (!validateFilter()) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('cost.materialFilter') })
            return;
        }

        const request = {
            startDate: moment(state.startDate).format('YYYY-MM-DD'),
            endDate: moment(state.endDate).format('YYYY-MM-DD'),
            class_id: state.class_id,
            plant_id: state.plant_id,
            minerals: state.mineral_id,
            design_id: state.class_design_id,
            price: state.price_id
        }

        logEvent("[Cost Report] Get Cost", { "params": request });

        setState({ loading: true, chartData: null, summary: null });

        costReport(request).then(d => {

            if (d.success) {
                if (validateResponse(d)) {

                    let datasets = [];
                    let index = 0;

                    Object.keys(d?.chart?.data_sets).forEach((key) => {

                        if (key.startsWith(`day_${d.data_definition.ultimate}`)) {
                            datasets.push(generateLineDataSet(key, index, d.chart.data_sets[key], d.data_definition));
                            index++;
                        }
                        else if (key.startsWith('day_')) {
                            return;
                        }
                        else if (key.startsWith('prediction_')) {
                            datasets.push(generateLineDataSet(key, index, d.chart.data_sets[key], d.data_definition));
                            index++;
                        }

                    });

                    Object.keys(d?.chart?.data_sets).filter(f => staticFields.includes(f)).forEach((key) => {
                        if (!key.startsWith(`day_${d.data_definition.ultimate}`) &&
                            !key.startsWith('day_') && !key.startsWith('prediction_')) {
                            datasets.push(generateBarDataSet(key, index, d.chart.data_sets[key], d.data_definition));
                            index++;
                        }
                    });

                    Object.keys(d?.chart?.data_sets).filter(f => !staticFields.includes(f)).forEach((key) => {
                        if (!key.startsWith(`day_${d.data_definition.ultimate}`) &&
                            !key.startsWith('day_') && !key.startsWith('prediction_')) {
                            if (key === 'cost_m3')
                                datasets.push(generateTotalCost(key, d.chart.data_sets[key], d.data_definition));
                            else if (key === 'material_m3')
                                datasets.push(generateMaterialCost(key, d.chart.data_sets[key], d.data_definition));
                            else
                                datasets.push(generateBarDataSet(key, index, d.chart.data_sets[key], d.data_definition));
                            index++;
                        }
                    });

                    let _chartData = {
                        labels: d?.chart?.labels,
                        datasets: datasets
                    }

                    setState({ chartData: _chartData, loading: false, summary: d.summary });
                }
                else {
                    setState({ chartData: null, loading: false });
                    toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('cost.noData') })
                }
            }
            else {
                setState({ chartData: null, loading: false });
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('cost.costReportError') })
            }
        }).catch(_ => {
            logEvent("[Cost Report] Error on Get Cost", { "params": request });

            setState({ chartData: null, loading: false });
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('cost.costReportError') })
        });
    }

    const { loading, classDesigns, summary } = state;
    const classDesign = classDesigns.filter(f => f.class_id === state.class_id).map(i => { return { label: i.name, value: i.id } });

    return (
        <div>
            {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px' }} />)}
            <Panel header={getLabel('filters')} toggleable>

                <div className="p-grid">

                    <div className="p-col-12 p-md-2">

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <Calendar id='start_date' disabled={loading} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={state.startDate} onChange={(e) => setState({ startDate: e.value, chartData: null, summary: null })}></Calendar>
                                <label htmlFor="start_date">{getLabel('startDate')}</label>
                            </span>
                        </div>

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <Calendar id='end_date' disabled={loading} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={state.endDate} onChange={(e) => setState({ endDate: e.value, chartData: null, summary: null })}></Calendar>
                                <label htmlFor="end_date">{getLabel('endDate')}</label>
                            </span>
                        </div>
                    </div>

                    <div className="p-col-12 p-md-5">

                        <div className="p-col-12 p-md-11">
                            <span className="p-float-label">
                                <Dropdown id="plant" disabled={loading} showClear style={{ width: '100%' }} value={state.plant_id} options={state.plants} onChange={(e) => setState({ plant_id: e.value, chartData: null, summary: null })} />
                                <label htmlFor="plant">{getLabel('plant')}</label>
                            </span>
                        </div>

                        <div className="p-col-12" style={{ paddingTop: 0 }}>
                            <div className="select-label" htmlFor="class">{getLabel('class')}</div>
                            <SelectButton className="font-10" id="class" disabled={loading} value={state.class_id} options={state.classes} onChange={(e) => setState({ class_id: e.value, chartData: null, summary: null, class_design_id: null })}></SelectButton>
                        </div>

                    </div>

                    <div className="p-col-12 p-md-3">

                        <div className="p-col-12">
                            <span className="p-float-label">
                                <MultiSelect
                                    id="class_design"
                                    disabled={loading}
                                    style={{ width: "100%" }} value={state.class_design_id}
                                    options={classDesign} onChange={(e) => setState({ class_design_id: e.value, chartData: null, summary: null })} filter
                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                    selectedItemsLabel={getLabel('cost.designSelected')}
                                />
                                <label htmlFor="class_design">{getLabel('class_design')}</label>
                            </span>
                        </div>


                        <div className="p-col-12" style={{ paddingTop: 0 }}>
                            <div className="select-label" htmlFor="mineral">{getLabel('mineral')}</div>
                            <SelectButton multiple={true} className="font-10" id="minerals" disabled={loading} value={state.mineral_id} options={state.minerals} onChange={(e) => setState({ mineral_id: e.value, chartData: null, summary: null })}></SelectButton>
                        </div>

                        <div className="p-col-12" style={{ paddingTop: 0 }}>
                            <div className="select-label" htmlFor="priceCaption">{getLabel('cost.priceCaption')}</div>
                            <SelectButton className="font-10" id="priceCaption" disabled={loading} value={state.price_id} options={state.prices} onChange={(e) => setState({ price_id: e.value, chartData: null, summary: null })}></SelectButton>
                        </div>


                    </div>

                    <div className="p-col-12 p-md-2">
                        <div className="p-col-12">
                            <Button disabled={loading} style={{ width: "100%" }} label={getLabel('search')} onClick={() => getCost()} className='p-button-raised p-button-success no-print' icon="pi pi-search" />
                        </div>
                        <div className="p-col-12">
                            <Button
                                disabled={loading} style={{ width: "100%" }} label={getLabel('reporting')}
                                className='p-button-raised p-button-secondary no-print' icon="pi pi-print"
                                onClick={() => {
                                    logEvent("[Cost Report] Print");
                                    window.print();
                                }} />
                        </div>
                    </div>

                </div>
            </Panel>

            <div className="p-grid">
                <div className="p-col-12">
                    {
                        state.chartData && (
                            <Chart key={Math.random()} redraw type="bar" data={state.chartData} options={{
                                responsive: true,
                                scales: {
                                    x:
                                    {
                                        type: "category",
                                        id: "sampleNo",
                                        stacked: true,
                                        ticks: {
                                            callback: function (label) {
                                                let realLabel = this.getLabelForValue(label);
                                                var sampleNo = realLabel.split(";")[0];
                                                return sampleNo;
                                            }
                                        },
                                        beginAtZero: true,
                                        stepSize: 1
                                    },

                                    xAxis2: {
                                        type: "category",
                                        id: "date",
                                        grid: {
                                            drawOnChartArea: false,
                                        },
                                        ticks: {
                                            callback: function (label) {
                                                let realLabel = this.getLabelForValue(label);
                                                var date = realLabel.split(";")[1];
                                                return date;
                                            },
                                        },
                                        beginAtZero: true,
                                        stepSize: 1
                                    },
                                    line:
                                    {
                                        display: true,
                                        position: 'left',
                                        type: "linear",
                                        id: "line",
                                        beginAtZero: true,
                                        min: 0,
                                        stepSize: 4
                                    },
                                    bar: {
                                        stacked: true,
                                        display: true,
                                        type: "linear",
                                        position: "right",
                                        id: "bar",
                                        beginAtZero: true,
                                        max: LocalStorage.get('cost_report_max')
                                    },
                                    totals: {
                                        display: false,
                                        type: "linear",
                                        position: "right",
                                        grid: {
                                            drawOnChartArea: false,
                                        },
                                        id: "totals",
                                        beginAtZero: true,
                                        max: LocalStorage.get('cost_report_max')
                                    }

                                },
                                plugins: {
                                    tooltip: {
                                        enabled: true,
                                        intersect: false,
                                        mode: 'index',
                                        yAlign: 'bottom',
                                        callbacks: {
                                            title: function (tooltipItem, data) {

                                                if (tooltipItem?.length > 0) {
                                                    const titles = tooltipItem[0]?.label.split(";");
                                                    let rowTitles = [];
                                                    rowTitles.push(`Sample No: ${titles[0]}`);
                                                    rowTitles.push(`${getLabel('date')}: ${titles[1]}`);
                                                    if (tooltipItem[0]?.raw?.z)
                                                        rowTitles.push(`${getLabel('notes')} : ${tooltipItem[0]?.raw?.z || ""}`);
                                                    rowTitles.push(`${getLabel('class_design')} : ${tooltipItem[0].raw?.q || ""}`);

                                                    return rowTitles
                                                }
                                            },
                                            label: function (tooltipItem) {
                                                const dataset = tooltipItem.dataset;
                                                const value = tooltipItem?.raw?.y;
                                                if (!dataset)
                                                    return;
                                                else if (dataset.strength) {
                                                    return `${dataset.label} : ${value ? parseFloat(value).toFixed(1) : ''} MPa`;
                                                }
                                                else
                                                    return `${dataset.label} : ${value ? parseFloat(value).toFixed(1) : ''} ${currencySymbol()}`;
                                            }
                                        }
                                    },
                                    elements: {
                                        line: {
                                            fill: false
                                        },
                                        point: {
                                            radius: 0
                                        }
                                    },
                                    title: {
                                        display: true,
                                        position: 'top',
                                        text: getLabel('cost.chartTitle'),
                                        fontSize: 18
                                    },
                                    datalabels: {
                                        display: false,
                                        align: 'center',
                                        anchor: 'top',
                                        offset: 0,
                                        color: 'white',
                                        formatter: function (value, context) {
                                            if (value.constructor === Object)
                                                return value.y?.toFixed(1);
                                            else
                                                return value;
                                        },
                                        font: {
                                            weight: 'bold',
                                            size: 10
                                        },
                                        //     listeners: {
                                        //         enter: function (context) {
                                        //             context.hovered = true;
                                        //             return true;
                                        //         },
                                        //         leave: function (context) {
                                        //             context.hovered = false;
                                        //             return true;
                                        //         }
                                        //     }
                                    }
                                }
                            }} />
                        )
                    }

                </div>

                {
                    state.summary?.avg_cement && (
                        <div className="p-col-12">
                            <table border="1" className="summary-table">
                                <tbody>
                                    <tr className="font-w-600">
                                        <td colSpan="5" className="border-bottom">{getLabel('report.summaryTable')}</td>
                                    </tr>

                                    <tr className="font-w-400">
                                        <td key="summary-label-cement">{getLabel('cement')}(kg/m³)</td>
                                        <td key="summary-label-mineral">{getLabel('mineral')}(kg/m³)</td>
                                        <td key="summary-label-ultimate">{getLabel('cost.strength')}</td>
                                        <td key="summary-label-cost">{LocalStorage.get('currency')}/{getLabel('cost.costPrice')}</td>
                                        <td key="summary-label-mpa">{LocalStorage.get('currency')}/{getLabel('cost.mpaCost')}</td>
                                    </tr>
                                    <tr>
                                        <td key="sum-avg-cement">{summary?.avg_cement}</td>
                                        <td key="sum-avg-mineral">{summary?.avg_mineral}</td>
                                        <td key="sum-avg-ultimate">{summary?.avg_ultimate_st}</td>
                                        <td key="sum-avg-cost">{summary?.avg_cost}</td>
                                        <td key="sum-avg-mpa">{summary?.cost_mpa}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    )
                }

            </div>
            {loading && (<MyLoader />)}

            <Toast ref={(el) => setToast(el)} />

        </div>
    )
};

export default withTranslation()(CostReport);