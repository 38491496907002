import { InputTextarea } from 'primereact/inputtextarea';

const MyInputTextArea = ({ disabled, rows, cols, customClass, value, id, checkChanges, change, autoResize }) => {

    const _className = checkChanges ? 'input-changed' : '';

    return (
        <div className={customClass}>
            <InputTextarea
                rows={rows} cols={cols} autoResize={autoResize} disabled={disabled}
                id={id} className={_className}
                value={value} style={{ width: '100%' }}
                onChange={(e) => change(id, e)} />
        </div>
    )
};

export default MyInputTextArea;