import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Header from './common/Header';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { uiCheck } from '../actions/user';
import { getReports } from '../actions/report';
import { getLabel, getLang } from '../helpers/translator';
import { listItems } from '../actions/list';
import Footer from './common/Footer';
import { LocalStorage } from '../helpers/storage';
import { logEvent } from '../helpers/amplitude';
import MyLoader from './common/MyLoader';



const Report = ({ history }) => {
    const [inputs, setInputs] = useState({
        'startDate': moment(new Date()).subtract(2, 'month').toDate(),
        'endDate': new Date()
    });
    const [auth, setAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoad, setPageLoad] = useState(true);
    const [toast, setToast] = useState({});
    const [dataDefinition, setDataDefinition] = useState({});
    const [dataTable, setDataTable] = useState();
    const [plants, setPlants] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [minerals, setMinerals] = useState([]);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState({});
    const [chartData, setChartData] = useState({});
    const [tempChartData, setTempChartData] = useState({});
    const [concreteChartData, setConcreteChartData] = useState({});
    const colors = ['#002060', '#FF0000', '#00B050', '#000'];
    const additionColors = ['#FFC000', '#002060', '#FF0000', '#00B050'];
    const [reportSettings] = useState(LocalStorage.get('report_settings'));


    useEffect(() => {
        uiCheck('report').then(d => { setAuth(d.auth); if (d.success) setPageLoad(false) }).catch(e => { setAuth(false); setPageLoad(false) });
        logEvent("[Report] View");
        listItems('plant').then(d => {
            let _plants = [];
            if (d.success) {
                d.data.forEach(i => _plants.push({ label: i.name, value: i.id }));
            }
            setPlants(_plants);

        });

        listItems('class').then(d => {
            let _classes = [];
            if (d.success) {
                d.data.forEach(i => _classes.push({ label: i.name, value: i.id }));
            }
            setClasses(_classes);
        });

        listItems('sample_type').then(d => {
            let _sample_types = [];
            if (d.success) {
                d.data.forEach(i => _sample_types.push({ label: i.name, value: i.id }));
            }
            setSampleTypes(_sample_types);
        });

        listItems('mineral').then(d => {
            let _minerals = [];
            if (d.success) {
                d.data.forEach(i => _minerals.push({ label: i.name, value: i.id }));
                setTimeout(() => {
                    _minerals.push({ label: getLabel('noMineral'), value: 0 });
                    setMinerals(_minerals);
                }, 800);
            }

        });

    }, []);

    const changeInput = (key, e) => {
        logEvent("[Report] Filter Change", { 'filter': key, 'value': e.value });
        setInputs(prevState => { return { ...prevState, [key]: e.value } });
    }

    const getReport = () => {
        if (inputs.startDate === null || inputs.endDate === null ||
            inputs?.minerals?.length === 0 || inputs.minerals === undefined ||
            inputs.sample_type_id === null || inputs.sample_type_id === undefined ||
            inputs.class_id === null || inputs.class_id === undefined ||
            inputs.plant_id === null || inputs.plant_id === undefined) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('report.reqField') });
            logEvent("[Report] Required Filter Check");
            return;
        }

        logEvent("[Report] Get Report", {
            'reportParams': {
                startDate: moment(inputs.startDate).format("YYYY-MM-DD"),
                endDate: moment(inputs.endDate).format("YYYY-MM-DD"),
                plant_id: inputs.plant_id,
                class_id: inputs.class_id,
                sample_type_id: inputs.sample_type_id,
                minerals: inputs.minerals
            }
        });

        setLoading(true);
        getReports({
            startDate: moment(inputs.startDate).format("YYYY-MM-DD"),
            endDate: moment(inputs.endDate).format("YYYY-MM-DD"),
            plant_id: inputs.plant_id,
            class_id: inputs.class_id,
            sample_type_id: inputs.sample_type_id,
            minerals: inputs.minerals
        }).then(d => {
            setLoading(false);

            if (d.validation?.fck_insufficient) {
                toast.show({ life: 7000, severity: 'warn', summary: getLabel('warning'), detail: getLabel('report.fck_show') });
            }

            if (d.not_valid) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('report.reqField') });
                return;
            }

            if (d.empty) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('report.empty') });
                return;
            }

            if (d.fck) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('report.empty') });
                return;
            }

            if (d.success) {
                setData(d.data);
                setDataDefinition(d.data_definition);
                setSummary(d.summary);
                const fckLegends = [
                    {
                        label: getLabel('report.fck+'),
                        hidden: !reportSettings.chart.fields['report.fck+'],
                        data: d?.chart?.data_sets?.fckPlus,
                        borderWidth: 1,
                        pointRadius: 0,
                        pointHoverRadius: 5,
                        borderColor: '#C60ACA',
                        backgroundColor: '#C60ACA',
                        yAxisID: "line",
                        type: 'line',
                        lineTension: 0,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        borderWidth: 1,
                        hidden: !reportSettings.chart.fields['report.fck'],
                        pointRadius: 0,
                        label: getLabel('report.fck'),
                        data: d?.chart?.data_sets?.fck,
                        borderColor: '#03d7fc',
                        backgroundColor: '#03d7fc',
                        yAxisID: "line",
                        type: 'line',
                        lineTension: 0,
                        pointHoverRadius: 5,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        borderWidth: 1,
                        hidden: !reportSettings.chart.fields['report.fckMin'],
                        pointRadius: 0,
                        label: getLabel('report.fckMin'),
                        data: d?.chart?.data_sets?.fckMin,
                        borderColor: '#848484',
                        backgroundColor: '#848484',
                        yAxisID: "line",
                        type: 'line',
                        lineTension: 0,
                        pointHoverRadius: 5,
                        datalabels: {
                            display: false
                        }
                    }
                ];

                const otherLegends = [{
                    stack: 'Stack 0',
                    hidden: !reportSettings.chart.fields['flow_diameter'],
                    label: getLabel('flow_diameter'),
                    data: d?.chart?.data_sets?.flow_diameter,
                    backgroundColor: '#404040',
                    borderColor: 'white',
                    fill: false,
                    type: 'bar',
                    yAxisID: "bar",
                    datalabels: {
                        display: reportSettings.chart.showData,
                        rotation: 270,
                    }
                },
                {
                    stack: 'Stack 0',
                    hidden: !reportSettings.chart.fields['slump'],
                    label: getLabel('slump'),
                    data: d?.chart?.data_sets?.slump.slice(0, 100),
                    backgroundColor: '#2F5597',
                    fill: false,
                    type: 'bar',
                    yAxisID: "bar",
                    datalabels: {
                        display: reportSettings.chart.showData,
                        rotation: 270
                    }
                },
                {
                    stack: 'Stack 1',
                    hidden: !reportSettings.chart.fields['cement'],
                    label: getLabel('cement'),
                    data: d?.chart?.data_sets?.cement,
                    backgroundColor: '#DAE3F3',
                    fill: true,
                    stacked: true,
                    type: 'bar',
                    yAxisID: "bar",
                    datalabels: {
                        display: reportSettings.chart.showData,
                        color: 'black',
                        rotation: 270,
                    }
                },
                {
                    stack: 'Stack 1',
                    hidden: !reportSettings.chart.fields['mineral_additives'],
                    label: getLabel('mineral_additives'),
                    data: d?.chart?.data_sets?.mineral,
                    backgroundColor: '#c7c5c5',
                    fill: true,
                    stacked: true,
                    type: 'bar',
                    yAxisID: "bar",
                    datalabels: {
                        display: reportSettings.chart.showData,
                        rotation: 270,
                        color: 'black'
                    }
                }];

                let breakLegends = [];
                d.data_definition.break_days.forEach((day, index) => {
                    breakLegends.push({
                        pointRadius: reportSettings.chart.showData ? 1 : 3,
                        hidden: !reportSettings.chart.fields[`day_${day}_${d.data_definition.ultimate}`],
                        label: getLabel('report.day').replace("#DAY#", day),
                        data: d?.chart?.data_sets[`day_${day}_strength`],
                        borderColor: colors[index],
                        backgroundColor: colors[index],
                        yAxisID: "line",
                        type: 'line',
                        lineTension: 0,
                        pointHoverRadius: 5,
                        datalabels: {
                            display: reportSettings.chart.showData,
                            color: 'black',
                            anchor: 'end',
                            align: 'top'
                        }
                    })
                });

                // let predictLegends = [];

                // d.data_definition.prediction_days.forEach((day, index) => {
                //     predictLegends.push({
                //         pointRadius: 1,
                //         borderDash: [5, 5],
                //         hidden: true,
                //         label: getLabel('report.prediction').replace("#DAY#", day).replace("#ULTIMATE#", d.data_definition.ultimate),
                //         data: d?.chart?.data_sets[`prediction_${day}_${d.data_definition.ultimate}`],
                //         borderColor: colors[index],
                //         backgroundColor: colors[index],
                //         yAxisID: "line",
                //         type: 'line',
                //         pointHoverRadius: 5,
                //         lineTension: 0,
                //         datalabels: {
                //             anchor: 'top',
                //             align: 'top'
                //         }
                //     })
                // });

                let predictPartialLegends = [];

                d.data_definition.prediction_days.forEach((day, index) => {
                    predictPartialLegends.push({
                        pointRadius: reportSettings.chart.showData ? 0 : 3,
                        hidden: !reportSettings.chart.fields[`${day}_${d.data_definition.ultimate}_partial`],
                        label: getLabel('report.prediction_partial').replace("DAY", day).replace("ULT", d.data_definition.ultimate),
                        data: d?.chart?.data_sets[`prediction_${day}_${d.data_definition.ultimate}_partial`],
                        borderColor: additionColors[index],
                        backgroundColor: additionColors[index],
                        yAxisID: "line",
                        type: 'line',
                        borderDash: [5, 5],
                        pointHoverRadius: 5,
                        lineTension: 0,
                        datalabels: {
                            display: reportSettings.chart.showData,
                            color: 'black',
                            anchor: 'end',
                            align: 'top'
                        }
                    })
                });


                let _chartData = {
                    labels: d?.chart?.labels,
                    datasets: [...fckLegends, ...breakLegends, ...otherLegends, ...predictPartialLegends]
                }

                setChartData(_chartData);

                let _tempChartData = {
                    labels: d?.temp?.labels,
                    datasets: [
                        {
                            label: getLabel('temp'),
                            backgroundColor: '#42A5F5',
                            borderColor: '#42A5F5',
                            data: d?.temp?.weather_temp,
                            borderWidth: 1,
                            pointRadius: 2,
                            pointHoverRadius: 5,
                            lineTension: 0,
                            type: 'line',
                            fill: false,
                            datalabels: {
                                align: 'bottom'
                            }
                        },
                        {
                            label: getLabel('concrete_temp'),
                            backgroundColor: '#FFA726',
                            borderColor: '#FFA726',
                            data: d?.temp?.concrete_temp,
                            borderWidth: 1,
                            pointRadius: 2,
                            pointHoverRadius: 5,
                            lineTension: 0,
                            type: 'line',
                            fill: false
                        }
                    ]
                }

                setTempChartData(_tempChartData);

                let _concreteChartData = {
                    labels: d?.fresh_concrete_density?.labels,
                    datasets: [
                        {
                            label: getLabel('fresh_concrete_density'),
                            backgroundColor: '#A9D18E',
                            data: d?.fresh_concrete_density?.fresh_concrete_density,
                            datalabels: {
                                display: false
                            },
                            canvas: {
                                height: 128
                            }
                        }
                    ]
                }

                setConcreteChartData(_concreteChartData);
            }



        }).catch(err => {
            logEvent("[Report] Get Report Error");
            setLoading(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('report.fetchError') });
        });
    }

    const exportTable = () => {
        logEvent("[Report] Export Table");
        dataTable.exportCSV();
    }

    return (
        <div>
            {pageLoad && (
                <ProgressSpinner style={{ left: "50%" }} />
            )}
            {!pageLoad && (
                <>
                    {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}

                    <Header history={history} />

                    <Card title={getLabel('report.title')} subTitle={getLabel('report.subTitle')}>

                        {!auth && (
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Message severity="error" text={getLabel('permission')}></Message>
                                </div>
                            </div>
                        )}
                        {auth && (
                            <div>
                                <Panel header={getLabel('filters')} toggleable>
                                    <div className="p-grid">

                                        <div className="p-col-12 p-md-2">

                                            <div className="p-col-12">
                                                <span className="p-float-label">
                                                    <Calendar id='start_date' disabled={loading} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={inputs.startDate} onChange={(e) => changeInput('startDate', e)}></Calendar>
                                                    <label htmlFor="start_date">{getLabel('startDate')}</label>
                                                </span>
                                            </div>

                                            <div className="p-col-12">
                                                <span className="p-float-label">
                                                    <Calendar id='end_date' disabled={loading} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={inputs.endDate} onChange={(e) => changeInput('endDate', e)}></Calendar>
                                                    <label htmlFor="end_date">{getLabel('endDate')}</label>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-md-5">

                                            <div className="p-col-12 p-md-10">
                                                <span className="p-float-label">
                                                    <Dropdown id="plant" disabled={loading} showClear style={{ width: '100%' }} value={inputs.plant_id} options={plants} onChange={(e) => changeInput('plant_id', e)} />
                                                    <label htmlFor="plant">{getLabel('plant')}</label>
                                                </span>
                                            </div>

                                            <div className="p-col-12" style={{ paddingTop: 0 }}>
                                                <div className="select-label" htmlFor="class">{getLabel('class')}</div>
                                                <SelectButton className="font-10" id="class" disabled={loading} value={inputs.class_id} options={classes} onChange={(e) => changeInput('class_id', e)}></SelectButton>
                                            </div>

                                        </div>

                                        <div className="p-col-12 p-md-3">

                                            <div className="p-col-12" style={{ paddingTop: 0 }}>
                                                <div className="select-label" htmlFor="sample_type">{getLabel('sample_type')}</div>
                                                <SelectButton className="font-10" id="sample_type" disabled={loading} value={inputs.sample_type_id} options={sampleTypes} onChange={(e) => changeInput('sample_type_id', e)}></SelectButton>
                                            </div>

                                            <div className="p-col-12" style={{ paddingTop: 0 }}>
                                                <div className="select-label" htmlFor="mineral">{getLabel('mineral')}</div>
                                                <SelectButton multiple={true} className="font-10" id="minerals" disabled={loading} value={inputs.minerals} options={minerals} onChange={(e) => changeInput('minerals', e)}></SelectButton>
                                            </div>

                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <div className="p-col-12">
                                                <Button disabled={loading} style={{ width: "100%" }} label={getLabel('search')} onClick={() => getReport()} className='p-button-raised p-button-success no-print' icon="pi pi-search" />
                                            </div>
                                            <div className="p-col-12">
                                                <Button
                                                    disabled={loading} style={{ width: "100%" }} label={getLabel('reporting')}
                                                    className='p-button-raised p-button-secondary no-print' icon="pi pi-print"
                                                    onClick={() => {
                                                        logEvent("[Report] Print");
                                                        window.print();
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Panel>
                                {data.length !== 0 && (
                                    <div>
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <Chart key={"main"} redraw type="bar" data={chartData} options={{
                                                    responsive: true,
                                                    elements: {
                                                        line: {
                                                            fill: false
                                                        },
                                                        point: {
                                                            radius: 0
                                                        }
                                                    },
                                                    scales: {
                                                        x:
                                                        {
                                                            type: "category",
                                                            id: "sampleNo",
                                                            stacked: true,
                                                            beginAtZero: true,
                                                            ticks: {
                                                                callback: function (label) {
                                                                    let realLabel = this.getLabelForValue(label);
                                                                    var sampleNo = realLabel.split(";")[0];
                                                                    return sampleNo;
                                                                },
                                                            }
                                                        },
                                                        xAxis2:
                                                        {
                                                            type: "category",
                                                            id: "date",
                                                            grid: {
                                                                drawOnChartArea: false,
                                                            },
                                                            beginAtZero: true,
                                                            ticks: {
                                                                callback: function (label) {
                                                                    let realLabel = this.getLabelForValue(label);
                                                                    var date = realLabel.split(";")[1];
                                                                    return date;
                                                                },
                                                            }
                                                        },

                                                        line:
                                                        {
                                                            display: true,
                                                            position: 'left',
                                                            type: "linear",
                                                            id: "line",
                                                            min: LocalStorage.get("first_min"),
                                                            beginAtZero: true,
                                                            ticks: {
                                                                stepSize: 4
                                                            }
                                                        },
                                                        bar: {
                                                            stacked: true,
                                                            display: true,
                                                            type: "linear",
                                                            position: "right",
                                                            grid: {
                                                                drawOnChartArea: false,
                                                            },
                                                            gridLines: {
                                                                display: false
                                                            },
                                                            id: "bar",
                                                            min: 0,
                                                            max: LocalStorage.get("second_max"),
                                                            beginAtZero: false,
                                                        }
                                                    },
                                                    plugins: {
                                                        tooltip: {
                                                            // enabled: false,
                                                            // position: 'nearest',
                                                            // external: externalTooltipHandler,
                                                            intersect: false,
                                                            yAlign: 'bottom',
                                                            callbacks: {
                                                                label: function (tooltipItem) {
                                                                    const title = []
                                                                    title.push(`${getLabel('sample_no')} : ${tooltipItem?.label?.split(";")[0]}`);
                                                                    title.push(`${getLabel('date')} : ${tooltipItem?.label?.split(";")[1]}`);
                                                                    if (tooltipItem?.raw?.z)
                                                                        title.push(`${getLabel('notes')} : ${tooltipItem?.raw?.z || ""}`);
                                                                    title.push(`${getLabel('class_design')} : ${tooltipItem.raw?.q || ""}`);
                                                                    return title;
                                                                },
                                                                title: function (tooltipItem) {
                                                                    if (tooltipItem?.length > 0)
                                                                        return `${tooltipItem[0].dataset?.label} : ${tooltipItem[0].formattedValue}` || getLabel('report.detail');
                                                                    else
                                                                        return getLabel('report.detail');
                                                                }
                                                            }
                                                        },
                                                        title: {
                                                            display: true,
                                                            position: 'top',
                                                            text: getLabel('report.chartHeader'),
                                                            fontSize: 18
                                                        },
                                                        datalabels: {
                                                            display: false,
                                                            align: 'center',
                                                            anchor: 'top',
                                                            offset: 0,
                                                            color: 'white',
                                                            formatter: function (value, context) {
                                                                if (value.constructor === Object) {
                                                                    if (context?.dataset?.type === 'line')
                                                                        return value.y?.toFixed(reportSettings.chart.digit);
                                                                    else
                                                                        return value.y;
                                                                }
                                                                else
                                                                    return value;
                                                            },
                                                            font: {
                                                                weight: 'bold',
                                                                size: 10
                                                            },
                                                            //     listeners: {
                                                            //         enter: function (context) {
                                                            //             context.hovered = true;
                                                            //             return true;
                                                            //         },
                                                            //         leave: function (context) {
                                                            //             context.hovered = false;
                                                            //             return true;
                                                            //         }
                                                            //     }
                                                        }
                                                    }
                                                }} />
                                            </div>

                                            <div className="p-col-12">
                                                <Chart key={"temp"} type="line" redraw data={tempChartData} options={
                                                    {
                                                        responsive: true,
                                                        aspectRatio: 4,
                                                        // legend: {
                                                        //     labels: {
                                                        //         fontColor: 'white'
                                                        //     }
                                                        // },
                                                        scales: {
                                                            x: {
                                                                ticks: {
                                                                    // fontColor: "white",
                                                                }
                                                            },
                                                            y: {
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    // fontColor: "white",
                                                                }
                                                            }
                                                        },
                                                        plugins: {
                                                            title: {
                                                                display: true,
                                                                // fontColor: 'white',
                                                                position: 'top',
                                                                text: getLabel('report.tempChartHeader'),
                                                                fontSize: 18
                                                            },
                                                            datalabels: {
                                                                // color: 'white',
                                                                align: 'top',
                                                                anchor: 'end',
                                                            }
                                                        }
                                                    }
                                                } />
                                            </div>

                                            <div className="p-col-12">
                                                <Chart key={"fresh-concrete"} type="bar" redraw data={concreteChartData} options={{
                                                    responsive: true,
                                                    aspectRatio: 4,
                                                    // legend: {
                                                    //     labels: {
                                                    //         fontColor: 'white'
                                                    //     }
                                                    // },

                                                    scales: {
                                                        x: {
                                                            ticks: {
                                                                // fontColor: "white",
                                                            }
                                                        },
                                                        y: {
                                                            min: 2200
                                                        }
                                                    },
                                                    plugins: {
                                                        title: {
                                                            display: true,
                                                            // fontColor: 'white',
                                                            position: 'top',
                                                            text: getLabel('fresh_concrete_density'),
                                                            fontSize: 18
                                                        },
                                                        datalabels: {
                                                            // color: 'white',
                                                            anchor: 'center',
                                                        }
                                                    }
                                                }} />
                                            </div>
                                        </div>

                                        <div className="p-col-12">
                                            <table border="1" className="summary-table">
                                                <tbody>
                                                    <tr className="font-w-600">
                                                        <td colSpan={(dataDefinition?.pass_days?.length + dataDefinition?.prediction_days?.length + 5) + ""} className="border-bottom">{getLabel('report.summaryTable')}</td>
                                                    </tr>
                                                    <tr className="font-w-600">
                                                        <td colSpan={(dataDefinition?.pass_days?.length + 5) + ""}>{getLabel('report.avgTable')}</td>
                                                        <td colSpan={(dataDefinition?.prediction_days?.length) + ""}>{getLabel('report.accTable')}</td>
                                                    </tr>
                                                    <tr className="font-w-400">
                                                        {
                                                            dataDefinition?.pass_days && dataDefinition.pass_days.map(day => (
                                                                <td key={day + dataDefinition.ultimate}>{getLabel('report.pass').replace("DAY", day).replace("ULT", dataDefinition.ultimate)}</td>
                                                            ))
                                                        }
                                                        <td key="summary-label-std">{getLabel('stdDeviation')}</td>
                                                        <td key="summary-label-cement">{getLabel('cement')}(kg)</td>
                                                        <td key="summary-label-mineral">{getLabel('mineral')}(kg)</td>
                                                        <td key="summary-label-additive">{getLabel('additive')}({LocalStorage.get("additive_unit")})</td>
                                                        <td key="summary-label-day">{getLabel('report.day').replace("#DAY#", dataDefinition?.ultimate)}</td>
                                                        {
                                                            dataDefinition?.prediction_days && dataDefinition.prediction_days.map(day => (
                                                                <td key={`summary-predict-${day}`}>{getLabel('report.mape').replace("DAY", day).replace("ULT", dataDefinition.ultimate)}</td>
                                                            ))
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            dataDefinition?.pass_days && dataDefinition.pass_days.map(day => (
                                                                <td key={`summary-definition-${day}`}>{summary[`pass_${day}`]}</td>
                                                            ))
                                                        }
                                                        <td key="sum-avg-std">{summary?.std_ultimate_st}</td>
                                                        <td key="sum-avg-cement">{summary?.avg_cement}</td>
                                                        <td key="sum-avg-mineral">{summary?.avg_mineral}</td>
                                                        <td key="sum-avg-additive">{summary?.avg_additive}</td>
                                                        <td key="sum-avg-ultimate">{summary?.avg_ultimate_st}</td>
                                                        {
                                                            dataDefinition?.prediction_days && dataDefinition.prediction_days.map(day => (
                                                                <td key={`summary-mape-${day}`}>{summary[`mape_${day}`]}</td>
                                                            ))
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div className="no-print p-flex-row-reverse p-grid p-col-12" style={{ marginTop: 20 }}>
                                                <Button disabled={loading} label={getLabel('download')} className='p-button-raised p-button-secondary p-button-sm'
                                                    icon="pi pi-external-link" iconPos="left" onClick={() => exportTable()} />
                                            </div>

                                            <DataTable value={data} exportFilename={getLabel('filter.exportFileName')}
                                                ref={(el) => { setDataTable(el) }} scrollable scrollHeight="300px"
                                                className="no-print p-datatable-responsive p-datatable-sm 
                                                header-center custom-report-table p-datatable-gridlines"
                                                sortMode="multiple"
                                                removableSort>
                                                <Column key="date" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="date" style={{ width: 60 }} header={getLabel('date')} sortable />
                                                <Column key="sample_no" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="sample_no" header={getLabel('sample_no')} sortable />
                                                <Column key="cement_amount" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="cement_amount" header={getLabel('cement')} sortable />
                                                <Column key="mineral_amount" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="mineral_amount" header={getLabel('mineral')} sortable />
                                                <Column key="weather_temp" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="weather_temp" header={getLabel('temp')} sortable />
                                                <Column key="concrete_temp" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="concrete_temp" header={getLabel('concrete_temp')} sortable />
                                                <Column key="fresh_concrete_density" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="fresh_concrete_density" header={getLabel('fresh_concrete_density')} sortable />
                                                <Column key="slump" field="slump" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' header={getLabel('report.slump')} sortable />
                                                <Column key="flow_diameter" alignHeader={"center"} align={"center"} headerClassName='table-header-custom-center' field="flow_diameter" header={getLabel('flow_diameter')} sortable />
                                                {
                                                    dataDefinition?.break_days && dataDefinition.break_days.map(day => (
                                                        <Column key={`column-break-${day}`} alignHeader={"center"} headerClassName='table-header-custom-center' align={"center"} field={`day_${day}_strength`} header={getLabel('report.day').replace("#DAY#", day)} sortable />
                                                    ))
                                                }
                                                {
                                                    dataDefinition?.break_days && dataDefinition.prediction_days.map(day => (
                                                        <Column key={`column-predict-${day}`} alignHeader={"center"} headerClassName='table-header-custom-center' align={"center"} field={`prediction_${day}_${dataDefinition.ultimate}`} header={getLabel('report.prediction').replace("#DAY#", day).replace("#ULTIMATE#", dataDefinition.ultimate)} sortable />
                                                    ))
                                                }
                                                <Column key="class_desing" field="class_design" alignHeader={"center"} headerClassName='table-header-custom-center' align={"center"} style={{ width: 120 }} header={getLabel('class_design')} sortable />
                                                <Column key="statement" field="statement" alignHeader={"center"} headerClassName='table-header-custom-center' align={"center"} style={{ width: 120 }} header={getLabel('notes')} sortable />

                                            </DataTable>

                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {loading && (
                            <MyLoader />
                        )
                        }
                    </Card>
                    <Footer />
                </>
            )
            }
            <Toast ref={(el) => setToast(el)} />

        </div >
    )
};

export default withTranslation()(Report);
