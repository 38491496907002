import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Header from './common/Header';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { uiCheck } from '../actions/user';
import Footer from './common/Footer';
import { logEvent } from '../helpers/amplitude';
import { getLabel } from '../helpers/translator';

const TermsOfConditions = ({ history }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        logEvent("[Terms Of Condition] View");
        uiCheck('static').then(d => { if (d.success) setLoading(false) }).catch(e => { setLoading(false) })
    }, [])

    return (
        <div>
            {
                loading && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }

            {!loading && (
                <div>
                    <Header history={history} />
                    <Card title={getLabel('terms.terms')}>
                        <p>
                            {getLabel('terms.p1')}
                        </p>

                        <p>
                            {getLabel('terms.p2')}
                        </p>

                        <p>
                            {getLabel('terms.p3')}
                        </p>

                        <h3>1. {getLabel('terms.responsibility')}</h3>
                        <p>
                            a. {getLabel('terms.r1')}
                        </p>
                        <p>

                            b. {getLabel('terms.r2')}
                        </p>
                        <p>
                            c. {getLabel('terms.r3')}
                        </p>
                        <p>
                            d. {getLabel('terms.r4')}
                        </p>

                        <h3>2. {getLabel('terms.ipr')}</h3>
                        <p>
                            2.1. {getLabel('terms.i1')}
                        </p>
                        <p>

                            2.2. {getLabel('terms.i2')}
                        </p>

                        <h3>3. {getLabel('terms.confident')}</h3>
                        <p>
                            3.1. {getLabel('terms.c1')}
                        </p>
                        <p>

                            3.2. {getLabel('terms.c2')}
                        </p>

                        <p>

                            3.3. {getLabel('terms.c3')}
                        </p>

                        <h3>4. {getLabel('terms.guarantee')}</h3>
                        <p>
                            {getLabel('terms.g1')}
                        </p>

                        <h3>5. {getLabel('terms.reg')}</h3>
                        <p>
                            {getLabel('terms.reg1')}
                        </p>

                        <h3>6. {getLabel('terms.forceM')}</h3>
                        <p>
                            {getLabel('terms.f1')}
                        </p>

                        <h3>7. {getLabel('terms.aia')}</h3>
                        <p>
                            {getLabel('terms.aia1')}
                        </p>

                        <h3>8. {getLabel('terms.ac')}</h3>
                        <p>
                            {getLabel('terms.ac1')}
                        </p>

                        <h3>9. {getLabel('terms.notify')}</h3>
                        <p>
                            {getLabel('terms.n1')}
                        </p>

                        <h3>10. {getLabel('terms.ea')}</h3>
                        <p>
                            {getLabel('terms.ea1')}
                        </p>

                        <h3>11. {getLabel('terms.clj')}</h3>
                        <p>
                            {getLabel('terms.clj1')}
                        </p>
                    </Card>
                    <Footer />
                </div>
            )}
        </div>

    )
};

export default withTranslation()(TermsOfConditions);