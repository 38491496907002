import { post as $post, get as $get } from './core/fetch';


export const new_sample = (sample) => $post('/new-sample', sample);
export const get_sample_no = (plant_id, year) => $get(`/get-sample-no/${plant_id}/${year}`);
export const get_plant_class_sample = (plant_id, class_id, class_design_id) => $get(`/get-plant-class-sample/${plant_id}/${class_id}/${class_design_id}`);
export const get_samples = (date, plant_id) => $get(`/get-samples/${date}/${plant_id}`);
export const update_samples = (samples) => $post('/update-samples', samples);
export const get_samples_for_update = (search) => $post('/get-samples-for-update', search);
export const sample_detail = (id) => $get(`/sample-detail/${id}`);
