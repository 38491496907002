
import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import Header from '../common/Header';
import Footer from '../common/Footer';
import MethyleneBlueTest from './MethyleneBlueTest';
import OtherMaterialsSpecificGravity from './OtherMaterialsSpecificGravity';
import AggregateParticleSizeDistribution from './AggregateParticleSizeDistribution';
import AggregateSpecificWeightAndWaterAbsorption from './AggregateSpecificWeightAndWaterAbsorption';
import ChemicalAdditiveTracking from './ChemicalAdditiveTracking';
import LooseCompressedUnitWeight from './LooseCompressedUnitWeight';
import SandEquivalent from './SandEquivalent';
import FlatnessIndex from './FlatnessIndex';
import ShapeIndex from './ShapeIndex';
import LosAngelesTest from './LosAngelesTest';
import { uiCheck } from '../../actions/user';
import { physicalTestDetail } from '../../actions/physicalTest';
import { getLabel } from '../../helpers/translator';


const PhysicalTestHome = ({ history, match }) => {
  const [testCode, setTestCode] = useState(match?.params?.testCode);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const [testData, setTestData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [toast, setToast] = useState({});

  useEffect(() => {
    uiCheck('physical-test').then(d => { setLoading(false); setAuth(d.auth); }).catch(e => { setLoading(false); setAuth(false); });
  }, []);

  useEffect(() => {
    setTestCode(match.params.testCode);
    physicalTestDetail(match.params.testCode).then(d => {
      if (d.success) {
        setTestData(d.data);
        setHasError(false);
      }
      else {
        setTestData(null);
        setHasError(true);
      }
    });
  }, [match.params]);

  const loadTest = {
    "methylene-blue": <MethyleneBlueTest history={history} test={testData} toast={toast} />,
    "other-materials-specific-gravity": <OtherMaterialsSpecificGravity history={history} test={testData} toast={toast} />,
    "aggregate-particle-size-distribution": <AggregateParticleSizeDistribution history={history} test={testData} toast={toast} />,
    "aggregate-specific-weight-and-water-absorption": <AggregateSpecificWeightAndWaterAbsorption history={history} test={testData} toast={toast} />,
    "chemical-additive-tracking": <ChemicalAdditiveTracking history={history} test={testData} toast={toast} />,
    "loose-compressed-unit-weight": <LooseCompressedUnitWeight history={history} test={testData} toast={toast} />,
    "sand-equivalent": <SandEquivalent history={history} test={testData} toast={toast} />,
    "flatness-index": <FlatnessIndex history={history} test={testData} toast={toast} />,
    "shape-index": <ShapeIndex history={history} test={testData} toast={toast} />,
    "los-angeles-test": <LosAngelesTest history={history} test={testData} toast={toast} />
  }


  return (
    <div>
      {
        loading && (
          <ProgressSpinner style={{ left: "50%" }} />
        )
      }
      {
        !loading && (
          <div>
            <Header history={history} />
            {
              !auth && (
                <div className="p-grid">
                  <div className="p-col-12">
                    <Message severity="error" text={getLabel('permission')}></Message>
                  </div>
                </div>
              )
            }
            {
              hasError && (
                <div className="p-grid">
                  <div className="p-col-12">
                    <Message severity="error" text={getLabel('error')}></Message>
                  </div>
                </div>
              )
            }
            {
              auth && testData && (
                <div>{loadTest[testCode]}</div>
              )
            }
            <Toast ref={(el) => setToast(el)} />
            <Footer />
          </div>
        )
      }

    </div>
  );
};

export default withTranslation()(PhysicalTestHome);