import { InputText } from 'primereact/inputtext';
import { withTranslation } from 'react-i18next';
import { getLabel } from '../../helpers/translator';

const MyInputText = ({ disabled, customClass, htmlFor, value, id, checkChanges, change, customHeader = "", required = false }) => {
    const compClass = [
        'p-float-label',
        checkChanges && value ? 'input-changed' : ''
    ]

    const requiredClass = required && !value ? 'p-invalid' : '';
    return (
        <div className={customClass}>
            <span className={compClass.join(' ')}>
                <InputText
                    disabled={disabled} className={requiredClass} id={id}
                    value={value || ""} style={{ width: '100%' }}
                    onChange={(e) => change(id, e)} />

                {customHeader === "" && (
                    <label htmlFor={id}>{getLabel(htmlFor)}</label>
                )}

                {customHeader !== "" && (
                    <label htmlFor={id}>{customHeader}</label>
                )}
            </span>
        </div>
    )
};

export default withTranslation()(MyInputText);