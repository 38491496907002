import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { LocalStorage } from '../../../helpers/storage';
import { getLang, getLabel, dateFormat } from '../../../helpers/translator';
import { listItems } from '../../../actions/list';
import { updatePhysicalTestData } from '../../../actions/physicalTest';
import { logEvent } from '../../../helpers/amplitude';


const Table = ({ fields, data, setData, loading, setLoading, toast, testCode, editMode = false }) => {


  const INITIAL_STATE = {
    plants: [],
    aggregateTypes: [{ label: getLabel('natural_sand_cost'), value: 'natural_sand' },
    { label: getLabel('crushed_sand_cost'), value: 'crushed_sand' },
    { label: getLabel('no_1_cost'), value: 'no_1' },
    { label: getLabel('no_2_cost'), value: 'no_2' }],
    materials: [
      { label: getLabel('cement'), value: 'cement' },
      { label: getLabel('mineral'), value: 'mineral' },
      { label: getLabel('additive'), value: 'additive' },
      { label: getLabel('physicalTest.generalLabels.recycleWater'), value: 'recycle_water' },
      { label: getLabel('physicalTest.generalLabels.recycleWaterSolids'), value: 'recycle_water_solid' },
    ],
    experimentTypes: [
      { label: getLabel('physicalTest.generalLabels.loose'), value: 'loose' },
      { label: getLabel('physicalTest.generalLabels.compressed'), value: 'compressed' }
    ],
    sources: [],
    minerals: [],
    cements: [],
    additives: [],
    naturalSandSources: [],
    crushedSandSources: [],
    aggNoOneSources: [],
    aggNoTwoSources: [],
  };
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const dataTable = useRef(null);
  const timezone = LocalStorage.get('timezone');

  useEffect(() => {

    listItems('plant').then(d => {
      let plants = [];
      if (d.success) {
        d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('plants', plants);
    });

    listItems('mineral').then(d => {
      let minerals = [];
      if (d.success) {
        d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('minerals', minerals);
    });

    listItems('cement').then(d => {
      let cements = [];
      if (d.success) {
        d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('cements', cements);
    });

    listItems('additive').then(d => {
      let additives = [];
      if (d.success) {
        d.data.forEach(i => additives.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('additives', additives);

    });

    listItems('natural_sand').then(d => {
      let naturalSandSources = [];
      if (d.success) {
        d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('naturalSandSources', naturalSandSources);
    });

    listItems('crushed_sand').then(d => {
      let crushedSandSources = [];
      if (d.success) {
        d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('crushedSandSources', crushedSandSources);
    });

    listItems('agg_no_one').then(d => {
      let aggNoOneSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoOneSources', aggNoOneSources);
    });

    listItems('agg_no_two').then(d => {
      let aggNoTwoSources = [];
      if (d.success) {
        d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
      }
      changeArrayInput('aggNoTwoSources', aggNoTwoSources);
    });

  }, []);

  const updateTest = () => {
    setLoading(true);
    const tests = _.cloneDeep(data).filter(f => f.value.updated);
    tests.forEach(d => d.value.date = moment.utc(d.value.date).tz(timezone).format("YYYY-MM-DD HH:mm:ss"));

    logEvent(`[Physical Test Update] - ${testCode}`, { tests: tests.map(t => t.code) });

    updatePhysicalTestData(tests).then(d => {
      setLoading(false);
      if (d.success) {
        toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('physicalTest.operation.updateSuccess') });
      }
      else if (d.exists) {
        toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('physicalTest.operation.existsTest') });
      }
      else {
        toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.updateError') });
      }
    }).catch(_e => {
      setLoading(false);
      toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('physicalTest.operation.updateError') });
    });
  }

  const changeArrayInput = (key, e) => {
    setInputs(prevState => { return { ...prevState, [key]: e } });
  }

  const exportTable = (selectionOnly) => {
    logEvent(`[Physical Test Export Table] - ${testCode}`);
    dataTable.current.exportCSV({ selectionOnly });
  }

  const exportFunction = ({ column, data, field, rowData }) => {
    return getExportData(column, data, field, rowData);
  }

  const getExportData = (column, data, field, rowData) => {
    const { multi_choice_entry } = column?.props?.entryField || false;
    switch (field) {
      case "aggregateType":
        return inputs.aggregateTypes.find(f => f.value === data)?.label || '';
      case "material":
        return inputs.materials.find(f => f.value === data)?.label || '';
      case "experimentType":
        return inputs.experimentTypes.find(f => f.value === data)?.label || '';
      case "plant":
        const plants = inputs.plants.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        return multi_choice_entry ? plants.map(p => p.label).join(",") : plants ? plants[0].label : '';
      case "source":
        const sources = getSourceData(rowData, data);
        return multi_choice_entry ? sources.map(p => p.label).join(",") : sources ? sources[0].label : '';
      case "date":
        return moment.utc(data).tz(timezone).format(`${dateFormat()} HH:mm`)
      default:
        return data;
    }
  }


  const getSourceData = (rowData, data, multi_choice_entry) => {
    const { aggregateType, material } = rowData;
    if (aggregateType) {
      switch (aggregateType) {
        case "natural_sand":
          return inputs.naturalSandSources.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        case "crushed_sand":
          return inputs.crushedSandSources.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        case "no_1":
          return inputs.aggNoOneSources.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        case "no_2":
          return inputs.aggNoTwoSources.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        default:
          return null;
      }
    }

    if (material) {
      switch (material) {
        case "cement":
          return inputs.cements.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        case "mineral":
          return inputs.minerals.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        case "additive":
          return inputs.additives.filter(f => multi_choice_entry ? data.includes(f.value) : f.value === data);
        default:
          return null;
      }
    }

    return inputs.additives.filter(f => f.value === data);

  }

  const getFieldData = (rowData, field) => {
    const { code, multi_choice_entry, onRunTime, formula, func } = field;
    switch (code) {
      case "aggregateType":
        return inputs.aggregateTypes.find(f => f.value === rowData[code])?.label || '';
      case "material":
        return inputs.materials.find(f => f.value === rowData[code])?.label || '';
      case "experimentType":
        return inputs.experimentTypes.find(f => f.value === rowData[code])?.label || '';
      case "plant":
        const plants = inputs.plants.filter(f => multi_choice_entry ? rowData[code].includes(f.value) : f.value === rowData[code]);
        return multi_choice_entry ? plants.map(p => p.label).join(",") : plants.length > 0 ? plants[0].label : '';
      case "source":
        const sources = getSourceData(rowData, rowData['source']);
        return multi_choice_entry ? sources.map(p => p.label).join(",") : sources && sources.length > 0 ? sources[0].label : '';
      case "date":
        return moment.utc(rowData['date']).tz(timezone).format(`${dateFormat()} HH:mm`)
      default:
        if (onRunTime) {
          let result = null;
          if (func) {
            result = eval(formula);
          }
          else {
            result = eval(formula).toFixed(2);
          }
          rowData[code] = result;
          return rowData[code];
        }
        else {
          return rowData[code];
        }
    }
  }

  const getDropdownSource = (field, rowData) => {
    const { source, sourceFilter, sourceName } = field;
    const { aggregateType, material } = rowData;

    if (source === 'aggregate_sources')
      return sourceFilter ? inputs.aggregateTypes.filter(f => eval(sourceFilter)) : inputs.aggregateTypes;

    if (source === 'plants')
      return sourceFilter ? inputs.plants.filter(f => eval(sourceFilter)) : inputs.plants;

    if (source === 'materials')
      return sourceFilter ? inputs.materials.filter(f => eval(sourceFilter)) : inputs.materials;

    if (source === 'experimentType')
      return sourceFilter ? inputs.experimentTypes.filter(f => eval(sourceFilter)) : inputs.experimentTypes;

    if (source === 'sources') {

      if (sourceName) {
        return sourceFilter ? inputs[sourceName].filter(f => eval(sourceFilter)) : inputs[sourceName];
      }

      if (aggregateType) {
        switch (aggregateType) {
          case "natural_sand":
            return sourceFilter ? inputs.naturalSandSources.filter(f => eval(sourceFilter)) : inputs.naturalSandSources;
          case "crushed_sand":
            return sourceFilter ? inputs.crushedSandSources.filter(f => eval(sourceFilter)) : inputs.crushedSandSources;
          case "no_1":
            return sourceFilter ? inputs.aggNoOneSources.filter(f => eval(sourceFilter)) : inputs.aggNoOneSources;
          case "no_2":
            return sourceFilter ? inputs.aggNoTwoSources.filter(f => eval(sourceFilter)) : inputs.aggNoTwoSources;
          default:
            return null;
        }
      }

      if (material) {
        switch (material) {
          case "cement":
            return sourceFilter ? inputs.cements.filter(f => eval(sourceFilter)) : inputs.cements;
          case "mineral":
            return sourceFilter ? inputs.minerals.filter(f => eval(sourceFilter)) : inputs.minerals;
          case "additive":
            return sourceFilter ? inputs.additives.filter(f => eval(sourceFilter)) : inputs.additives;
          default:
            return null;
        }
      }

    }
  }

  const renderField = (rowData, field) => {
    const body = getFieldData(rowData, field);
    return (
      <div className='render-field' data-pr-tooltip={body?.length > 15 ? body : ''}>
        {body?.length > 15 ? `${body.substring(0, 15)}...` : body}
        {body?.length > 15 ? <Tooltip target=".render-field" /> : null}
      </div>
    );
  }

  const cellEditor = (options) => {
    const { field } = options;
    const { entryField } = options?.column?.props;
    if (field === 'testNo')
      return numberEditor(options, 0);
    else if (field === 'date')
      return dateEditor(options);
    else if (entryField?.type === 'number')
      return numberEditor(options);
    else if (entryField.type === 'text')
      return textEditor(options);
    else if (entryField.type === 'dropdown')
      return listEditor(options);
    else
      return textEditor(options);
  }

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  }

  const numberEditor = (options, fractionDigits = 2) => {
    return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} step={0.1} useGrouping={false} minFractionDigits={fractionDigits} />
  }

  const dateEditor = (options) => {
    const value = moment.utc(options.value).tz(timezone).toDate();
    return <Calendar showButtonBar showIcon showTime hourFormat="24" stepMinute={15} locale={getLang()} value={value} onChange={(e) => options.editorCallback(e.value)} />
  }

  const listEditor = (options) => {
    return <Dropdown value={options.value} options={getDropdownSource(options.column.props.entryField, options.rowData)} onChange={(e) => onEditorValueChange(options, e.value)} />
  }

  const onEditorValueChange = (props, value) => {
    const newData = [...data];
    const { rowData, rowIndex, field } = props;
    rowData[field] = value;
    rowData['updated'] = true;
    newData[rowIndex]['value'] = rowData;
    setData(newData);
  }

  const onCellEditComplete = (e) => {
    const newData = [...data];
    let { rowData, rowIndex, newValue, field } = e;
    rowData[field] = newValue;
    rowData['updated'] = true;
    newData[rowIndex]['value'] = rowData;
    setData(newData);
  }

  const flatnessIndexValue = (rowData) => {
    return (
      (
        (
          rowData['16_sieve'] + rowData['12_sieve'] + rowData['10_sieve'] +
          rowData['8_sieve'] + rowData['6_sieve'] + rowData['5_sieve'] +
          rowData['4_sieve'] + rowData['3_sieve'] + rowData['2_sieve']
        )
        /
        (
          rowData['25_31_fraction'] + rowData['20_25_fraction'] + rowData['16_20_fraction'] +
          rowData['12_16_fraction'] + rowData['10_12_fraction'] + rowData['8_10_fraction'] +
          rowData['6_8_fraction'] + rowData['5_6_fraction'] + rowData['4_5_fraction']
        )
      ) * 100).toFixed(2);
  }

  const flatnessIndexCategory = (rowData) => {
    const val = flatnessIndexValue(rowData);
    if (val <= 15) {
      return "FI 15";
    }
    else if (val <= 20) {
      return "FI 20";
    }
    else if (val <= 35) {
      return "FI 35";
    }
    else if (val <= 50) {
      return "FI 50";
    }
    else {
      return "FI BEYAN";
    }
  }

  const flatnessIndexSuitability = (rowData) => {
    const val = (rowData['sampleWeight'] - (
      rowData['remaining_80_100mm'] + rowData['passingThrough_4mm'] + rowData['untestedSampleMass'] + rowData['25_31_fraction'] +
      rowData['20_25_fraction'] + rowData['16_20_fraction'] + rowData['12_16_fraction'] + rowData['10_12_fraction'] +
      rowData['8_10_fraction'] + rowData['6_8_fraction'] + rowData['5_6_fraction'] + rowData['4_5_fraction']
    )) / rowData['sampleWeight'] * 100
    if (val < 1)
      return <span class="green-dot">●</span>;
    else
      return <span class="red-dot">●</span>

  }

  const shapeIndexValue = (rowData) => {
    const nonCubic = (rowData['31_5_cubic'] || 0) + (rowData['22_4_cubic'] || 0) + (rowData['16_cubic'] || 0) + (rowData['11_2_cubic'] || 0) +
      (rowData['8_cubic'] || 0) + (rowData['5_6_cubic'] || 0) + (rowData['4_cubic'] || 0) + (rowData['2_cubic'] || 0);
    const sieve = (rowData['31_5_sieve'] || 0) + (rowData['22_4_sieve'] || 0) + (rowData['16_sieve'] || 0) + (rowData['11_2_sieve'] || 0)
      + (rowData['8_sieve'] || 0) + (rowData['5_6_sieve'] || 0) + (rowData['4_sieve'] || 0) + (rowData['2_sieve'] || 0);

    return (nonCubic / sieve * 100).toFixed(2);
  }

  const looseCompressedUnitWeight = (rowData) => {
    let data = [];
    if (rowData['fullContainerWeightMeasurement_1']) {
      data.push(rowData['fullContainerWeightMeasurement_1']);
    }
    if (rowData['fullContainerWeightMeasurement_2']) {
      data.push(rowData['fullContainerWeightMeasurement_2']);
    }
    if (rowData['fullContainerWeightMeasurement_3']) {
      data.push(rowData['fullContainerWeightMeasurement_3']);
    }
    const avg = data.reduce((p, c) => p + c, 0) / data.length;
    return ((avg - rowData['containerTare']) / rowData['containerVolume']).toFixed(2);
  }

  const entries = data.map(d => d.value);

  return (
    <div className="card">
      {
        entries && entries.length > 0 && (
          <>
            {
              !editMode && (
                <div className="p-flex-row-reverse p-grid p-col-12">
                  <Button disabled={loading} label={getLabel('download')} className='p-button-raised p-button-primary p-button-sm'
                    icon="pi pi-external-link" iconPos="left" onClick={() => exportTable(false)} />
                </div>
              )
            }
            {
              editMode && (
                <div className="p-flex-row-reverse p-grid p-col-12">
                  <Button disabled={loading} label={getLabel('save')} className='p-button-raised p-button-primary' onClick={() => updateTest()} />
                </div>
              )
            }
            <DataTable
              header={editMode ? getLabel('physicalTest.generalLabels.updateTableHeader') : getLabel('physicalTest.generalLabels.summaryTableHeader')}
              className="p-datatable-sm p-datatable-striped p-datatable-border update-table header-center"
              value={entries}
              scrollable
              loading={loading}
              scrollHeight="500px"
              exportFilename={getLabel('filter.exportFileName')}
              ref={dataTable}
              exportFunction={exportFunction}
              showGridlines
              removableSort
              emptyMessage={getLabel('physicalTest.generalLabels.emptyTableMessage')}
            >

              <Column
                key='testNo'
                field='testNo'
                alignHeader={"center"} align={"center"}
                header={getLabel('physicalTest.generalLabels.testNo')}
                headerClassName='datatable-custom-header'
                className="datatable-custom-column"
                {...(editMode ? { editor: cellEditor } : {})}
                onCellEditComplete={onCellEditComplete}
                sortable
              />

              <Column
                key='date'
                field='date'
                alignHeader={"center"} align={"center"}
                header={getLabel('date')}
                body={(rowData) => moment.utc(rowData['date']).tz(timezone).format(`${dateFormat()} HH:mm`)}
                headerClassName='datatable-custom-header'
                className="datatable-custom-column"
                {...(editMode ? { editor: cellEditor } : {})}
                onCellEditComplete={onCellEditComplete}
                sortable
              />
              {
                fields && fields.filter(f => editMode ? !f.onRunTime && !f.autoCalculate : f.showOnTable).map(field => (
                  <Column
                    key={field.code}
                    field={field.code}
                    alignHeader={"center"} align={"center"}
                    header={field.label[getLang()]}
                    body={(rowData) => renderField(rowData, field)}
                    headerClassName='datatable-custom-header'
                    className="datatable-custom-column"
                    {...(editMode ? { editor: cellEditor } : {})}
                    onCellEditComplete={onCellEditComplete}
                    entryField={field}
                    sortable
                  />
                ))
              }
            </DataTable>
          </>
        )
      }
    </div>
  );
};

export default withTranslation()(Table);