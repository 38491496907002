import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import MyInputNumber from '../common/MyInputNumber';
import MyInputText from '../common/MyInputText';
import MyInputTextArea from '../common/MyInputTextArea';
import { Message } from 'primereact/message';
import { newSample, getSampleNo, getPlantClassSample } from '../../actions/sample';
import { listItems } from '../../actions/list';
import { getLimit } from '../../helpers/limit';
import { LocalStorage } from '../../helpers/storage';
import { logEvent } from '../../helpers/amplitude';
import { getLang, getLabel, getAdditionalColumnDesc } from '../../helpers/translator';


const NewSample = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [sampleId, setSampleId] = useState(null);
    const [success, setSuccess] = useState(false);
    const [toast, setToast] = useState({});
    const [plants] = useState([]);
    const [classes] = useState([]);
    const [classDesigns] = useState([]);
    const [cements] = useState([]);
    const [alternateCements] = useState([]);
    const [naturalSandSources] = useState([]);
    const [crushedSandSources] = useState([]);
    const [aggNoOneSources] = useState([]);
    const [aggNoTwoSources] = useState([]);
    const [minerals] = useState([]);
    const [additiveOne] = useState([]);
    const [additiveTwo] = useState([]);
    const [additiveThree] = useState([]);
    const [sampleTypes] = useState([]);
    const [customers] = useState([]);
    const [otherFields] = useState(LocalStorage.get('other_fields'));
    const [inputs, setInputs] = useState({ 'date': new Date() });
    const [errors, setErrors] = useState({});
    const [oldInputs, setOldInputs] = useState({});
    const [originSampleNo, setOriginSampleNo] = useState(null);
    const [panels, setPanel] = useState({ general: false, recipe: false, fresh: false, note: false, other: false, measure: false })
    const [pageLoad, setPageLoad] = useState(true);
    const [showRequired, setShowRequired] = useState(false);
    const additionalReqFields = LocalStorage.get('req_fields');

    useEffect(() => {

        logEvent("[New Sample] View");

        if (plants.length === 0) {
            setLoading(true);
            listItems('plant').then(d => {
                setLoading(false);
                if (d.success) {
                    d.data.forEach(i => plants.push({ label: i.name, value: i.id }));
                    setPageLoad(false);
                }
            });
        }

        if (classes.length === 0) {
            listItems('class').then(d => {
                if (d.success) {
                    d.data.forEach(i => classes.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (classDesigns.length === 0) {
            listItems('class_design').then(d => {
                if (d.success) {
                    d.data.forEach(i => classDesigns.push(i));
                }
            });
        }

        if (cements.length === 0) {
            listItems('cement').then(d => {
                if (d.success) {
                    d.data.forEach(i => cements.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => alternateCements.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (naturalSandSources.length === 0) {
            listItems('natural_sand').then(d => {
                if (d.success) {
                    d.data.forEach(i => naturalSandSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (crushedSandSources.length === 0) {
            listItems('crushed_sand').then(d => {
                if (d.success) {
                    d.data.forEach(i => crushedSandSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (aggNoOneSources.length === 0) {
            listItems('agg_no_one').then(d => {
                if (d.success) {
                    d.data.forEach(i => aggNoOneSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (aggNoTwoSources.length === 0) {
            listItems('agg_no_two').then(d => {
                if (d.success) {
                    d.data.forEach(i => aggNoTwoSources.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (minerals.length === 0) {
            listItems('mineral').then(d => {
                if (d.success) {
                    d.data.forEach(i => minerals.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (additiveOne.length === 0) {
            listItems('additive').then(d => {
                if (d.success) {
                    d.data.forEach(i => additiveOne.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => additiveTwo.push({ label: i.name, value: i.id }));
                    d.data.forEach(i => additiveThree.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (sampleTypes.length === 0) {
            listItems('sample_type').then(d => {
                if (d.success) {
                    d.data.forEach(i => sampleTypes.push({ label: i.name, value: i.id }));
                }
            });
        }

        if (customers.length === 0) {
            listItems('customer').then(d => {
                if (d.success) {
                    d.data.forEach(i => customers.push({ label: i.name, value: i.id }));
                }
            });
        }

    }, [plants, customers, classes, cements, alternateCements, minerals, additiveOne, additiveTwo, additiveThree, sampleTypes, naturalSandSources, crushedSandSources, aggNoOneSources, aggNoTwoSources, classDesigns]);

    useEffect(() => {
        if (inputs?.plant_id) {
            setLoading(true);
            getSampleNo(inputs.plant_id, date.getFullYear()).then(d => { setOriginSampleNo(d?.data?.sample_no || 0); setLoading(false); setInputs((prevState) => { return { ...prevState, sample_no: (d?.data.sample_no || 0) + 1 } }) })
        }

    }, [inputs.plant_id, date]);

    useEffect(() => {
        if (inputs?.plant_id && inputs?.class_id && inputs?.class_design_id) {
            setLoading(true);
            getPlantClassSample(inputs.plant_id, inputs.class_id, inputs.class_design_id).then(d => {
                setLoading(false);

                if (d.success) {
                    let additionalColumns = {};
                    d.data.additional_values.forEach(d => {
                        additionalColumns[d.column.code] = d.value;
                    });
                    setOldInputs({
                        cement_id: d.data.cement_id,
                        alternative_cement_id: d.data.alternative_cement_id,
                        natural_sand_source_id: d.data.natural_sand_source_id,
                        crushed_sand_source_id: d.data.crushed_sand_source_id,
                        agg_no_one_source_id: d.data.agg_no_one_source_id,
                        agg_no_two_source_id: d.data.agg_no_two_source_id,
                        natural_sand_system_moisture: d.data.natural_sand_system_moisture,
                        crushed_sand_system_moisture: d.data.crushed_sand_system_moisture,
                        agg_no_one_system_moisture: d.data.agg_no_one_system_moisture,
                        agg_no_two_system_moisture: d.data.agg_no_two_system_moisture,
                        mineral_id: d.data.mineral_id,
                        customer_id: d.data.customer_id,
                        additive_one_id: d.data.additive_one_id,
                        additive_two_id: d.data.additive_two_id,
                        add_one_amount: d.data.add_one_amount,
                        add_two_amount: d.data.add_two_amount,
                        agg_no_one: d.data.agg_no_one,
                        agg_no_two: d.data.agg_no_two,
                        alternative_cement_amount: d.data.alternative_cement_amount,
                        cement_amount: d.data.cement_amount,
                        crushed_sand: d.data.crushed_sand,
                        crushed_sand_methylene: d.data.crushed_sand_methylene,
                        mineral_amount: d.data.mineral_amount,
                        natural_sand: d.data.natural_sand,
                        natural_sand_methylene: d.data.natural_sand_methylene,
                        theoretical_water: d.data.theoretical_water,
                        environmental_effect: d.data.environmental_effect,
                        clor_content: d.data.clor_content,
                        ...additionalColumns
                    });
                    setInputs((prevState) => {
                        return {
                            plant_id: prevState.plant_id,
                            class_id: prevState.class_id,
                            date: prevState.date,
                            sample_no: prevState.sample_no,
                            cement_id: d.data.cement_id,
                            alternative_cement_id: d.data.alternative_cement_id,
                            natural_sand_source_id: d.data.natural_sand_source_id,
                            crushed_sand_source_id: d.data.crushed_sand_source_id,
                            class_design_id: prevState.class_design_id,
                            agg_no_one_source_id: d.data.agg_no_one_source_id,
                            agg_no_two_source_id: d.data.agg_no_two_source_id,
                            natural_sand_system_moisture: d.data.natural_sand_system_moisture,
                            crushed_sand_system_moisture: d.data.crushed_sand_system_moisture,
                            agg_no_one_system_moisture: d.data.agg_no_one_system_moisture,
                            agg_no_two_system_moisture: d.data.agg_no_two_system_moisture,
                            mineral_id: d.data.mineral_id,
                            customer_id: d.data.customer_id,
                            additive_one_id: d.data.additive_one_id,
                            additive_two_id: d.data.additive_two_id,
                            add_one_amount: d.data.add_one_amount,
                            add_two_amount: d.data.add_two_amount,
                            agg_no_one: d.data.agg_no_one,
                            agg_no_two: d.data.agg_no_two,
                            alternative_cement_amount: d.data.alternative_cement_amount,
                            cement_amount: d.data.cement_amount,
                            crushed_sand: d.data.crushed_sand,
                            crushed_sand_methylene: d.data.crushed_sand_methylene,
                            mineral_amount: d.data.mineral_amount,
                            natural_sand: d.data.natural_sand,
                            natural_sand_methylene: d.data.natural_sand_methylene,
                            theoretical_water: d.data.theoretical_water,
                            environmental_effect: d.data.environmental_effect || "",
                            clor_content: d.data?.clor_content || "",
                            ...additionalColumns
                        }
                    });

                    setErrors(
                        {
                            add_one_amount: d.data.add_one_amount && (d.data.add_one_amount < getLimit('add_one_amount', 'lower') || d.data.add_one_amount > getLimit('add_one_amount', 'upper')),
                            add_two_amount: d.data.add_two_amount && (d.data.add_two_amount < getLimit('add_two_amount', 'lower') || d.data.add_two_amount > getLimit('add_two_amount', 'upper')),
                            agg_no_one: d.data.agg_no_one && (d.data.agg_no_one < getLimit('agg_no_one', 'lower') || d.data.agg_no_one > getLimit('agg_no_one', 'upper')),
                            agg_no_two: d.data.agg_no_two && (d.data.agg_no_two < getLimit('agg_no_two', 'lower') || d.data.agg_no_one > getLimit('agg_no_one', 'upper')),
                            alternative_cement_amount: d.data.alternative_cement_amount && (d.data.alternative_cement_amount < getLimit('alternative_cement_amount', 'lower') || d.data.alternative_cement_amount > getLimit('alternative_cement_amount', 'upper')),
                            cement_amount: d.data.cement_amount && (d.data.cement_amount < getLimit('cement_amount', 'lower') || d.data.cement_amount > getLimit('cement_amount', 'upper')),
                            crushed_sand: d.data.crushed_sand && (d.data.crushed_sand < getLimit('crushed_sand', 'lower') || d.data.crushed_sand > getLimit('crushed_sand', 'upper')),
                            crushed_sand_methylene: d.data.crushed_sand_methylene && (d.data.crushed_sand_methylene < getLimit('crushed_sand_methylene', 'lower') || d.data.crushed_sand_methylene > getLimit('crushed_sand_methylene', 'upper')),
                            mineral_amount: d.data.mineral_amount && (d.data.mineral_amount < getLimit('mineral_amount', 'lower') || d.data.mineral_amount > getLimit('mineral_amount', 'upper')),
                            natural_sand: d.data.natural_sand && (d.data.natural_sand < getLimit('natural_sand', 'lower') || d.data.natural_sand > getLimit('natural_sand', 'upper')),
                            natural_sand_methylene: d.data.natural_sand_methylene && (d.data.natural_sand_methylene < getLimit('natural_sand_methylene', 'lower') || d.data.natural_sand_methylene > getLimit('natural_sand_methylene', 'upper')),
                            theoretical_water: d.data.theoretical_water && (d.data.theoretical_water < getLimit('theoretical_water', 'lower') || d.data.theoretical_water > getLimit('theoretical_water', 'upper')),
                            weather_temp: d.data.weather_temp && (d.data.weather_temp < getLimit('weather_temp', 'lower') || d.data.weather_temp > getLimit('weather_temp', 'upper')),
                            concrete_temp: d.data.concrete_temp && (d.data.concrete_temp < getLimit('concrete_temp', 'lower') || d.data.concrete_temp > getLimit('concrete_temp', 'upper')),
                            slump: d.data.slump && (d.data.slump < getLimit('slump', 'lower') || d.data.slump > getLimit('slump', 'upper')),
                            flow_diameter: d.data.flow_diameter && (d.data.flow_diameter < getLimit('flow_diameter', 'lower') || d.data.flow_diameter > getLimit('flow_diameter', 'upper')),
                            fresh_concrete_density: d.data.fresh_concrete_density && (d.data.fresh_concrete_density < getLimit('fresh_concrete_density', 'lower') || d.data.fresh_concrete_density > getLimit('fresh_concrete_density', 'upper')),
                            air_percent: d.data.air_percent && (d.data.air_percent < getLimit('air_percent', 'lower') || d.data.air_percent > getLimit('air_percent', 'upper')),
                        });

                }
                else {
                    toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('newSample.fetchLastError') })
                }
            }).catch(e => {
                setLoading(false);
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('newSample.fetchLastError') });
            });
        }

    }, [inputs.plant_id, inputs.class_id, inputs.class_design_id, toast]);

    const changeInput = (key, e) => {
        if (key.indexOf('_id') === -1 && key !== 'sample_no' && key !== 'clor_content' && key !== 'environmental_effect' && key !== 'statement') {
            if (getLimit(key, 'lower') !== null || getLimit(key, 'upper') !== null)
                setErrors(prevState => { return { ...prevState, [key]: e.value !== null && e.value !== undefined && (e.value < getLimit(key, 'lower') || e.value > getLimit(key, 'upper')) } });
        }
        setInputs(prevState => { return { ...prevState, [key]: e.value } });
    }

    const checkChanges = (key) => {
        if (Object.entries(oldInputs).length !== 0 && (oldInputs[key] !== null && oldInputs[key] !== undefined)) {
            return oldInputs[key] !== inputs[key];
        }
        else if (inputs[key] !== null && inputs[key] !== undefined)
            return true;
        else
            return false;
    }

    const checkDropdowns = (key) => {
        if (Object.entries(oldInputs).length !== 0 && oldInputs[key]) {
            return inputs[key] !== oldInputs[key];
        }
        else if (!oldInputs[key] && inputs[key])
            return true;
        else
            return false;
    }

    const save = () => {
        toast.clear();
        if (!validRequired()) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('manuelPredict.reqFields') });
            logEvent("[New Sample] Required Fields");
            return;
        }
        const err = Object.keys(errors).find(k => errors[k]);
        if (err) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('limit.warn') });
            logEvent("[New Sample] Limit Error");
            return;
        }
        setLoading(true);
        inputs.date = moment(date).format('YYYY-MM-DD');
        const customer = inputs.customer_id;
        if (customer && !parseInt(customer)) {
            const exists = customers.find(x => x.label.toLowerCase() === customer.toLowerCase())
            if (exists) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('newSample.customerError'), sticky: true });
                return
            }
        }

        logEvent("[New Sample] Save");

        newSample(inputs).then(d => {
            setLoading(false);
            setSuccess(d.success);
            if (d.already) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('newSample.already'), sticky: true });
                return;
            }

            if (d.not_valid) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('newSample.notValid'), sticky: true });
                return;
            }

            if (d.success) {
                toast.show({ severity: 'success', summary: getLabel('info'), detail: getLabel('dataSuccess') });
                setSampleId(d.id);
                getSampleNo(inputs.plant_id, date.getFullYear()).then(d => {
                    setOriginSampleNo(d?.data?.sample_no || null);
                    setOldInputs({});
                    setInputs({
                        plant_id: inputs.plant_id,
                        sample_no: d?.data?.sample_no + 1,
                        clor_content: "",
                        environmental_effect: "",
                        statement: ""
                    });
                    setErrors({});
                    setLoading(false);
                });
            }
            else {
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('newSample.saveError') })
            }
        }).catch(e => {
            logEvent("[New Sample] Error on Save");

            setLoading(false);
            toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('newSample.saveError') })
        }
        );

    }

    const validRequired = () => {
        if (!date || !inputs.plant_id || !inputs.class_id
            || !inputs.cement_id || !inputs.cement_amount || !inputs.theoretical_water
            || !inputs.class_design_id || !inputs.weather_temp || !inputs.concrete_temp
            || !inputs.fresh_concrete_density || !inputs.sample_type_id) {
            setShowRequired(true);
            return false;
        }

        if ((!inputs.additive_one_id || !inputs.add_one_amount) &&
            (!inputs.additive_two_id || !inputs.add_two_amount)) {
            setShowRequired(true);
            return false;
        }

        if (!inputs.slump && !inputs.flow_diameter) {
            setShowRequired(true);
            return false;
        }

        let additionalReq = true;
        additionalReqFields.forEach(d => {
            if (inputs[d] === null || inputs[d] === undefined) {
                additionalReq = false;
                setShowRequired(true);
                return false;
            }
        })
        if (additionalReq)
            setShowRequired(false);
        return additionalReq;

    }

    const checkShowRequired = (key) => {
        if (showRequired) {
            const field = additionalReqFields.find(f => f === key);
            return field !== undefined;
        }
        else {
            return false;
        }
    }

    const selectBoxChanged = (key) => {
        return checkDropdowns(key) ? "select-box-changed"
            : ['plant_id', 'cement_id', 'class_id', 'sample_type_id', 'class_design_id'].includes(key) && !inputs[key] && showRequired ? "p-invalid" : "";
    }

    const classDesign = classDesigns.filter(f => f.class_id === inputs.class_id).map(i => { return { label: i.name, value: i.id } });

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }

            {!pageLoad && (
                <div>
                    {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
                    <div className="p-grid p-fluid">

                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-4">
                                    <span className="p-float-label">
                                        <Calendar className={showRequired && date === null ? "p-invalid" : ''} id='date' showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={date} onChange={(e) => setDate(e.value)}></Calendar>
                                        <label htmlFor="date">{getLabel('date')}</label>
                                    </span>
                                </div>

                                <div className="p-col-12 p-md-4">
                                    <span className="p-float-label">
                                        <Dropdown className={showRequired && !inputs.plant_id ? "p-invalid" : ''} id="plant" showClear style={{ width: '100%' }} value={inputs.plant_id} options={plants} onChange={(e) => changeInput('plant_id', e)} />
                                        <label htmlFor="plant">{getLabel('plant')}</label>
                                    </span>
                                </div>

                                <MyInputNumber disabled={true} customClass="p-col-12 p-md-4" htmlFor={getLabel("newSample.lastSampleNo")} id="sample_no"
                                    value={originSampleNo} validationLimit={false} />
                            </div>
                        </div>

                        <div className="p-col-12">

                            <div className="p-grid">

                                <Panel header={getLabel('newSample.generalHeader')} className="p-col-12" toggleable collapsed={panels.general} onToggle={(e) => setPanel(prevState => { return { ...prevState, general: e.value } })}>
                                    <div className="p-grid">

                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('sample_no')} customClass="p-col-12 p-md-2" htmlFor="sample_no" id="sample_no" value={inputs.sample_no} change={changeInput} validationLimit={false} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("class_id")} id="class" showClear style={{ width: '100%' }} value={inputs.class_id} options={classes} onChange={(e) => changeInput('class_id', e)} />
                                                <label htmlFor="class">{getLabel('class')}</label>
                                            </span>
                                        </div>

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown disabled={loading} id="class_design_id" className={selectBoxChanged('class_design_id')} showClear
                                                    style={{ width: '100%' }} value={inputs.class_design_id} options={classDesign}
                                                    onChange={(e) => changeInput('class_design_id', e)} />
                                                <label htmlFor="class_design">{getLabel('class_design')}</label>
                                            </span>
                                        </div>

                                        <MyInputText required={checkShowRequired('waybill_number')}
                                            checkChanges={checkChanges('waybill_number')}
                                            customClass="p-col-12 p-md-2" htmlFor="waybill_number"
                                            id="waybill_number" value={inputs.waybill_number}
                                            change={(key, e) => { setInputs(prevState => { return { ...prevState, [key]: e.target.value } }) }} />


                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("customer_id")} filter id="customer" showClear style={{ width: '100%' }} value={inputs.customer_id} options={customers} onChange={(e) => changeInput('customer_id', e)} emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    editable />
                                                <label htmlFor="customer">{getLabel('customer')}</label>
                                            </span>
                                        </div>

                                        <MyInputText checkChanges={checkChanges('environmental_effect')} customClass="p-col-12 p-md-2" htmlFor="environment_affect" id="environmental_effect"
                                            value={inputs.environmental_effect} change={(key, e) => { setInputs(prevState => { return { ...prevState, [key]: e.target.value } }) }} />

                                        <MyInputText checkChanges={checkChanges('clor_content')} customClass="p-col-12 p-md-2" htmlFor="clor" id="clor_content"
                                            value={inputs.clor_content} change={(key, e) => { setInputs(prevState => { return { ...prevState, [key]: e.target.value } }) }} />

                                    </div>
                                </Panel>

                                <Panel header={getLabel('manuelPredict.recipeHeader')} className="p-col-12" toggleable collapsed={panels.recipe} onToggle={(e) => setPanel(prevState => { return { ...prevState, recipe: e.value } })}>
                                    <div className="p-grid">

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown
                                                    className={selectBoxChanged("cement_id")}
                                                    id="cement" showClear style={{ width: '100%' }} value={inputs.cement_id} options={cements} onChange={(e) => { if (!e.value) changeInput('cement_amount', { value: null }); changeInput('cement_id', e) }} />
                                                <label htmlFor="cement">{getLabel('cement')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('cement_amount')}
                                            customClass="p-col-12 p-md-2" htmlFor="cement_val" id="cement_amount" step={5} suffix=" kg"
                                            value={inputs.cement_amount} change={changeInput} />


                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("alternative_cement_id")} id="alternative_cement_id" showClear style={{ width: '100%' }} value={inputs.alternative_cement_id} options={alternateCements} onChange={(e) => { if (!e.value) changeInput('alternative_cement_amount', { value: null }); changeInput('alternative_cement_id', e) }} />
                                                <label htmlFor="alter_cement">{getLabel('alternate_cement')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber
                                            required={checkShowRequired('alternative_cement_amount')}
                                            checkChanges={checkChanges('alternative_cement_amount')} customClass="p-col-12 p-md-2" htmlFor="alternate_cement_val" id="alternative_cement_amount" step={5} suffix=" kg"
                                            value={inputs.alternative_cement_amount} change={changeInput} />


                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("mineral_id")} id="mineral" showClear
                                                    style={{ width: '100%' }} value={inputs.mineral_id} options={minerals}
                                                    onChange={(e) => {
                                                        if (!e.value) changeInput('mineral_amount', { value: null });
                                                        changeInput('mineral_id', e)
                                                    }} />
                                                <label htmlFor="mineral">{getLabel('mineral')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber required={checkShowRequired('mineral_amount')} checkChanges={checkChanges('mineral_amount')} customClass="p-col-12 p-md-2" htmlFor="mineral_val" id="mineral_amount" step={5} suffix=" kg"
                                            value={inputs.mineral_amount} change={changeInput} />

                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('theoretical_water')} customClass="p-col-12 p-md-2" htmlFor="theorical_water" id="theoretical_water" step={1} suffix=" kg" value={inputs.theoretical_water} change={changeInput} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("additive_one_id")}
                                                    id="add_1" showClear style={{ width: '100%' }} value={inputs.additive_one_id}
                                                    options={additiveOne}
                                                    onChange={(e) => {
                                                        if (!e.value) changeInput('add_one_amount', { value: null });
                                                        changeInput('additive_one_id', e)
                                                    }} />
                                                <label htmlFor="add_1">{getLabel('additive_one')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber add_prefix={true} checkChanges={checkChanges('add_one_amount')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="additive_one_val" id="add_one_amount" step={0.1} suffix={LocalStorage.get("additive_unit")}
                                            value={inputs.add_one_amount} change={changeInput} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("additive_two_id")}
                                                    id="additive_two_id" showClear style={{ width: '100%' }}
                                                    value={inputs.additive_two_id} options={additiveTwo}
                                                    onChange={(e) => {
                                                        if (!e.value) changeInput('add_two_amount', { value: null });
                                                        changeInput('additive_two_id', e)
                                                    }} />
                                                <label htmlFor="add_2">{getLabel('additive_two')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber add_prefix={true} checkChanges={checkChanges('add_two_amount')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="additive_two_val" id="add_two_amount" step={0.1} suffix={LocalStorage.get("additive_unit")} value={inputs.add_two_amount} change={changeInput} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("natural_sand_source_id")} id="natural_sand_source_id" showClear style={{ width: '100%' }} value={inputs.natural_sand_source_id} options={naturalSandSources} onChange={(e) => {
                                                    if (!e.value) changeInput('natural_sand', { value: null });
                                                    changeInput('natural_sand_source_id', e);
                                                }} />
                                                <label htmlFor="natural_sand_source">{getLabel('natural_sand_source')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('natural_sand')} customClass="p-col-12 p-md-2" htmlFor="natural_sand" id="natural_sand" step={1} suffix=" kg" value={inputs.natural_sand} change={changeInput} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("crushed_sand_source_id")} id="crushed_sand_source_id" showClear style={{ width: '100%' }} value={inputs.crushed_sand_source_id} options={crushedSandSources} onChange={(e) => {
                                                    if (!e.value) changeInput('crushed_sand', { value: null });
                                                    changeInput('crushed_sand_source_id', e)
                                                }} />
                                                <label htmlFor="crushed_sand_source">{getLabel('crushed_sand_source')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber checkChanges={checkChanges('crushed_sand')} customClass="p-col-12 p-md-2" htmlFor="crushed_sand" id="crushed_sand" step={1} suffix=" kg" value={inputs.crushed_sand} change={changeInput} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("agg_no_one_source_id")} id="agg_no_one_source_id" showClear style={{ width: '100%' }} value={inputs.agg_no_one_source_id} options={aggNoOneSources} onChange={(e) => {
                                                    if (!e.value) changeInput('agg_no_one', { value: null });
                                                    changeInput('agg_no_one_source_id', e)
                                                }} />
                                                <label htmlFor="no_1_source">{getLabel('no_1_source')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber checkChanges={checkChanges('agg_no_one')} customClass="p-col-12 p-md-2" htmlFor="no_1" id="agg_no_one" step={1} suffix=" kg" value={inputs.agg_no_one} change={changeInput} />

                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("agg_no_two_source_id")} id="agg_no_two_source_id" showClear style={{ width: '100%' }} value={inputs.agg_no_two_source_id} options={aggNoTwoSources} onChange={(e) => {
                                                    if (!e.value) changeInput('agg_no_two', { value: null });
                                                    changeInput('agg_no_two_source_id', e);
                                                }} />
                                                <label htmlFor="no_2_source">{getLabel('no_2_source')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber checkChanges={checkChanges('agg_no_two')} customClass="p-col-12 p-md-2" htmlFor="no_2" id="agg_no_two" step={1} suffix=" kg" value={inputs.agg_no_two} change={changeInput} />

                                        <MyInputNumber required={checkShowRequired('natural_sand_system_moisture')} checkChanges={checkChanges('natural_sand_system_moisture')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="natural_sand_system_moisture" id="natural_sand_system_moisture" step={0.1} value={inputs.natural_sand_system_moisture} change={changeInput} />

                                        <MyInputNumber required={checkShowRequired('crushed_sand_system_moisture')} checkChanges={checkChanges('crushed_sand_system_moisture')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="crushed_sand_system_moisture" id="crushed_sand_system_moisture" step={0.1} value={inputs.crushed_sand_system_moisture} change={changeInput} />

                                        <MyInputNumber required={checkShowRequired('agg_no_one_system_moisture')} checkChanges={checkChanges('agg_no_one_system_moisture')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="agg_no_one_system_moisture" id="agg_no_one_system_moisture" step={0.1} value={inputs.agg_no_one_system_moisture} change={changeInput} />

                                        <MyInputNumber required={checkShowRequired('agg_no_two_system_moisture')} checkChanges={checkChanges('agg_no_two_system_moisture')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="agg_no_two_system_moisture" id="agg_no_two_system_moisture" step={0.1} value={inputs.agg_no_two_system_moisture} change={changeInput} />

                                    </div>
                                </Panel>
                                {
                                    otherFields && otherFields.length > 0 && (
                                        <Panel header={getLabel('newSample.otherInfo')} className="p-col-12" toggleable collapsed={panels.other} onToggle={(e) => setPanel(prevState => { return { ...prevState, other: e.value } })}>
                                            <div className="p-grid">
                                                {otherFields.map(field => (
                                                    [
                                                        field.field_type === 'FLOAT' && (
                                                            <MyInputNumber required={checkShowRequired(field.code)} key={field.id} customHeader={getAdditionalColumnDesc(field)} checkChanges={checkChanges(field.code)} customClass="p-col-12 p-md-2" minFractionDigits={2} id={field.code} step={0.1} value={inputs[field.code]} change={changeInput} validationLimit={false} />
                                                        ),

                                                        field.field_type === 'STRING' && (
                                                            <MyInputText required={checkShowRequired(field.code)} key={field.id} customHeader={getAdditionalColumnDesc(field)} checkChanges={checkChanges(field.code)} customClass="p-col-12 p-md-2" id={field.code} value={inputs[field.code]} change={(key, e) => { setInputs(prevState => { return { ...prevState, [key]: e.target.value } }) }} />
                                                        )
                                                    ]
                                                ))}
                                            </div>
                                        </Panel>
                                    )
                                }

                                <Panel header={getLabel('newSample.inputMaterialMeasurements')} className="p-col-12" toggleable collapsed={panels.measure} onToggle={(e) => setPanel(prevState => { return { ...prevState, measure: e.value } })}>
                                    <div className="p-grid">
                                        <MyInputNumber required={checkShowRequired('natural_sand_methylene')} checkChanges={checkChanges('natural_sand_methylene')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="natural_sand_methylene" id="natural_sand_methylene" step={0.1} value={inputs.natural_sand_methylene} change={changeInput} />

                                        <MyInputNumber required={checkShowRequired('crushed_sand_methylene')} checkChanges={checkChanges('crushed_sand_methylene')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="crushed_sand_methylene" id="crushed_sand_methylene" step={0.1} value={inputs.crushed_sand_methylene} change={changeInput} />
                                    </div>
                                </Panel>

                                <Panel header={getLabel('manuelPredict.freshConcreteHeader')} className="p-col-12" toggleable collapsed={panels.fresh} onToggle={(e) => setPanel(prevState => { return { ...prevState, fresh: e.value } })}>
                                    <div className="p-grid">
                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('weather_temp')} customClass="p-col-12 p-md-2" htmlFor="temp" id="weather_temp" minFractionDigits={2} step={1} suffix=" ℃"
                                            value={inputs.weather_temp} change={changeInput} />

                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('concrete_temp')} customClass="p-col-12 p-md-2" htmlFor="concrete_temp" id="concrete_temp" minFractionDigits={2} step={1} suffix=" ℃"
                                            value={inputs.concrete_temp} change={changeInput} />

                                        <MyInputNumber checkChanges={checkChanges('slump')} customClass="p-col-12 p-md-2" htmlFor="slump" id="slump" step={10} suffix=" mm"
                                            value={inputs.slump} change={changeInput} />

                                        <MyInputNumber checkChanges={checkChanges('flow_diameter')} customClass="p-col-12 p-md-2" htmlFor="flow_diameter" id="flow_diameter" step={10} suffix=" mm"
                                            value={inputs.flow_diameter} change={changeInput} />


                                        <div className="p-col-12 p-md-2">
                                            <span className="p-float-label">
                                                <Dropdown className={selectBoxChanged("sample_type_id")} id="sample_type_id" showClear style={{ width: '100%' }} value={inputs.sample_type_id} options={sampleTypes} onChange={(e) => changeInput('sample_type_id', e)} />
                                                <label htmlFor="sample_type">{getLabel('sample_type')}</label>
                                            </span>
                                        </div>

                                        <MyInputNumber required={showRequired} checkChanges={checkChanges('fresh_concrete_density')} customClass="p-col-12 p-md-2" htmlFor="unit_weight" id="fresh_concrete_density" step={1} suffix=" kg/m3"
                                            value={inputs.fresh_concrete_density} change={changeInput} />

                                        <MyInputNumber required={checkShowRequired('air_percent')} checkChanges={checkChanges('air_percent')} customClass="p-col-12 p-md-2" minFractionDigits={2} htmlFor="air_percent" id="air_percent" step={0.1} prefix="%"
                                            value={inputs.air_percent} change={changeInput} />

                                        <Message severity="warn" text={getLabel('newSample.slumpWarn')} style={{ width: '100%' }}></Message>

                                    </div>
                                </Panel>

                                <Panel header={getLabel('notes')} className="p-col-12" toggleable collapsed={panels.note} onToggle={(e) => setPanel(prevState => { return { ...prevState, note: e.value } })}>
                                    <MyInputTextArea rows={5} cols={30} customClass="p-col-12" autoResize={true} value={inputs.statement} id="statement" change={(key, e) => { setInputs(prevState => { return { ...prevState, [key]: e.target.value } }) }} />

                                </Panel>
                            </div>

                        </div>

                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2 p-lg-1 p-xl-1">
                                    <Button onClick={() => save()} label={getLabel('save')} className='p-button-raised' />
                                </div>

                                {success && LocalStorage.get('org') !== 'DURABLE' && (
                                    <div className="p-col-12 p-md-8">
                                        <Message severity="success" text={(<a href={`pdf/${LocalStorage.get('org')}/${sampleId}`} target="_blank" rel="noreferrer">{getLabel('newSample.saveSuccess')}</a>)} style={{ width: '100%' }}></Message>
                                    </div>
                                )}

                            </div>

                        </div>

                    </div>
                    {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: 10 }} />)}
                    <Toast ref={(el) => setToast(el)} />
                </div>
            )}
        </div>
    )
};


export default withTranslation()(NewSample);