import React from "react";
import Anime from 'react-animejs-wrapper'
import { withTranslation } from "react-i18next";

const MyLoader = ({ duration = 2500 }) => {
  return (
    <div className="p-dialog-mask p-component-overlay p-dialog-visible p-dialog-center" style={{ zIndex: 1004 }}>
      <Anime
        style={{
          width: '150px',
        }}
        config={{
          translateY: [-1000, 100],
          direction: 'alternate',
          // translateX: [-1000, 0],
          scale: [0, 3],
          loop: true,
          duration: duration,
          rotate: [270, 360]

        }}
      >
        <img src="./optimix-logo-black.png" alt="run" style={{ width: '150px' }} />
      </Anime>
    </div>
  );
};

export default withTranslation()(MyLoader);