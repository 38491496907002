import {
    get_sample_no, new_sample,
    get_plant_class_sample, get_samples, update_samples,
    get_samples_for_update, sample_detail
} from '../api/sample';


export const getSampleNo = (plantId, year) =>
    new Promise(function (resolve, reject) {
        get_sample_no(plantId, year)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });


export const newSample = (sample) =>
    new Promise(function (resolve, reject) {
        new_sample(sample)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });

export const getPlantClassSample = (plantId, classId, classDesignId) =>
    new Promise(function (resolve, reject) {
        get_plant_class_sample(plantId, classId, classDesignId)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const getSamples = (date, plantId) =>
    new Promise(function (resolve, reject) {
        get_samples(date, plantId)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const updateSamples = (samples) =>
    new Promise(function (resolve, reject) {
        update_samples(samples)
            .then(data => resolve(data))
            .catch(error => reject(error));
    });


export const getSamplesForUpdate = (search) =>
    new Promise(function (resolve, reject) {
        get_samples_for_update(search)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const sampleDetail = (id) =>
    new Promise(function (resolve, reject) {
        sample_detail(id)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });