import { InputNumber } from 'primereact/inputnumber';
import { withTranslation } from 'react-i18next';
import { getLimit } from '../../helpers/limit';
import { getLabel } from '../../helpers/translator';

const MyInputNumber = ({ disabled, customClass, htmlFor, step, prefix, suffix, value, id, checkChanges, change, minFractionDigits = 0, validationLimit = true, required = false, add_prefix = false, customHeader = "" }) => {

    const showLimit = () => {
        return validationLimit && value !== null && value !== undefined && (value < getLimit(id, 'lower') || value > getLimit(id, 'upper'));
    }

    const _className = checkChanges && value ? 'input-changed' : required && !value ? 'p-invalid' : '';

    return (
        <div className={customClass}>
            <span className="p-float-label">
                <InputNumber disabled={disabled} step={step} prefix={prefix} suffix={suffix}
                    useGrouping={false} minFractionDigits={minFractionDigits}
                    id={id} className={_className}
                    value={value} style={{ width: '100%' }}
                    onChange={(e) => change(id, e)} />
                {customHeader === "" && (
                    <label htmlFor={id}>{getLabel(htmlFor)}{add_prefix && (prefix)}</label>
                )}

                {customHeader !== "" && (
                    <label htmlFor={id}>{customHeader}</label>
                )}

            </span>

            {
                showLimit() && (
                    <small className="p-error p-d-block">{getLimit(id, 'lower')} - {getLimit(id, 'upper')} {getLabel('limit.info')}</small>
                )
            }
        </div>
    )
};

export default withTranslation()(MyInputNumber);