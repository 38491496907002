import axios from 'axios';
import config from './core/config';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    authUser: (username, password) =>
        axios
            .post(`${config.baseUrl}/login`, { username, password })
            .then(response => {
                if (response.data && response.data.status === true) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
                }
                return response.data;
            })
            .catch(err => {
                throw err.message;
            }),
};