
import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import { uiCheck } from '../../../actions/user';
import { getLabel } from '../../../helpers/translator';


const GeneralReport = ({ history, match }) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const [toast, setToast] = useState({});

  useEffect(() => {
    uiCheck('physical-test-general-report').then(d => { setLoading(false); setAuth(d.auth); }).catch(e => { setLoading(false); setAuth(false); });
  }, []);


  return (
    <div>
      {
        loading && (
          <ProgressSpinner style={{ left: "50%" }} />
        )
      }
      {
        !loading && (
          <div>
            <Header history={history} />
            {
              !auth && (
                <div className="p-grid">
                  <div className="p-col-12">
                    <Message severity="error" text={getLabel('permission')}></Message>
                  </div>
                </div>
              )
            }
            <Toast ref={(el) => setToast(el)} />
            <Footer />
          </div>
        )
      }

    </div>
  );
};

export default withTranslation()(GeneralReport);