import {
    last_manuel_predict, manuel_predict, get_daily_predict,
    run_predict, manuel_predict_cost
} from '../api/predict';

export const lastManuelPredict = (plantId, classId, classDesignId) =>
    new Promise(function (resolve, reject) {
        last_manuel_predict(plantId, classId, classDesignId)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });


export const manuelPredict = (predict) =>
    new Promise(function (resolve, reject) {
        manuel_predict(predict)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const dailyPredict = (plantId) =>
    new Promise(function (resolve, reject) {
        get_daily_predict(plantId).then(data => resolve(data)).catch(error => reject(error));
    });

export const runPredict = (predict) =>
    new Promise(function (resolve, reject) {
        run_predict(predict)
            .then(data => {
                resolve(data);
            })
            .catch(error => reject(error));
    });

export const manuelPredictCost = (predictId) =>
    new Promise(function (resolve, reject) {
        manuel_predict_cost(predictId)
            .then(data => {
                if (data.success)
                    resolve(data);
                else
                    reject(data);
            })
            .catch(error => reject(error));
    });