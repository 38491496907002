import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { sampleDetail } from '../../actions/sample';
import { uiCheck } from '../../actions/user';
import { logEvent } from '../../helpers/amplitude';

const Gumustas = ({ history, match }) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [sampleId] = useState(match?.params?.id);
    const [sample, setSample] = useState(null);

    useEffect(() => {
        logEvent("[GUMUSTAS PDF] View");

        uiCheck('pdf').then(d => {
            sampleDetail(sampleId).then(d => {
                if (d.success) {
                    setLoading(false);
                    setSample(d.data);
                    setTimeout(() => window.print(), 1000);
                }
            }).catch(err => {
                setLoading(false);
            });
        }).catch(e => { setLoading(false) });
    }, [sampleId]);

    const waterConcrete = () => {
        let total_binder = 0
        if (sample?.mineral?.name === 'CÜRUF')
            total_binder = (sample.cement_amount + sample.mineral_amount) + sample.alternative_cement_amount || 0;
        else if (sample?.mineral?.name === 'U. KÜL')
            total_binder = (sample.cement_amount + (0.4 * sample.mineral_amount)) + sample.alternative_cement_amount || 0;
        else
            total_binder = sample.cement_amount + sample.alternative_cement_amount || 0;

        return (sample.theoretical_water / total_binder)?.toFixed(2);

    }

    const additive = () => {
        let mineralEqualCement = 0
        if (sample?.mineral?.name === 'CÜRUF')
            mineralEqualCement = sample.mineral_amount
        else if (sample?.mineral?.name === 'U. KÜL')
            mineralEqualCement = 0.4 * sample.mineral_amount;

        return (((sample.cement_amount + mineralEqualCement) + sample.alternative_cement_amount || 0) * (sample.add_one_amount + sample.add_two_amount) / 100)?.toFixed(2);
    }

    const theoricalTotal = () => {
        return (sample.cement_amount + sample.mineral_amount + sample.theoretical_water + parseFloat(additive())
            + sample.natural_sand * (1 - sample.natural_sand_system_moisture / 100)
            + sample.crushed_sand * (1 - sample.crushed_sand_system_moisture / 100)
            + sample.agg_no_one * (1 - sample.agg_no_one_system_moisture / 100)
            + sample.agg_no_two * (1 - sample.agg_no_two_system_moisture / 100))?.toFixed(2);
    }

    const realTotal = () => {
        return (sample.cement_amount + sample.mineral_amount
            + (sample.theoretical_water
                - (((sample.natural_sand * sample.natural_sand_system_moisture / 100) / (1 - sample.natural_sand_system_moisture / 100))
                    + ((sample.crushed_sand * sample.crushed_sand_system_moisture / 100) / (1 - sample.crushed_sand_system_moisture / 100))
                    + ((sample.agg_no_one * sample.agg_no_one_system_moisture / 100) / (1 - sample.agg_no_one_system_moisture / 100))
                    + ((sample.agg_no_two * sample.agg_no_two_system_moisture / 100) / (1 - sample.agg_no_two_system_moisture / 100))
                )) + parseFloat(additive()) + sample.natural_sand
            + sample.crushed_sand + sample.agg_no_one + sample.agg_no_two)?.toFixed(2);
    }

    const sampleArea = () => {
        if (sample?.sample_type?.name === "15X15X15 KÜP")
            return 15 * 15;
        else if (sample?.sample_type?.name === "10X20 SİLİNDİR")
            return (Math.PI * Math.pow(5, 2))?.toFixed(2);
        else
            return ""
    }

    const unitWeight = (weight) => {
        if (sample?.sample_type?.name === "15X15X15 KÜP")
            return ((weight / (15 * 15 * 15)) * 1000)?.toFixed(2);
        else if (sample?.sample_type?.name === "10X20 SİLİNDİR")
            return ((weight / (Math.PI * Math.pow(5, 2) * 20)) * 1000)?.toFixed(2);
        else
            return ""
    }

    return (
        <div>
            {
                loading && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }

            {
                !loading && !sample && (
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Message severity="error" text={t('pdf.loadError')}></Message>
                        </div>
                    </div>
                )
            }

            {!loading && sample && (
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <table border="1" className="pdf-table">
                            <thead>
                                <tr>
                                    <th style={{ width: 190 }}>
                                        <img src="/gumustas.png" alt='gumustas' />
                                    </th>

                                    <th colSpan="10" style={{ width: 400 }}>
                                        <div className="p-text-center p-text-bold">
                                            <label>TAZE VE SERTLEŞMİŞ BETON DENEYLERİ</label>
                                        </div>
                                        <div className="p-text-center p-text-bold">
                                            <label>BETON NUMUNE VE DENEY FORMU</label>
                                        </div>
                                        <div className="p-text-center p-text-bold">
                                            <label>(TS EN 12350-1  Nisan 2002)</label>
                                        </div>
                                    </th>

                                    <th style={{ width: 190 }}>
                                        <img src="/tse.png" alt='tse' />
                                    </th>
                                </tr>

                            </thead>

                            <tbody>
                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.concreteClass')}
                                    </td>
                                    <td colSpan="2">
                                        {sample?.classes?.name}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.concreteCode')}
                                    </td>
                                    <td colSpan="2">
                                        {sample?.classes?.name}/.......
                                    </td>

                                    <td colSpan="2">
                                        {t('date')}
                                    </td>
                                    <td colSpan="2">
                                        {moment(sample.date).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.customer')}
                                    </td>
                                    <td colSpan="8" className="p-text-left">
                                        {sample?.customer?.name}
                                    </td>

                                    <td >
                                        {t('pdf.invoiceNo')}
                                    </td>

                                    <td>

                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('sample_no')}
                                    </td>
                                    <td>
                                        {sample?.sample_no}
                                    </td>

                                    <td colSpan="1">
                                        {t('pdf.fromInfo')}
                                    </td>

                                    <td colSpan="6">

                                    </td>

                                    <td>
                                        {t('pdf.created_at')}
                                    </td>
                                    <td colSpan="2">
                                        {moment(sample.created_at).format(i18n.language === 'en' ? "MM-DD-YYYY HH:mm:ss" : "DD-MM-YYYY HH:mm:ss")}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('temp')}( ℃)
                                    </td>

                                    <td colSpan="2">
                                        {sample?.weather_temp}
                                    </td>

                                    <td colSpan="2">
                                        {t('concrete_temp')}
                                    </td>

                                    <td colSpan="2">
                                        {sample?.concrete_temp}
                                    </td>

                                    <td colSpan="2">
                                        {t('plant')}
                                    </td>

                                    <td colSpan="2">
                                        {sample?.plant?.name}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.slump')}( ℃)
                                    </td>

                                    <td colSpan="2">
                                        {sample?.slump}
                                    </td>

                                    <td>
                                        {t('pdf.consistencyClass')}
                                    </td>

                                    <td>
                                        {sample.slump <= 45 && ("S1")}
                                        {sample.slump > 45 && sample.slump <= 95 && ("S2")}
                                        {sample.slump > 95 && sample.slump <= 155 && ("S3")}
                                        {sample.slump > 155 && sample.slump <= 215 && ("S4")}
                                        {sample.slump > 215 && ("S5")}
                                    </td>

                                    <td colSpan="2">
                                        <label style={{ fontSize: 20 }}>D</label>
                                        <label style={{ fontSize: 10 }}>ençok</label>
                                    </td>

                                    <td colSpan="2">
                                        22.4
                                    </td>

                                    <td>
                                        {t('pdf.view')}
                                    </td>

                                    <td>

                                    </td>
                                </tr>

                                <tr>
                                    <td className="p-text-bold" colSpan="2">
                                        {t('pdf.freshConcreteUnit')}
                                    </td>

                                    <td colSpan="3">
                                        {t('pdf.containerConcrete')}
                                    </td>

                                    <td colSpan="3">
                                        {t('pdf.containerTare')}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.concreteWeight')}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.unitWeight')}
                                    </td>

                                </tr>

                                <tr>
                                    <td className="p-text-bold" colSpan="2">
                                        {t('pdf.freshWeightDesc')}
                                    </td>
                                    <td colSpan="3">
                                        {t('pdf.containerConcreteDesc')}
                                    </td>

                                    <td colSpan="3">
                                        {t('pdf.gram')}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.gram')}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.kgM3')}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.ts12350-6')}
                                    </td>
                                    <td colSpan="3">
                                    </td>

                                    <td colSpan="3">
                                    </td>

                                    <td colSpan="2">
                                    </td>

                                    <td colSpan="2">
                                        {sample.fresh_concrete_density}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.clorClass')}
                                    </td>
                                    <td colSpan="3">
                                        {t('pdf.strengthSpeed')}
                                    </td>

                                    <td colSpan="3">
                                        {t('pdf.envClass')}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.waterConcrete')}
                                    </td>

                                    <td colSpan="2">
                                        {t('air_percent')}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {sample.clor_content}
                                    </td>

                                    <td colSpan="3">
                                        {sample.day_2_strength && (sample.day_2_strength / sample.day_28_strength)?.toFixed(2)}
                                    </td>

                                    <td colSpan="3">
                                        {sample.environmental_effect}
                                    </td>

                                    <td colSpan="2">

                                        {waterConcrete()}
                                    </td>

                                    <td colSpan="2">
                                        {sample.air_percent}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.compound')}
                                    </td>
                                    <td>
                                        {t('pdf.cement')}
                                    </td>

                                    <td>
                                        {t('pdf.mineral_amount')}
                                    </td>

                                    <td>
                                        {t('pdf.water')}
                                    </td>

                                    <td>
                                        {t('pdf.additive')}
                                    </td>

                                    <td>
                                        {t('pdf.sand')}
                                    </td>

                                    <td>
                                        {t('pdf.crushedSand')}
                                    </td>

                                    <td>
                                        {t('pdf.no1')}
                                    </td>

                                    <td>
                                        {t('pdf.no2')}
                                    </td>

                                    <td colSpan="2">
                                        {t('pdf.total')}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.theoretical')}
                                    </td>
                                    <td>
                                        {sample.cement_amount}
                                    </td>

                                    <td>
                                        {sample.mineral_amount}
                                    </td>

                                    <td>
                                        {sample.theoretical_water}
                                    </td>

                                    <td>
                                        {additive()}
                                    </td>

                                    <td>
                                        {(sample.natural_sand * (1 - sample.natural_sand_system_moisture / 100))?.toFixed(2)}
                                    </td>

                                    <td>
                                        {(sample.crushed_sand * (1 - sample.crushed_sand_system_moisture / 100))?.toFixed(2)}
                                    </td>

                                    <td>
                                        {(sample.agg_no_one * (1 - sample.agg_no_one_system_moisture / 100))?.toFixed(2)}
                                    </td>

                                    <td>
                                        {(sample.agg_no_two * (1 - sample.agg_no_two_system_moisture / 100)).toFixed(2)}
                                    </td>

                                    <td colSpan="2">
                                        {
                                            theoricalTotal()
                                        }
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        {t('pdf.real')}
                                    </td>
                                    <td>
                                        {sample.cement_amount}
                                    </td>

                                    <td>
                                        {sample.mineral_amount}
                                    </td>

                                    <td>
                                        {(sample.theoretical_water
                                            - (((sample.natural_sand * sample.natural_sand_system_moisture / 100) / (1 - sample.natural_sand_system_moisture / 100))
                                                + ((sample.crushed_sand * sample.crushed_sand_system_moisture / 100) / (1 - sample.crushed_sand_system_moisture / 100))
                                                + ((sample.agg_no_one * sample.agg_no_one_system_moisture / 100) / (1 - sample.agg_no_one_system_moisture / 100))
                                                + ((sample.agg_no_two * sample.agg_no_two_system_moisture / 100) / (1 - sample.agg_no_two_system_moisture / 100))))?.toFixed(2)}
                                    </td>

                                    <td>
                                        {additive()}
                                    </td>

                                    <td>
                                        {sample.natural_sand}
                                    </td>

                                    <td>
                                        {sample.crushed_sand}
                                    </td>

                                    <td>
                                        {sample.agg_no_one}
                                    </td>

                                    <td>
                                        {sample.agg_no_two}
                                    </td>

                                    <td colSpan="2">
                                        {realTotal()}
                                    </td>

                                </tr>

                                <tr>
                                    <td colSpan="12">
                                        <div className="p-text-bold" style={{ fontSize: 16 }}>{t('pdf.strengthUnitHeader')}</div>
                                        <div style={{ fontSize: 10 }}>{t('pdf.ts12390-3')}</div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        {t('pdf.sample')}
                                    </td>

                                    <td>
                                        {t('pdf.mould')}
                                    </td>

                                    <td>
                                        {t('pdf.invoice')}
                                    </td>

                                    <td>
                                        <div style={{ width: 80 }}>{t('pdf.test')}</div>
                                    </td>

                                    <td>
                                        {t('pdf.age')}
                                    </td>

                                    <td>
                                        {t('pdf.weight')}
                                    </td>

                                    <td>
                                        {t('pdf.area')}
                                    </td>

                                    <td>
                                        {t('pdf.unitWeight')}
                                    </td>

                                    <td>
                                        {t('pdf.strenghtWeight')}
                                    </td>

                                    <td>
                                        {t('pdf.strenghtWeightTime')}
                                    </td>

                                    <td>
                                        {t('pdf.strength')}
                                    </td>

                                    <td>
                                        {t('pdf.avgStrength')}
                                    </td>

                                </tr>

                                <tr>
                                    <td>
                                        No
                                    </td>

                                    <td>
                                        No
                                    </td>

                                    <td>
                                        No
                                    </td>

                                    <td>
                                        {t('date')}
                                    </td>

                                    <td>
                                        {t('pdf.ageDate')}
                                    </td>

                                    <td>
                                        {t('pdf.gram')}
                                    </td>

                                    <td>
                                        {t('pdf.cm2')}
                                    </td>

                                    <td>
                                        {t('pdf.kgM3')}
                                    </td>

                                    <td>
                                        {t('pdf.strenghtWeightUnit')}
                                    </td>

                                    <td>
                                        {t('pdf.second')}
                                    </td>

                                    <td>
                                        {t('pdf.strengthUnit')}
                                    </td>

                                    <td>
                                        {t('pdf.strengthUnit')}
                                    </td>

                                </tr>

                                {
                                    _.range(1, 11).map(d => (
                                        <tr key={d}>
                                            <td>
                                                {d}
                                            </td>

                                            <td>

                                            </td>

                                            <td>

                                            </td>

                                            <td width='100'>
                                                {d === 1 && moment(sample.date).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                                {d === 3 && moment(sample.date_2_day).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                                {d === 4 && moment(sample.date_2_day).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                                {d === 5 && moment(sample.date_7_day).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                                {d === 6 && moment(sample.date_7_day).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                                {d === 8 && moment(sample.date_28_day).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                                {d === 9 && moment(sample.date_28_day).format(i18n.language === 'en' ? "MM-DD-YYYY" : "DD-MM-YYYY")}
                                            </td>

                                            {
                                                d === 3 && (
                                                    <td rowSpan="2" className="p-text-bold">
                                                        2 {t('day')}
                                                    </td>
                                                )
                                            }

                                            {
                                                d === 5 && (
                                                    <td rowSpan="2" className="p-text-bold">
                                                        7 {t('day')}
                                                    </td>
                                                )
                                            }

                                            {
                                                d === 8 && (
                                                    <td rowSpan="2" className="p-text-bold">
                                                        28 {t('day')}
                                                    </td>
                                                )
                                            }

                                            <td>
                                                {d === 3 && sample.day_2_weight_1}
                                                {d === 4 && sample.day_2_weight_1}
                                                {d === 5 && sample.day_7_weight_1}
                                                {d === 6 && sample.day_7_weight_2}
                                                {d === 8 && sample.day_28_weight_1}
                                                {d === 9 && sample.day_28_weight_2}
                                            </td>

                                            <td>
                                                {d === 3 && sampleArea()}
                                                {d === 4 && sampleArea()}
                                                {d === 5 && sampleArea()}
                                                {d === 6 && sampleArea()}
                                                {d === 8 && sampleArea()}
                                                {d === 9 && sampleArea()}
                                            </td>

                                            <td>
                                                {d === 3 && sample.day_2_strength_1 && (unitWeight(sample.day_2_weight_1))}
                                                {d === 4 && sample.day_2_strength_2 && (unitWeight(sample.day_2_weight_2))}
                                                {d === 5 && sample.day_7_strength_1 && (unitWeight(sample.day_7_weight_1))}
                                                {d === 6 && sample.day_7_strength_2 && (unitWeight(sample.day_7_weight_2))}
                                                {d === 8 && sample.day_28_strength_1 && (unitWeight(sample.day_28_weight_1))}
                                                {d === 9 && sample.day_28_strength_2 && (unitWeight(sample.day_28_weight_2))}
                                            </td>

                                            <td>
                                                {d === 3 && sample.day_2_strength_1 && (sample.day_2_strength_1 * sampleArea() / 10)?.toFixed(2)}
                                                {d === 4 && sample.day_2_strength_2 && (sample.day_2_strength_2 * sampleArea() / 10)?.toFixed(2)}
                                                {d === 5 && sample.day_7_strength_1 && (sample.day_7_strength_1 * sampleArea() / 10)?.toFixed(2)}
                                                {d === 6 && sample.day_7_strength_2 && (sample.day_7_strength_2 * sampleArea() / 10)?.toFixed(2)}
                                                {d === 8 && sample.day_28_strength_1 && (sample.day_28_strength_1 * sampleArea() / 10)?.toFixed(2)}
                                                {d === 9 && sample.day_28_strength_2 && (sample.day_28_strength_2 * sampleArea() / 10)?.toFixed(2)}
                                            </td>

                                            <td>
                                            </td>

                                            <td>
                                                {d === 3 && sample.day_2_strength_1}
                                                {d === 4 && sample.day_2_strength_2}
                                                {d === 5 && sample.day_7_strength_1}
                                                {d === 6 && sample.day_7_strength_2}
                                                {d === 8 && sample.day_28_strength_1}
                                                {d === 9 && sample.day_28_strength_2}

                                            </td>

                                            <td>
                                                {d === 3 && sample.day_2_strength}
                                                {d === 4 && sample.day_2_strength}
                                                {d === 5 && sample.day_7_strength}
                                                {d === 6 && sample.day_7_strength}
                                                {d === 8 && sample.day_28_strength}
                                                {d === 9 && sample.day_28_strength}
                                            </td>

                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td colSpan="4">
                                        {t("pdf.ts12390-6")}
                                    </td>

                                    <td>
                                        {t("pdf.dyk")}
                                    </td>

                                    <td>
                                        {t("pdf.dryWeight")}
                                    </td>

                                    <td>
                                        {t("pdf.waterInConcrete")}
                                    </td>

                                    <td>
                                        {t("pdf.cube")}
                                    </td>

                                    <td>
                                        {t("pdf.dryUnitWeight")}
                                    </td>

                                    <td>
                                        {t("pdf.cleavage")}
                                    </td>

                                    <td>
                                        {t("pdf.strengthWithUnit")}
                                    </td>

                                    <td>
                                        {t("pdf.avgStrengthUnit")}
                                    </td>

                                </tr>

                                {
                                    _.range(11, 15).map(d => (
                                        <tr key={d}>
                                            <td>
                                                {d}
                                            </td>

                                            <td>

                                            </td>

                                            {
                                                d === 11 && (
                                                    <td rowSpan="4" className="p-text-bold">
                                                        28 {t('day')}
                                                    </td>
                                                )
                                            }
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {
                                                d === 11 && (
                                                    <td rowSpan="4">
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }

                                <tr>
                                    <td colSpan="4" rowSpan="2" className="p-text-bold">
                                        {t('pdf.sampleNo')}
                                    </td>
                                    <td colSpan='8' className="p-text-bold p-text-center">
                                        {t('pdf.read')}
                                    </td>

                                </tr>

                                <tr>
                                    {
                                        _.range(1, 7).map(d => (
                                            <td key={d}>{d}</td>
                                        ))
                                    }
                                    <td>{t('pdf.avg')}</td>
                                    <td>{t('pdf.avg')}</td>
                                </tr>

                                {
                                    _.range(1, 4).map(d => (
                                        <tr key={d}>
                                            {d === 1 && (
                                                <td rowSpan="3" colSpan="3">
                                                    7 {t('pdf.daily')}
                                                </td>
                                            )}
                                            <td>{d}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {d === 1 && (<td rowSpan="3"></td>)}

                                        </tr>
                                    ))
                                }

                                {
                                    _.range(1, 4).map(d => (
                                        <tr key={d}>
                                            {d === 1 && (
                                                <td rowSpan="3" colSpan="3">
                                                    28 {t('pdf.daily')}
                                                </td>
                                            )}
                                            <td>{d}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            {d === 1 && (<td rowSpan="3"></td>)}

                                        </tr>
                                    ))
                                }

                                <tr>
                                    <td colSpan="12" className="p-text-left pdf-desc">{t('pdf.desc')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="4" className="p-text-left pdf-footer">{t('pdf.labor')}</td>
                                    <td colSpan="4" className="p-text-left pdf-footer">{t('pdf.check')}</td>
                                    <td colSpan="4" className="p-text-left pdf-footer">{t('pdf.approve')}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            )}
        </div>

    )
};

export default Gumustas;
