import { LocalStorage } from "./storage"

export const getLimit = (key, filter) => {
    const limits = LocalStorage.get('limits');
    if (!limits)
        return null;

    if (key === 'agg_no_two')
        return limits[`no_2_${filter}`];
    if (key === 'agg_no_one')
        return limits[`no_1_${filter}`];

    if (key === 'alternative_cement_amount')
        return limits[`cement_amount_${filter}`];

    if (key === 'add_one_amount' || key === 'add_two_amount')
        return limits[`additive_${filter}`];

    return limits.hasOwnProperty(`${key}_${filter}`) ? limits[`${key}_${filter}`] : null;
}
