import React, { Suspense } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { ProgressSpinner } from 'primereact/progressspinner';
import { isAuthenticated } from '../helpers/authentication';
import ManuelPredict from '../components/ManuelPredict';
import DailyPredict from '../components/DailyPredict';
import UpdateData from '../components/UpdateData';
import Report from '../components/Report';
import Login from '../components/Login';
import NotFound from '../components/NotFound';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfConditions from '../components/TermsOfConditions';
import Data from '../components/Data';
import Profile from '../components/Profile';
import Cost from '../components/Cost';
import Gumustas from '../components/pdf/Gumustas';
import Kent from '../components/pdf/Kent';
import Test from '../components/pdf/Test';
import Deneme from '../components/pdf/Deneme';
import PhysicalTestHome from '../components/physical-tests/PhysicalTestsHome';
import GeneralReport from '../components/physical-tests/reports/GeneralReport';

const history = createHistory();

const router = (
  <Suspense fallback={<ProgressSpinner style={{ left: "50%" }} />}>
    <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
      <Switch>
        <Route path="/login" exact component={Login} />,

        <Route path="/" exact render={(props) => (
          isAuthenticated() ? <ManuelPredict {...props} /> : <Redirect push to="/login" />
        )} />,

        <Route path="/manuel-predict" exact render={(props) => (
          isAuthenticated() ? <ManuelPredict {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/daily-predict" exact render={(props) => (
          isAuthenticated() ? <DailyPredict {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/data" exact render={(props) => (
          isAuthenticated() ? <Data {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/update-data" exact render={(props) => (
          isAuthenticated() ? <UpdateData {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/report" exact render={(props) => (
          isAuthenticated() ? <Report {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/privacy" exact render={(props) => (
          isAuthenticated() ? <PrivacyPolicy {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/terms" exact render={(props) => (
          isAuthenticated() ? <TermsOfConditions {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/profile" exact render={(props) => (
          isAuthenticated() ? <Profile {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/pdf/GUMUSTAS/:id" exact render={(props) => (
          isAuthenticated() ? <Gumustas {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/pdf/KENT/:id" exact render={(props) => (
          isAuthenticated() ? <Kent {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/pdf/TEST/:id" exact render={(props) => (
          isAuthenticated() ? <Test {...props} /> : <Redirect push to="/login" />
        )} />,
        <Route path="/pdf/DENEME/:id" exact render={(props) => (
          isAuthenticated() ? <Deneme {...props} /> : <Redirect push to="/login" />
        )} />

        <Route path="/cost" exact render={(props) => (
          isAuthenticated() ? <Cost {...props} /> : <Redirect push to="/login" />
        )} />,

        <Route path="/physical-test/:testCode" exact render={(props) => (
          isAuthenticated() ? <PhysicalTestHome {...props} /> : <Redirect push to="/login" />
        )} />,

        <Route path="/physical-test-general-report" exact render={(props) => (
          isAuthenticated() ? <GeneralReport {...props} /> : <Redirect push to="/login" />
        )} />,

        {!isAuthenticated() && <Redirect push to="/login" />}
        <Route component={NotFound} />
      </Switch>
    </Router>
  </Suspense>
);

export { router };
