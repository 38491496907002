import amplitudeJs from 'amplitude-js';
import { LocalStorage } from './storage';

const amplitude = amplitudeJs.getInstance();
amplitude.init("c462f8387567d190382e9f5b718e3670");
let isUserSet = false;

export const logEvent = (event, eventProperties) => {
  // Don't log stuff during development
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!isUserSet) {
    const userName = LocalStorage.get('username');
    const orgName = LocalStorage.get('org');
    if (userName) {
      amplitude.setUserId(userName);
      isUserSet = true;
    }
    if (orgName) {
      const orgIdentify = new amplitude.Identify().set('organization', orgName);
      amplitude.identify(orgIdentify);
    }
  }

  amplitude.logEvent(event, eventProperties);
}