import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Header from './common/Header';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import moment from 'moment-timezone';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dateFormat, getLabel, getLang } from '../helpers/translator';
import { listItems, getColumns } from '../actions/list';
import { search } from '../actions/search';
import Footer from './common/Footer';
import { LocalStorage } from '../helpers/storage';
import { uiCheck } from '../actions/user';
import { logEvent } from '../helpers/amplitude';
import MyLoader from './common/MyLoader';


const Data = ({ history }) => {
    const amonthAgo = moment(new Date()).subtract(1, 'month').toDate();
    const timezone = LocalStorage.get('timezone');
    const [startDate, setStartDate] = useState(amonthAgo);
    const [endDate, setEndDate] = useState(new Date());
    const [plant, setPlant] = useState(null);
    const [plants, setPlants] = useState([]);
    const [classes, setClasses] = useState([]);
    const [classItem, setClass] = useState(null);
    const [cements, setCements] = useState([]);
    const [cement, setCement] = useState(null);
    const [minerals, setMinerals] = useState([]);
    const [mineral, setMineral] = useState(null);
    const [column, setColumn] = useState([]);
    const [columns, setColumns] = useState([]);
    const [logColumn, setLogColumn] = useState(['user_activity.activity', 'user_activity.user', 'user_activity.activity_date']);
    const [logColumns, setLogColumns] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [sampleType, setSampleType] = useState(null);
    const [sampleNo, setSampleNo] = useState('');
    const [dataTable, setDataTable] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({});
    const [pageLoad, setPageLoad] = useState(true);
    const [otherFields] = useState(LocalStorage.get('other_fields'));



    useEffect(() => {
        uiCheck('database').then(d => { if (d.success) setPageLoad(false) }).catch(e => { setPageLoad(false) });
        logEvent("[Database] View");

        listItems('sample_type').then(d => {
            let _sampleTypes = [];
            if (d.success) {
                d.data.forEach(i => _sampleTypes.push({ label: i.name, value: i.id }));
            }
            setSampleTypes(_sampleTypes);
        });

        listItems('customer').then(d => {
            let _customers = [];
            if (d.success) {
                d.data.forEach(i => _customers.push({ label: i.name, value: i.id }));
            }
            setCustomers(_customers);
        });

        listItems('cement').then(d => {
            let _cements = [];
            if (d.success) {
                d.data.forEach(i => _cements.push({ label: i.name, value: i.id }));
            }
            setCements(_cements);
        });

        listItems('plant').then(d => {
            let _plants = [];
            if (d.success) {
                d.data.forEach(i => _plants.push({ label: i.name, value: i.id }));
            }
            setPlants(_plants);
        });

        listItems('class').then(d => {
            let _classes = [];
            if (d.success) {
                d.data.forEach(i => _classes.push({ label: i.name, value: i.id }));
            }
            setClasses(_classes);
        });

        listItems('mineral').then(d => {
            if (d.success) {
                let _minerals = [];
                d.data.forEach(i => _minerals.push({ label: i.name, value: i.id }));
                setTimeout(() => _minerals.push({ label: getLabel('noMineral'), value: 0 }), 800);
                setMinerals(_minerals);
            }
        });

        setTimeout(() => {
            getColumns(getLang()).then(d => {
                setLogColumns([{ value: 'user_activity.activity', label: getLabel('operation') }, { value: 'user_activity.user', label: getLabel('user') }, { value: 'user_activity.activity_date', label: getLabel('date') }]);
                if (d.success) {
                    let selected = [];
                    let _columns = [];
                    Object.entries(d.data).forEach(([_key, _value]) => {
                        _columns.push({ value: _key, label: _value });
                        if (!d.no_select.includes(_key))
                            selected.push(_key);
                    });
                    setColumns(_columns);
                    setColumn(selected);
                }
            });
        }, 800);

    }, []);

    const exportTable = () => {
        logEvent("[Database] Export Table");
        dataTable.exportCSV();
    }

    const exportFunction = ({ column, data, field, rowData }) => {
        if (field === 'user_activities.activity')
            return getLabel(`database.${data}`);
        else if (field === 'user_activities.activity_date')
            return moment.utc(data).tz(timezone).format(`${dateFormat()} HH:mm:ss`);
        else if (field === 'additional_values') {
            const row = rowData.additional_values.find(f => f.column.code === column.props.columnKey);
            return row ? row.value : '';
        }
        else if (field === 'break_dates') {
            const break_id = column.props.columnKey.split("break_date_")[1];
            const data = rowData.break_dates.find(f => f.break_id === parseInt(break_id));
            return data ? data.value || '' : '';
        }

        else if (field === 'break_values') {
            if (column.props.columnKey.includes("number_weight_")) {
                const number_id = column.props.columnKey.split("number_weight_")[1];
                const data = rowData.break_values.find(f => f.number_id === parseInt(number_id));
                return data ? data.weight || '' : '';
            }
            if (column.props.columnKey.includes("number_strength_")) {
                const number_id = column.props.columnKey.split("number_strength_")[1];
                const data = rowData.break_values.find(f => f.number_id === parseInt(number_id));
                return data && data.strength ? parseFloat(data.strength).toFixed(2) : '';
            }
            if (column.props.columnKey.includes("number_avg_")) {
                const break_id = column.props.columnKey.split("number_avg_")[1];
                const data = rowData.break_values.filter(f => f.break_id === parseInt(break_id));
                const sum = data.reduce((x, y) => x + y.strength, 0);
                const count = data.filter(f => f.strength !== null).length;
                return data.length > 0 && count > 0 ? parseFloat(sum / count).toFixed(2) : '';
            }

        }
        else if (field === 'predict') {
            let predict_id = parseInt(column.props.columnKey.split("predict_")[1]);
            predict_id = predict_id === 0 ? null : predict_id;
            const data = rowData.predict.find(f => f.break_id === predict_id);
            return data && data?.prediction ? parseFloat(data.prediction).toFixed(2) || '' : '';
        }
        else
            return data;
    }

    const queryData = () => {

        if (column.length === 0) {
            toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('filter.columnReq') });
            logEvent("[Database] Required Fields");
            return;
        }

        setLoading(true);
        const searchParams = {
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            plant,
            classItem,
            cement,
            mineral,
            column,
            sample_no: sampleNo,
            sample_type: sampleType,
            customer
        }

        logEvent("[Database] Query Data", { "searchParams": searchParams });

        search(searchParams).then(d => {
            setLoading(false);
            if (d.not_valid) {
                toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('database.reqParams') });
                return;
            }
            if (d.success) {
                setData(d.data);
                if (d.data.length === 0) {
                    toast.show({ severity: 'warn', summary: getLabel('warning'), detail: getLabel('filter.noData') });
                }
            }
            else {
                setLoading(false);
                toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('filter.error') });
            }
        }).catch(err => {
            logEvent("[Database] Error on Query Data", { "searchParams": searchParams });
            setLoading(false); toast.show({ severity: 'error', summary: getLabel('error'), detail: getLabel('filter.error') })
        });

    }


    const renderActivity = (rowData) => {
        const activity = rowData?.user_activities?.activity || null;
        return activity ? getLabel(`database.${activity}`) : "";
    }

    const renderActivityDate = (rowData) => {
        const activity_date = rowData?.user_activities?.activity_date || null;
        return activity_date ? moment.utc(activity_date).tz(timezone).format(`${dateFormat()} HH:mm:ss`) : "";;
    }

    const renderCreatePdf = (rowData) => {
        return (
            <a disabled={loading} target="_blank" href={`pdf/${LocalStorage.get('org')}/${rowData.id}`} className='' rel="noreferrer">
                <i className="pi pi-file-pdf" />
                {getLabel('create')}
            </a>
        )
    }

    const frozenColumns = ['date', 'sample_no', 'plant.name', 'classes.name'];

    const renderBody = (rowData, key) => {
        const props = key.split('.');
        const val1 = rowData[props[0]];
        let val2 = val1;
        if (val1 && props.length === 2) {
            val2 = val1[props[1]];
        }

        let retval = '';
        if (val1 === undefined && val2 === undefined)
            retval = getAdditionalData(rowData, key) || '';
        else
            retval = val2 ? val2.toString() : '';

        if (key === 'date') {
            retval = moment.utc(rowData['date']).tz(timezone).format(dateFormat()) || ''
        }

        if (key.includes('break_date_')) {
            const break_id = key.split("break_date_")[1];
            const data = rowData.break_dates.find(f => f.break_id === parseInt(break_id));
            retval = data ? moment.utc(data.value).tz(timezone).format(dateFormat()) || '' : '';
        }

        if (key.includes('number_weight_')) {
            const number_id = key.split("number_weight_")[1];
            const data = rowData.break_values.find(f => f.number_id === parseInt(number_id));
            retval = data ? data.weight || '' : '';
        }

        if (key.includes('number_strength_')) {
            const number_id = key.split("number_strength_")[1];
            const data = rowData.break_values.find(f => f.number_id === parseInt(number_id));
            retval = data && data.strength ? parseFloat(data.strength).toFixed(2) : '';
        }

        if (key.includes('number_avg_')) {
            const break_id = key.split("number_avg_")[1];
            const data = rowData.break_values.filter(f => f.break_id === parseInt(break_id));
            const sum = data.reduce((x, y) => x + y.strength, 0);
            const count = data.filter(f => f.strength !== null).length;
            retval = data.length > 0 && count > 0 ? parseFloat(sum / count).toFixed(2) : '';

        }

        if (key.includes('predict_')) {
            let predict_id = parseInt(key.split("predict_")[1]);
            predict_id = predict_id === 0 ? null : predict_id;
            const data = rowData.predict.find(f => f.break_id === predict_id);
            retval = data && data?.prediction ? parseFloat(data.prediction).toFixed(2) || '' : '';
        }

        return (
            <div className="custom-tooltip">
                {retval.length > 15 ? `${retval.substring(0, 15)}...` : retval}
                {retval.length > 15 && <span className="custom-tooltiptext">{retval}</span>}
            </div>
        );
    }

    const getAdditionalData = (rowData, key) => {
        const row = rowData.additional_values.find(f => f.column.code === key);
        if (row) {
            return row.value;
        }

    }

    const getField = (key) => {
        if (key.includes('break_date_')) {
            return 'break_dates';
        }

        if (key.includes('number_weight_')) {
            return 'break_values';
        }

        if (key.includes('number_strength_')) {
            return 'break_values';
        }

        if (key.includes('number_avg_')) {
            return 'break_values';
        }

        if (key.includes('predict_')) {
            return 'predict';
        }
        if (otherFields.find(f => f.code === key)) {
            return 'additional_values';
        }

        return key;
    }

    return (
        <div>
            {
                pageLoad && (
                    <ProgressSpinner style={{ left: "50%" }} />
                )
            }
            {
                !pageLoad && (
                    <div>
                        {/* {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px' }} />)} */}
                        <Header history={history} />
                        <Card title={getLabel('database.title')} subTitle={getLabel('database.subTitle')}>
                            <Panel header={getLabel('filters')} toggleable>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <Calendar id='start_date' disabled={loading || sampleNo || customer?.length > 0} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={startDate} onChange={(e) => setStartDate(e.value)}></Calendar>
                                                <label htmlFor="start_date">{getLabel('startDate')}</label>
                                            </span>
                                        </div>

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <Calendar id='end_date' disabled={loading || sampleNo || customer?.length > 0} showButtonBar style={{ width: '100%' }} showIcon={true} locale={getLang()} value={endDate} onChange={(e) => setEndDate(e.value)}></Calendar>
                                                <label htmlFor="end_date">{getLabel('endDate')}</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-2">

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    disabled={loading}
                                                    id="plant"
                                                    style={{ width: "100%" }} value={plant}
                                                    options={plants} onChange={(e) => setPlant(e.value)}
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.plantSelected')}
                                                />
                                                <label htmlFor="plant">{getLabel('plant')}</label>
                                            </span>
                                        </div>

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <InputText id="sampleNo" disabled={loading} type="number" keyfilter="num" style={{ width: '100%' }} value={sampleNo}
                                                    onChange={(e) => {
                                                        setSampleNo(e.target.value);
                                                        if (e.target.value || customer?.length > 0) {
                                                            setStartDate(null);
                                                            setEndDate(null);
                                                        }
                                                        else if ((e.target.value === null || e.target.value === '') && (customer?.length === 0 || !customer)) {
                                                            setStartDate(amonthAgo);
                                                            setEndDate(new Date());
                                                        }
                                                    }} />
                                                <label htmlFor="sampleNo">{getLabel('sample_no')}</label>
                                            </span>

                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    disabled={loading}
                                                    id="classess"
                                                    style={{ width: "100%" }} value={classItem}
                                                    options={classes} onChange={(e) => setClass(e.value)}
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.classSelected')}
                                                />
                                                <label htmlFor="classess">{getLabel('class')}</label>
                                            </span>
                                        </div>
                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    disabled={loading}
                                                    id="customers"
                                                    filter
                                                    style={{ width: "100%" }} value={customer}
                                                    options={customers}
                                                    onChange={(e) => {
                                                        setCustomer(e.value);
                                                        if (e.value.length > 0 || sampleNo) {
                                                            setStartDate(null);
                                                            setEndDate(null);
                                                        }
                                                        else if ((!sampleNo || sampleNo === '') && e.value?.length === 0) {
                                                            setStartDate(amonthAgo);
                                                            setEndDate(new Date());
                                                        }
                                                    }}
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.customerSelected')}
                                                />
                                                <label htmlFor="customers">{getLabel('customer')}</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-2">

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    disabled={loading}
                                                    id="sample_type"
                                                    style={{ width: "100%" }} value={sampleType}
                                                    options={sampleTypes} onChange={(e) => setSampleType(e.value)}
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.sampleTypeSelected')}
                                                />
                                                <label htmlFor="sample_type">{getLabel('sample_type')}</label>
                                            </span>
                                        </div>

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    disabled={loading}
                                                    id="cement"
                                                    style={{ width: "100%" }} value={cement}
                                                    options={cements} onChange={(e) => setCement(e.value)}
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.cementSelected')}
                                                />
                                                <label htmlFor="cement">{getLabel('cement')}</label>
                                            </span>
                                        </div>

                                    </div>

                                    <div className="p-col-12 p-md-2">

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    disabled={loading}
                                                    id="mineral"
                                                    style={{ width: "100%" }} value={mineral}
                                                    options={minerals} onChange={(e) => setMineral(e.value)}
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.mineralSelected')}
                                                />
                                                <label htmlFor="mineral">{getLabel('mineral')}</label>
                                            </span>
                                        </div>

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    id="logs"
                                                    disabled={loading}
                                                    style={{ width: "100%" }} value={logColumn}
                                                    options={logColumns} onChange={(e) => setLogColumn(e.value)} filter
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.columnSelected')}
                                                />
                                                <label htmlFor="logs">{getLabel('logs')}</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-2">

                                        <div className="p-col-12">
                                            <span className="p-float-label">
                                                <MultiSelect
                                                    id="columns"
                                                    disabled={loading}
                                                    style={{ width: "100%" }} value={column}
                                                    options={columns} onChange={(e) => setColumn(e.value)} filter
                                                    emptyFilterMessage={getLabel('multiEmptyItems')}
                                                    selectedItemsLabel={getLabel('database.columnSelected')}
                                                />
                                                <label htmlFor="columns">{getLabel('columns')}</label>
                                            </span>
                                        </div>

                                        <div className="p-col-12">
                                            <Button disabled={loading} style={{ width: "100%" }} label={getLabel('search')} onClick={() => queryData()} className='p-button-raised p-button-secondary' icon="pi pi-search" />
                                        </div>
                                    </div>

                                </div>
                            </Panel>

                            {data.length !== 0 && (
                                <div className="p-flex-row-reverse p-grid p-col-12" style={{ marginTop: 20 }}>
                                    <Button disabled={loading} label={getLabel('download')} className='p-button-raised p-button-success p-button-sm'
                                        icon="pi pi-external-link" iconPos="left" onClick={() => exportTable()} />
                                </div>
                            )}

                            {loading && (<ProgressBar mode="indeterminate" style={{ height: '6px' }} />)}

                            {data.length !== 0 && (
                                <div className="p-col-12" style={{ fontSize: 12 }}>
                                    <div className="card">
                                        <DataTable
                                            className="p-datatable-sm p-datatable-striped p-datatable-border custom-table"
                                            value={data}
                                            scrollable
                                            loading={loading}
                                            scrollHeight="500px"
                                            exportFilename={getLabel('filter.exportFileName')}
                                            ref={(el) => { setDataTable(el) }}
                                            exportFunction={exportFunction}
                                            sortMode="multiple"
                                            removableSort
                                            scrollDirection="both"
                                            showGridlines
                                        >

                                            {columns && column && columns.filter(f => column.includes(f.value)).map(c => (
                                                <Column
                                                    key={c.value}
                                                    field={getField(c.value)}
                                                    alignHeader={"center"} align={"center"}
                                                    header={c.label}
                                                    headerClassName='datatable-custom-header'
                                                    body={(rowData) => renderBody(rowData, c.value)}
                                                    columnKey={c.value}
                                                    className="datatable-custom-column"
                                                    frozen={frozenColumns.includes(c.value)}
                                                    sortable
                                                />
                                            ))}

                                            {data[0].hasOwnProperty('user_activities') && logColumn.find(f => f === 'user_activity.activity') && (
                                                <Column
                                                    key="user_activities.activity"
                                                    field="user_activities.activity"
                                                    body={renderActivity}
                                                    alignHeader={"center"} align={"center"}
                                                    header={getLabel('operation')}
                                                    className="datatable-custom-column"
                                                    headerClassName='datatable-custom-header'
                                                    sortable
                                                />
                                            )}

                                            {data[0].hasOwnProperty('user_activities') && logColumn.find(f => f === 'user_activity.user') && (
                                                <Column
                                                    key="user_activities.user.name"
                                                    field="user_activities.user.name"
                                                    alignHeader={"center"} align={"center"}
                                                    header={getLabel('user')}
                                                    headerClassName='datatable-custom-header'
                                                    className="datatable-custom-column"
                                                    sortable
                                                />
                                            )}

                                            {data[0].hasOwnProperty('user_activities') && logColumn.find(f => f === 'user_activity.activity_date') && (
                                                <Column
                                                    key="user_activities.activity_date"
                                                    field="user_activities.activity_date"
                                                    body={renderActivityDate}
                                                    alignHeader={"center"} align={"center"}
                                                    header={getLabel('at_date')}
                                                    headerClassName='datatable-custom-header'
                                                    className="datatable-custom-column"
                                                    sortable
                                                />
                                            )}
                                            {LocalStorage.get('org') !== 'DURABLE' && (
                                                <Column
                                                    body={renderCreatePdf}
                                                    alignHeader={"center"} align={"center"}
                                                    header={getLabel('concreteSampleForm')}
                                                    headerClassName='datatable-custom-header'
                                                    className="datatable-custom-column"
                                                />
                                            )}

                                        </DataTable>
                                    </div>
                                </div>
                            )}

                        </Card>
                        <Footer />
                        {loading && (<MyLoader />)}
                        <Toast ref={(el) => setToast(el)} />
                    </div>

                )
            }
        </div>

    )
};

export default withTranslation()(Data);