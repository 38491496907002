import i18n from '../i18n';
import { LocalStorage } from './storage';

export const getLabel = (key) => {
    return i18n.t(key);
}

export const getLang = () => {
    return i18n.language;
}

export const getAdditionalColumnDesc = field => {
    return i18n.language === 'en' ? field.desc_en : i18n.language === 'ru' ? field.desc_ru : field.desc_tr;
}

export const dateFormat = () => {
    return i18n.language === 'en' ? "MM/DD/YYYY" : i18n.language === 'ru' ? "DD.MM.YYYY" : "DD-MM-YYYY";
}
export const currencySymbol = () => {
    return LocalStorage.get('currency')
}